import React, { useMemo } from "react";
import PropTypes from "prop-types";

/*
 * Flex box spacer or specify the width or height
 */
function Spacer({ width, height }) {
  const styles = useMemo(
    () =>
      !width && !height
        ? { flex: 1 }
        : {
            width: width || "100%",
            height: height || "100%",
          },
    [width, height],
  );
  return <div style={styles}></div>;
}

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Spacer.defaultProps = {
  width: 0,
  height: 0,
};

export default Spacer;
