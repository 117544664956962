import { useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import { useRequestStatus } from "../../shared/modules/hooks";
import { memoObjectByKeyValues } from "../../shared/modules/object";

const CHANGE_REFUND_METHOD = gql`
  mutation changeRefundMethod(
    $id: String!
    $refundMethod: String!
    $note: String
  ) {
    changeRefundMethod(
      returnHashid: $id
      refundMethod: $refundMethod
      note: $note
    ) {
      success
    }
  }
`;

export function useChangeRefundMethod() {
  const [mutate, state] = useMutation(CHANGE_REFUND_METHOD);
  const status = useRequestStatus(state);
  const changeRefundMethod = useCallback(variables => mutate({ variables }), [
    mutate,
  ]);

  return memoObjectByKeyValues({
    changeRefundMethod,
    status,
  });
}
