import React, { createContext, useCallback, useContext, useRef } from "react";
import { getBranding } from "../../shared/modules/config";
import { scrollToPosition } from "../../shared/modules/frame";
import { useDebounceCallback } from "../../shared/modules/hooks";

export const FrameDimensionsContext = createContext({
  setActiveSection: htmlElement => {},
  scrollToActiveNow: () => {},
  scrollToActive: () => {},
});

function useTypeFormHook() {
  const activeSectionRef = useRef(null);

  const setActiveSection = useCallback(htmlElement => {
    activeSectionRef.current = htmlElement;
  }, []);

  const scrollToActiveNow = useCallback(function scrollToActive() {
    // TODO: deprecate soon. Always returning 0.
    // const { top: viewportTop } = getWindowFramePosition();
    const { top } = activeSectionRef.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const marginTop = getBranding("fixed_app_bar")
      ? 0
      : getBranding("step_delimiter_position");
    scrollToPosition(top + scrollTop + marginTop);
  }, []);

  // Scroll to active section in the next frame (60Hz). If we call this function
  // on a callback (eg. onChange), we usually also set some state in react,
  // which trigger some UI change. So we give some time gap and wait for the
  // re-rendering before we perform the scroll action.
  const scrollToActive = useDebounceCallback(scrollToActiveNow, 16);

  return {
    setActiveSection,
    scrollToActiveNow,
    scrollToActive,
  };
}

export function useTypeForm() {
  return useContext(FrameDimensionsContext);
}

export default function TypeFormProvider({ children }) {
  const state = useTypeFormHook();

  return (
    <FrameDimensionsContext.Provider value={state}>
      {children}
    </FrameDimensionsContext.Provider>
  );
}
