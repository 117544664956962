import React from "react";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RedoIcon from "@material-ui/icons/Redo";
import LoopIcon from "@material-ui/icons/Loop";
import Image from "material-ui-image";

import { makeRootStyles } from "../../../theme/styles";
import ItemThumbnail from "../Item/ItemThumbnail";
import StoreCredit from "../../../../shared/components/icons/StoreCredit";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      position: "relative",
    },
    prevImageContainer: {
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      transform: `translate(-${theme.spacing(1)}px, -${theme.spacing(1)}px)`,
    },
    prevImageUsingIcon: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: theme.palette.common.white,
    },
    icon: {
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      right: 0,
      transform: `translate(50%, 50%)`,
      backgroundColor: theme.palette.common.white,
    },
  }),
  { name: "N0ExchangeItemThumbnail" },
);

function ExchangeItemThumbnail({
  className,
  imageAltTxt,
  imageStyle,
  imageUrl,
  prevImageAltTxt,
  prevImageUrl,
  storeCredit,
  size = "medium",
}) {
  const classes = useStyles();
  const theme = useTheme();
  const sizeMapping = {
    small: theme.spacing(6),
    medium: theme.spacing(8),
    large: theme.spacing(10),
  };
  const width = sizeMapping[size];
  const height = sizeMapping[size];

  return (
    <div className={classes.root}>
      <div className={classes.prevImageContainer}>
        {/* conner small image */}
        {storeCredit ? (
          <StoreCredit
            width={width}
            height={height}
            className={classes.prevImageUsingIcon}
          />
        ) : (
          <Image
            disableSpinner
            animationDuration={theme.transitions.duration.complex}
            alt={prevImageAltTxt}
            src={prevImageUrl}
            imageStyle={{
              width: "100%",
              height: "auto",
              display: "block",
              border: "2px solid #fff",
            }}
            style={{
              paddingTop: 0,
              width,
              height,
            }}
          />
        )}
        {/* conner icon */}
        <IconButton size="small" className={classes.icon}>
          {storeCredit ? (
            <LoopIcon color="secondary" />
          ) : (
            <RedoIcon color="secondary" />
          )}
        </IconButton>
      </div>
      {/* main image thumbnail */}
      <ItemThumbnail fade className={className} imageUrl={imageUrl} />
    </div>
  );
}
export default ExchangeItemThumbnail;
