import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { difference, isFunction, negate, pick, noop } from "lodash/fp";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";

import { makeRootStyles } from "../../theme/styles";
import { useStatesList } from "../../../shared/modules/regions";
import { countryCodes, getConfig } from "../../../shared/modules/config";
import { config } from "../../config";
import ZipValidation from "./ZipValidation.js";
import ButtonBusy from "../../../shared/components/form/ButtonBusy";
import { getCountryNameByCode, mergeAddress } from "../../modules/address";
import { assertAllFieldsBy } from "../../../shared/modules/object";
import { controlGridSize } from "../../../shared/modules/mui-utils";

const useStyles = makeRootStyles(
  theme => ({
    input: {
      backgroundColor: theme.palette.common.white,
    },
    autocompleteInputField: {
      marginBottom: 0,
      paddingBottom: 0,
    },
    autocompleteEndAdornment: {
      top: 0,
      paddingBottom: 0,
    },
    autocompleteInputRoot: {
      paddingBottom: 0,
      maxHeight: "48px",
    },
  }),
  { name: "N0MailingAddressForm" },
);

const MailingAddressForm = ({
  address: initialAddress,
  onSubmit,
  onChange,
  disable,
  exclude,
  submitLabel,
  extraValidation,
  validateZip,
  isBusy,
  notesEnabled,
  size,
}) => {
  const classes = useStyles();
  const [fromAddress, updateFromAddress] = useState(
    mergeAddress(initialAddress),
  );
  const [autoCompleteNames, setAutoCompleteNames] = useState({});

  // TODO: use useLazyQuery here to only make an API call when countryCode is entered by the user
  const { state: states } = useStatesList(fromAddress.countryCode || "");

  const handleInput = key => (event, ...args) => {
    const updates = isFunction(key)
      ? key(event, ...args)
      : { [key]: event.target.value };
    updateFromAddress(mergeAddress(fromAddress, updates));
  };

  const {
    firstName = "",
    lastName = "",
    phone = "",
    address1 = "",
    address2 = "",
    city = "",
    province = "",
    provinceCode = "",
    zip = "",
    country = "",
    countryCode = "",
    notes = "",
  } = fromAddress;

  const countryCodesList =
    getConfig("country_codes").length > 0
      ? getConfig("country_codes")
      : countryCodes;

  useEffect(() => {
    const genRan = () =>
      Math.random()
        .toString(36)
        .substring(7);
    setAutoCompleteNames({
      country: "cc" + genRan(),
      firstName: "fn" + genRan(),
      lastName: "ln" + genRan(),
      phone: "ph" + genRan(),
      line1: "add1" + genRan(),
      line2: "add2" + genRan(),
      city: "cty" + genRan(),
      province: "pvnc" + genRan(),
      state: "st" + genRan(),
      zip: "zp" + genRan(),
    });
  }, []);

  const firstRowWidth =
    12 / difference(["firstName", "lastName", "phone"], exclude).length;

  const zipExampleText =
    validateZip &&
    countryCode &&
    ZipValidation[countryCode] &&
    ZipValidation[countryCode].example;
  const lastRowWidth = zipExampleText ? 3 : 4;
  const zipValidate = useCallback(
    ({ zip, countryCode }) =>
      !validateZip ||
      !zip ||
      !countryCode ||
      !ZipValidation[countryCode] ||
      ZipValidation[countryCode].regex.test(zip),
    [validateZip, ZipValidation],
  );

  const validate = useCallback(
    address => {
      const statePresentWhenInUS =
        !(address?.countryCode == "US") || !isEmpty(address?.province);
      const requiredFields = difference(
        ["firstName", "lastName", "address1", "city", "zip", "countryCode"],
        exclude,
      );
      return (
        zipValidate(address) &&
        extraValidation() &&
        statePresentWhenInUS &&
        assertAllFieldsBy(negate(isEmpty), pick(requiredFields, address))
      );
    },
    [zipValidate, extraValidation, exclude],
  );

  const isZipValid = useMemo(() => zipValidate(fromAddress), [
    fromAddress,
    zipValidate,
  ]);
  const isValid = useMemo(() => validate(fromAddress), [fromAddress, validate]);

  // check disable flags from props or feature flags
  const disableCountry =
    disable?.includes("countryCode") ||
    (config.isAddressProvinceDisabled && !!countryCode);

  // check disable flags from props or feature flags or country is filled
  const disableProvince =
    disable?.includes("province") ||
    !fromAddress.countryCode ||
    (config.isAddressProvinceDisabled && !!province) ||
    (config.isAddressProvinceDisabled && !states.listStates?.length);

  useEffect(() => {
    onChange({ fromAddress, isValid: validate(fromAddress) });
  }, [fromAddress]);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ fromAddress, isValid });
  };

  // Disabled form auto-complete using combination of unique name, id, autoComplete field
  // https://adamsilver.io/blog/stopping-chrome-from-ignoring-autocomplete-off/
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            autoComplete={false}
            autoHighlight
            autoSelect
            id="countrycode-combo-box"
            options={countryCodesList}
            getOptionLabel={countryCode => {
              if (isObject(countryCode)) return countryCode.country;
              return countryCode;
            }}
            renderInput={params => {
              const mutatedParams = {
                ...params,
                inputProps: {
                  ...params.inputProps,
                  autoComplete: autoCompleteNames["country"],
                },
                InputProps: {
                  ...params.InputProps,
                  autoComplete: autoCompleteNames["country"],
                },
              };
              return (
                <TextField
                  {...mutatedParams}
                  label={config.translations.mailing_address_form_country}
                  variant="outlined"
                  fullWidth
                  className={classes.autocompleteInputField}
                  name={autoCompleteNames["country"]}
                  id={autoCompleteNames["country"]}
                  autoComplete={autoCompleteNames["country"]}
                />
              );
            }}
            value={getCountryNameByCode(countryCode)}
            onChange={handleInput((event, newValue) => ({
              countryCode: newValue.country_code,
            }))}
            disableClearable
            classes={{
              root: classes.input,
              endAdornment: classes.autocompleteEndAdornment,
              inputRoot: classes.autocompleteInputRoot,
            }}
            ListboxProps={{
              style: { maxHeight: "15rem" },
              position: "bottom-start",
            }}
            disabled={disableCountry}
          />
        </Grid>

        {!exclude.includes("firstName") && (
          <Grid item {...controlGridSize({ sm: firstRowWidth, xs: 12 }, size)}>
            <TextField
              label={
                config.translations.mailing_address_form_first_name ||
                "First Name"
              }
              value={firstName}
              onChange={handleInput("firstName")}
              margin="none"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.input}
              disabled={disable?.includes("firstName")}
              name={autoCompleteNames["firstName"]}
              id={autoCompleteNames["firstName"]}
              autoComplete={autoCompleteNames["firstName"]}
            />
          </Grid>
        )}
        {!exclude.includes("lastName") && (
          <Grid item {...controlGridSize({ sm: firstRowWidth, xs: 12 }, size)}>
            <TextField
              label={
                config.translations.mailing_address_form_last_name ||
                "Last Name"
              }
              value={lastName}
              onChange={handleInput("lastName")}
              margin="none"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.input}
              disabled={disable?.includes("lastName")}
              name={autoCompleteNames["lastName"]}
              id={autoCompleteNames["lastName"]}
              autoComplete={autoCompleteNames["lastName"]}
            />
          </Grid>
        )}

        {!exclude.includes("phone") && (
          <Grid item {...controlGridSize({ sm: firstRowWidth, xs: 12 }, size)}>
            <TextField
              label={
                config.translations.mailing_address_form_phone || "Phone Number"
              }
              value={phone}
              onChange={handleInput("phone")}
              margin="none"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.input}
              disabled={disable?.includes("phone")}
              name={autoCompleteNames["phone"]}
              id={autoCompleteNames["phone"]}
              autoComplete={autoCompleteNames["phone"]}
            />
          </Grid>
        )}

        <Grid item {...controlGridSize({ md: 6, xs: 12 }, size)}>
          <TextField
            label={
              config.translations.mailing_address_form_address_line_1 ||
              "Address 1"
            }
            value={address1}
            onChange={handleInput("address1")}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.input}
            disabled={disable?.includes("address1")}
            name={autoCompleteNames["line1"]}
            id={autoCompleteNames["line1"]}
            autoComplete={autoCompleteNames["line1"]}
          />
        </Grid>

        <Grid item {...controlGridSize({ md: 6, xs: 12 }, size)}>
          <TextField
            label={
              config.translations.mailing_address_form_address_line_2 ||
              "Address 2"
            }
            value={address2}
            onChange={handleInput("address2")}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.input}
            disabled={disable?.includes("address2")}
            name={autoCompleteNames["line2"]}
            id={autoCompleteNames["line2"]}
            autoComplete={autoCompleteNames["line2"]}
          />
        </Grid>

        <Grid item {...controlGridSize({ md: lastRowWidth, xs: 6 }, size)}>
          <TextField
            label={config.translations.mailing_address_form_city || "City"}
            value={city}
            onChange={handleInput("city")}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.input}
            disabled={disable?.includes("city")}
            name={autoCompleteNames["city"]}
            id={autoCompleteNames["city"]}
            autoComplete={autoCompleteNames["city"]}
          />
        </Grid>

        <Grid item {...controlGridSize({ md: lastRowWidth, xs: 6 }, size)}>
          {!disable?.includes("province") &&
          fromAddress.countryCode &&
          states ? (
            <Autocomplete
              id="auto-pvnc"
              autoHighlight
              autoSelect
              renderInput={params => {
                const mutatedParams = {
                  ...params,
                  inputProps: {
                    ...params.inputProps,
                    autoComplete: autoCompleteNames["province"],
                  },
                  InputProps: {
                    ...params.InputProps,
                    autoComplete: autoCompleteNames["province"],
                  },
                };
                return (
                  <TextField
                    {...mutatedParams}
                    label={
                      config.translations.mailing_address_form_state ||
                      "State / Province"
                    }
                    variant="outlined"
                    fullWidth
                    className={classes.autocompleteInputField}
                    value={province}
                    name={autoCompleteNames["province"]}
                    id={autoCompleteNames["province"]}
                    autoComplete={autoCompleteNames["province"]}
                  />
                );
              }}
              options={states.listStates.map(({ region }) => region)}
              classes={{
                root: classes.input,
                endAdornment: classes.autocompleteEndAdornment,
                inputRoot: classes.autocompleteInputRoot,
              }}
              value={province}
              onChange={handleInput((event, newValue) => ({
                province: newValue || "",
                provinceCode:
                  states?.listStates?.find(state => state.region === newValue)
                    ?.regionCode || "",
              }))}
              disabled={disableProvince}
            />
          ) : (
            <TextField
              label={
                config.translations.mailing_address_form_state ||
                "State / Province"
              }
              value={province}
              onChange={handleInput("province")}
              margin="none"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.input}
              disabled={true}
              name={autoCompleteNames["state"]}
              id={autoCompleteNames["state"]}
              autoComplete={autoCompleteNames["state"]}
            />
          )}
        </Grid>

        <Grid item {...controlGridSize({ md: lastRowWidth, xs: 6 }, size)}>
          <TextField
            label={
              config.translations.mailing_address_form_zip ||
              "Zip / Postal Code"
            }
            value={zip}
            onChange={handleInput("zip")}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.input}
            disabled={disable?.includes("zip")}
            name={autoCompleteNames["zip"]}
            id={autoCompleteNames["zip"]}
            autoComplete={autoCompleteNames["zip"]}
          />
        </Grid>

        {zipExampleText && (
          <Grid item {...controlGridSize({ md: 3, xs: 6 }, size)}>
            <Typography color={isZipValid ? "textPrimary" : "error"}>
              Format: {zipExampleText}
            </Typography>
          </Grid>
        )}

        {notesEnabled && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              rows={4}
              className={classes.input}
              color="primary"
              label={config.translations.mailing_address_form_notes || "Notes"}
              onChange={handleInput("notes")}
              value={notes}
            />
          </Grid>
        )}

        {!exclude.includes("submit") && (
          <Grid item xs={12}>
            <ButtonBusy
              isBusy={isBusy}
              disabled={disable?.includes("submit") || !isValid}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth>
              {submitLabel}
            </ButtonBusy>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

MailingAddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  disable: PropTypes.arrayOf(
    PropTypes.oneOf([
      "countryCode",
      "firstName",
      "lastName",
      "phone",
      "address1",
      "address2",
      "city",
      "province",
      "zip",
      "submit",
    ]),
  ),
  exclude: PropTypes.arrayOf(
    PropTypes.oneOf(["phone", "firstName", "lastName", "submit"]),
  ),
  submitLabel: PropTypes.string,
  extraValidation: PropTypes.func,
  validateZip: PropTypes.bool,
  isBusy: PropTypes.bool,
  notesEnabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

MailingAddressForm.defaultProps = {
  address: {},
  onChange: noop,
  disable: [],
  exclude: ["phone"],
  submitLabel: "Submit",
  extraValidation: () => true,
  validateZip: false,
  isBusy: false,
  notesEnabled: false,
  size: "medium",
};

export default MailingAddressForm;
