import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types";

const useStyles = makeStyles(
  theme => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(1.25),
      },
    },
    input: {
      borderRadius: 2,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #ced4da",
      width: "100%",
      padding: "10px 12px",
      minHeight: 84,
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
    },
    multiline: {
      padding: 0,
    },
  }),
  { name: "N0PlainTextArea" },
);

function PlainTextArea({ className, fullWidth, label, ...other }) {
  const classes = useStyles();

  return (
    <FormControl className={className} fullWidth={fullWidth}>
      {label ? (
        <InputLabel shrink={false} disableAnimation>
          <strong>{label}</strong>
        </InputLabel>
      ) : null}
      <InputBase {...other} classes={classes} multiline />
    </FormControl>
  );
}
PlainTextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
};
export default PlainTextArea;
