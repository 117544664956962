import { includes, keys } from "lodash/fp";

// Return status simplified to consumer (e.g. shown on return history)
export const SIMPLIFIED_RETURN_STATUS = {
  out_of_stock_exception: "initiated",
  // delivered_but_not_received: -4, // TODO: need research
  exception: "initiated",
  // preauthorization: -1, // TODO: need research
  started: "initiated",
  requested: "requested",
  initiated: "initiated",
  on_its_way_to_retailer: "on_its_way",
  delivered_to_retailer: "delivered",
  received_by_retailer: "received",
  processing: "received",
  approved: "approved", // need timestamp
  rejected: "declined",
  refunded: "refunded", // need timestamp
  cancelled_by_retailer: "cancelled", // hide from the list
  cancelled_by_user: "cancelled", // hide from the list
  // refunded_and_exchanged: 10,  // TODO: need research
  // refunded_return_awaiting_exchange_completion: 11,  // TODO: need research
  // exchange_processed: 12,  // TODO: need research
  resolve_manually_without_automation: "initiated",

  _unknown: "initiated",
};

export const simplifyReturnStatus = status =>
  SIMPLIFIED_RETURN_STATUS[status] ?? SIMPLIFIED_RETURN_STATUS._unknown;

export const RETURN_STATUSES = keys(SIMPLIFIED_RETURN_STATUS);

export const compareReturnStatus = (() => {
  const toNumber = status => RETURN_STATUSES.indexOf(status);
  const eq = (a, b) => toNumber(a) === toNumber(b);
  const gt = (a, b) => toNumber(a) > toNumber(b);
  const gte = (a, b) => toNumber(a) >= toNumber(b);
  const lt = (a, b) => toNumber(a) < toNumber(b);
  const lte = (a, b) => toNumber(a) <= toNumber(b);
  const memberOf = (a, statuses = []) => includes(a, statuses);
  return {
    eq,
    gt,
    gte,
    lt,
    lte,
    memberOf,
  };
})();
