import { isString } from "lodash";

/**
 * @param {string[]|number[]} array
 * @param {string} [connectiveText=and]
 * @returns {string}
 * @example
 * joinHumanize(['1', '2', '3'], 'and') // returns '1, 2 and 3'
 */
export const joinHumanize = (array, connectiveText = "and") => {
  let ret = [].concat(array); // clone array
  const last = ret.pop();
  ret = ret.join(", ");
  ret = [].concat(ret || []).concat(last || []);
  ret = ret.join(` ${connectiveText} `);
  return ret;
};

/**
 * @param {string[]|number[]} array
 * @returns {string}
 * @example
 * joinAnd(['1', '2', '3']) // returns '1, 2 and 3'
 */
export const joinAnd = array => joinHumanize(array, "and");

/**
 * @param {string[]|number[]} array
 * @returns {string}
 * @example
 * joinOr(['1', '2', '3']) // returns '1, 2 or 3'
 */
export const joinOr = array => joinHumanize(array, "or");

const SIZE_MULTIPLIER_MAP = {
  K: 1 << 10,
  KB: 1 << 10,
  M: 1 << 20,
  MB: 1 << 20,
  G: 1 << 30,
  GB: 1 << 30,
  T: 1 << 40,
  TB: 1 << 40,
};

/**
 * Parse size string to the number of bytes
 * @param {string} size size in text format, eg. 10KB
 * @returns {number}
 * @example
 * parseSize('1.5kb') // returns 1536
 */
export const parseSize = size => {
  const matches = size.match(/([0-9.]+)\s*([a-z]*)/i) || [];
  const num = parseFloat(matches[1] || 0);
  const unit = (matches[2] || "").toUpperCase();
  const multiplier = SIZE_MULTIPLIER_MAP[unit] || 1;
  return num === 0 ? 0 : num * multiplier;
};

/**
 * Format the size in number of bytes to a readable string
 * @param {number} size         file size
 * @param {number} [decimals=1] rounding to decimal points
 * @returns {string}
 * @example
 * formatSize(3000, 2) // returns '2.93 KB'
 */
export const formatSize = (size, decimals = 1) => {
  if (size === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Try to parse JSON string, it fallback to default value if there is any errors
 * @param {string} jsonString          a JSON string to parse
 * @param {*} [defaultValue=undefined] default value if parse failed
 * @returns {*}
 */
export const parseJSON = (jsonString, defaultValue = undefined) => {
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    return defaultValue;
  }
};

export const isValidHttpUrl = string => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const isPresentString = obj => {
  return isString(obj) && obj.trim() !== "";
};
