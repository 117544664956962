import { useMemo } from "react";
import errorNotifier from "../../shared/modules/error-notifier";
import localeFromUrl from "../../shared/modules/lang";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import { config } from "../config";
import { useOrderFetchData } from "../contexts/returnSteps";

const useConsumerSettings = () => {
  const fetchData = useOrderFetchData();
  const order = fetchData.state.data?.order;
  const country = order?.fromCountryCode || order?.billingCountryCode || "US";
  const locale = localeFromUrl || "en";
  const language = useMemo(() => {
    // Try to parse the locale language from the URL parameter to make sure it
    // is a valid locale to be used in Intl.NumberFormat which used in nth
    // Currency component. (SHOPZ-2218)
    try {
      try {
        return new Intl.Locale(locale.replace(/[^a-zA-Z]/g, "-")).language;
      } catch (err) {
        const fallback = locale.replace(/[^a-zA-Z].*/, "");
        console.warn(`Invalid locale: "${locale}", fallback to "${fallback}"`);
        return new Intl.Locale(fallback).language;
      }
    } catch (err) {
      // There are less browsers support Intl.Locale comparing to
      // Intl.NumberFormat. We saw Safari 13 would throw an error for "de".
      // In here, we try to find 2 alphabetic characters that can represent a
      // locale, otherwise fallback to English.
      // https://app.rollbar.com/a/narvar/fix/item/ShopifyZero/25381/occurrence/391020744460#detail
      // https://caniuse.com/mdn-javascript_builtins_intl_numberformat_format
      // https://caniuse.com/mdn-javascript_builtins_intl_locale
      const fallback = /^[a-z]{2,2}$/i.test(locale.split(/[^a-z]/i)[0])
        ? locale.substring(0, 2)
        : "en";
      console.warn(
        `Unsupported locale: "${locale}", fallback to "${fallback}"`,
      );
      err.message = "Cannot parse locale again";
      errorNotifier.warn(err, { country, locale, fallback });
      return "en";
    }
  }, [locale]);
  const localeFull = `${language}_${country}`;
  const { isIncludeCurrency } = config;

  return memoObjectByKeyValues({
    locale, // Shopify locale, it might include country code
    country,
    language,
    localeFull, // locale format used in nth components, e.g. en_CA, fr_CA
    includeCurrency: isIncludeCurrency,
  });
};

export default useConsumerSettings;
