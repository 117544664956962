import React from "react";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";

import { makeRootStyles } from "../../../theme/styles";
import { ACTION_HEIGHT_SPACING, getBorderSelected, getPadding } from "../utils";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      width: "100%",
      maxHeight: 300,
      overflowY: "auto",
    },
    disabled: {},
    action: {
      border: getBorderSelected(theme),
      boxSizing: "border-box",
      backgroundColor: theme.palette.common.white,
    },
    menuList: {
      borderTop: getBorderSelected(theme),
    },
    menuListItemRoot: {
      minHeight: theme.spacing(ACTION_HEIGHT_SPACING),
      whiteSpace: "normal",
    },
    menuListItemGutters: {
      paddingLeft: getPadding(theme),
      paddingRight: getPadding(theme),
    },
    itemLabel: {
      color: theme.palette.primary.main,
      padding: 0,
      "&$disabled": {
        opacity: 1,
      },
    },
    actionsSmall: {
      borderTopWidth: 0,
    },
  }),
  { name: "N0ItemMenuList" },
);

const ItemMenuList = ({ onChange, options, placeholder, value }) => {
  const classes = useStyles();

  const selectItemClasses = {
    root: classes.menuListItemRoot,
    gutters: classes.menuListItemGutters,
  };

  return (
    <MenuList className={clsx(classes.root, classes.itemLabel)}>
      {placeholder && (
        <MenuItem
          className={classes.itemLabel}
          classes={selectItemClasses}
          color="primary"
          value={null}
          disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map(option => (
        <MenuItem
          key={option.value}
          classes={selectItemClasses}
          color="primary"
          onClick={() => onChange(option.value)}
          selected={option.value === value}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MenuList>
  );
};
export default ItemMenuList;
