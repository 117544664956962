import React, { useRef } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { makeRootStyles } from "../../../theme/styles";

export const useStyles = makeRootStyles(
  theme => {
    return {
      root: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
      },
      accordion: {
        borderRadius: 0,
        flex: 1,
        marginTop: 0,
        marginBottom: 0,
        "&:before": {
          content: "none",
        },
        "&:after": {
          display: "block",
          opacity: 1,
          height: 3,
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: -2,
          left: 0,
          right: 0,
          content: "' '",
          zIndex: 1,
        },
        "&:last-child:after": {
          content: "none",
        },
        "&.Mui-expanded:before": {
          opacity: 1,
        },
      },
      accordionSummary: {
        padding: theme.spacing(2, 3),
        width: "100%",
        boxSizing: "border-box",
        // When closed, the summary text will not wrap so may horizontally overflow.
        overflow: "hidden",
      },
      expansionPanelSummaryContent: {
        maxWidth: "100%",
        margin: 0,
      },
      expansionPanelSummaryValid: {
        color: theme.palette.primary.main,
      },
      accordionDetails: {
        minHeight: "300px",
        height: "auto",
        padding: 0,
      },
      heading: {},
    };
  },
  { name: "N0CollapsibleOptions" },
);

const CollapsibleOptions = ({
  className,
  children,
  expanded,
  height,
  onChange,
}) => {
  const classes = useStyles();
  const buttonRefs = useRef({});

  return (
    <div className={clsx(classes.root, className)}>
      {React.Children.map(children, element => {
        if (!element) return null;

        const {
          buttonRef,
          collapseId,
          collapseLabel,
          collapseSummary,
          highlight,
        } = element.props;

        const isExpanded = collapseId === expanded;
        const summary =
          (isExpanded && collapseLabel) || collapseSummary || collapseLabel;

        return (
          <Accordion
            key={collapseId}
            className={classes.accordion}
            expanded={isExpanded}
            onChange={() => onChange(collapseId)}>
            <AccordionSummary
              ref={ref => {
                buttonRefs.current[collapseId] = ref;
                if (buttonRef) {
                  buttonRef.current = ref;
                }
              }}
              classes={{
                root: clsx(classes.accordionSummary, {
                  [classes.expansionPanelSummaryValid]:
                    !isExpanded && highlight,
                }),
                content: classes.expansionPanelSummaryContent,
              }}>
              {typeof summary === "string" ? (
                <Typography
                  className={classes.heading}
                  color="inherit"
                  variant="h4">
                  {summary}
                </Typography>
              ) : (
                summary
              )}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <element.type {...element.props} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CollapsibleOptions;

export const CollapsibleOptionItem = ({
  buttonRef,
  children,
  collapseId,
  collapseLabel,
  collapseSummary,
  highlight,
}) => children;
