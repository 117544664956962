import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useGrowthBook } from "@growthbook/growthbook-react";
import get from "lodash/get";

import { OrderLookup as NthOrderLookup } from "@narvar/nth-kit-returns-headless";

import Spinner from "../../../shared/components/Spinner";
import { config } from "../../config";
import {
  useReturnStepsActions,
  useReturnStepsState,
  CHOOSE_ITEMS_STEP,
  ORDER_LOOKUP_STEP,
} from "../../contexts/returnSteps";
import { useEffectOnce } from "../../../shared/modules/hooks";
import { makeRootStyles } from "../../theme/styles";
import OrderLookupDetails from "./OrderLookupDetails";
import OrderLookupLogin from "./OrderLookupLogin";
import ShopNowExpiration from "./ShopNowExpiration";
import Spacer from "../../../shared/components/Spacer";
import { updateGrowthBookAttributes } from "../../../shared/modules/growthBook";
import {
  countingEvent,
  LOGIN_SUCCESS_EVENT,
  useAbTesting,
} from "../../data/abTesting";
import useLoginSession from "../../hooks/useLoginSession";
import { toggleContainerClass } from "../../../shared/modules/frame";
import { getBranding } from "../../../shared/modules/config";
import titan from "../../../shared/modules/titan";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
      justifyContent: "center",
    },
    title: {
      marginBottom: theme.spacing(2),
      wordWrap: "break-word",
    },
    supplementaryText1: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    supplementaryText2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  }),
  { name: "N0OrderLookup" },
);

const OrderLookup = ({
  onOrderFetch,
  orderFetchData,
  orderFetchError,
  orderFetchLoading,

  shopNowData,
  checkShopNow,
  cancelShopNow,
  shopNowCancelData,
  shopNowCancelError,
  shopNowCancelLoading,
}) => {
  const classes = useStyles();
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const growthbook = useGrowthBook();
  const abTesting = useAbTesting();
  const loginSession = useLoginSession();

  const orderFetchDataRef = useRef(orderFetchData);

  const [orderNumber, setOrderNumber] = useState(state.orderNumber);
  const [email, setEmail] = useState(state.email);
  const [giftField, setGiftField] = useState(state.giftField);
  const isGift = state.guestReturn;
  const isGiftReturnsEnabled =
    config.isGiftZipEnabled || config.isGiftReceiptEnabled;
  const [shopNowCode, setShopNowCode] = useState();
  const csid = state.csid;
  const useNewDesign = config.isNthLoginUi;
  const newDesignLayout = getBranding("background_image_url")
    ? "default"
    : "centered";
  const isTrack = state.mode === "track";

  const newUIValues = useMemo(
    () =>
      [
        { type: "ORDER", value: orderNumber },
        { type: "EMAIL", value: email },
        isGift ? { type: "GIFT_CODE", value: giftField } : null,
      ].filter(d => d),
    [isGift, orderNumber, email, giftField],
  );

  const details = useMemo(
    () => (!orderFetchError && orderFetchData && orderFetchData.order) || null,
    [orderFetchData, orderFetchError],
  );

  const returnHistory = useMemo(
    () =>
      (!orderFetchError && orderFetchData && orderFetchData.returnHistory) ||
      null,
    [orderFetchData, orderFetchError],
  );

  const ineligible = !!details?.eligible;

  // reset local state when the global state changes
  useEffect(() => {
    setOrderNumber(state.orderNumber);
    setEmail(state.email);
    setGiftField(state.giftField);
  }, [state.orderNumber, state.email, state.giftField]);

  const handleExit = () => {
    orderFetchDataRef.current = null;

    setOrderNumber("");
    setEmail("");

    loginSession.logout();
    if (useNewDesign) {
      toggleContainerClass("nvo_container--new-login");
      toggleContainerClass("nvo_container--logged-in");
    }
  };

  const handleChangeOrderNumber = event => setOrderNumber(event.target.value);
  const handleChangeEmail = event => setEmail(event.target.value);
  const handleChangeGiftField = event => setGiftField(event.target.value);

  const handleChangeIsGift = val => {
    actions.setIsGuestReturn(val);
  };

  const trackingSetPerson = (orderNumber, isGift) => {
    const shopDomain = config.shopDomain;
    const uid = `${shopDomain}::${orderNumber}`;

    updateGrowthBookAttributes(growthbook, {
      userId: uid,
      email,
      loggedIn: true,
    });

    titan.identify(uid);
  };

  const handleSubmitForm = useCallback(
    function handleSubmitForm(event) {
      event?.preventDefault?.();
      actions.setEmail(email);
      actions.setOrderNumber(orderNumber);

      orderFetchDataRef.current = null;

      onOrderFetch({
        email,
        orderNumber,
        giftField,
        isGift,
        csid,
      });
    },
    [actions, onOrderFetch, email, orderNumber, isGift, giftField, csid],
  );

  const loginNode = useMemo(
    () => (
      <>
        <OrderLookupLogin
          disabled={orderFetchLoading}
          orderNumber={orderNumber}
          email={email}
          giftField={giftField}
          error={!!orderFetchError}
          onChangeOrderNumber={handleChangeOrderNumber}
          onChangeEmail={handleChangeEmail}
          onChangeGiftField={handleChangeGiftField}
          onSubmitForm={handleSubmitForm}
          isGift={isGift}
          isTrack={isTrack}
          onChangeIsGift={handleChangeIsGift}
        />
      </>
    ),
    [
      email,
      handleSubmitForm,
      orderFetchError,
      orderFetchLoading,
      orderNumber,
      isGift,
      isTrack,
    ],
  );

  const { isShopNowEnabled } = config;
  if (isShopNowEnabled) {
    useEffect(() => {
      if (shopNowCancelError || shopNowCancelLoading || !shopNowCancelData) {
        return;
      }

      window.localStorage.removeItem("nv_shop_now_checkout");
    }, [
      shopNowCancelData,
      shopNowCancelError,
      shopNowCancelLoading,
      cancelShopNow,
    ]);
  }

  const item = isShopNowEnabled
    ? window.localStorage.getItem("nv_shop_now_checkout")
    : null;

  const shopNowValue = get(
    shopNowData,
    "checkShopNowExpiration.formattedAmount",
  );
  const isShopNowExpired = get(shopNowData, "checkShopNowExpiration.expired");
  const handleCancelShopNow = code => cancelShopNow(code);

  useEffect(() => {
    if (!orderFetchDataRef.current && orderFetchData) {
      orderFetchDataRef.current = orderFetchData;

      actions.setGuestEmail(
        get(orderFetchData, "order.guestLoginEmail", email),
      );
      actions.goToStep(CHOOSE_ITEMS_STEP);

      trackingSetPerson(orderNumber, isGift);
      abTesting.trackResult(countingEvent(LOGIN_SUCCESS_EVENT));
      if (useNewDesign) {
        toggleContainerClass("nvo_container--new-login");
        toggleContainerClass("nvo_container--logged-in");
      }
    }
  }, [actions, ineligible, orderFetchData, orderFetchError]);

  useEffectOnce(() => {
    if (isGift && orderNumber && email && giftField) {
      onOrderFetch({ orderNumber, email, isGift, giftField, csid });
    } else if (orderNumber && email) {
      onOrderFetch({ orderNumber, email, isGift, csid });
    }

    // TODO: error handling
    if (item) {
      const searchString = new URL(item).search;
      const params = new URLSearchParams(searchString);
      const code = params.get("nrvr_code");
      setShopNowCode(code);
      checkShopNow(code);
    }
  });

  const translation1 = isTrack
    ? config.translations.track_app_order_lookup_supplementary_text1
    : config.translations.order_lookup_supplementary_text1;
  const translation2 = isTrack
    ? config.translations.track_app_order_lookup_supplementary_text2
    : config.translations.order_lookup_supplementary_text2;
  const title = isTrack
    ? isGift
      ? config.translations.track_app_order_lookup_gift_title
      : config.translations.track_app_order_lookup_title
    : isGift
    ? config.translations.order_lookup_gift_title_start_return
    : config.translations.order_lookup_title_start_return;

  const shopNowSessionReminder = !isShopNowExpired && (
    <ShopNowExpiration
      value={shopNowValue}
      code={shopNowCode}
      onCancel={handleCancelShopNow}
      cancelStatus={{
        data: shopNowCancelData,
        error: shopNowCancelError,
        loading: shopNowCancelLoading,
      }}
    />
  );

  // after login, if new UI was enabled, we'll also skip the order details header
  if (state.stepStatus[ORDER_LOOKUP_STEP]?.skipped && details) {
    if (shopNowCode && shopNowSessionReminder) {
      return (
        <Grid className={classes.root} spacing={2} container>
          <Grid xs={12} md={8} item>
            {shopNowSessionReminder}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }

  if (useNewDesign && !details) {
    return (
      <Grid className={classes.root} spacing={2} container>
        <Grid xs={12} md={6} item>
          <NthOrderLookup
            loading={orderFetchLoading}
            error={orderFetchError}
            giftReturnEnabled={isGiftReturnsEnabled}
            layout={newDesignLayout}
            values={newUIValues}
            onChange={e => {
              const values = e.value ?? [];
              const stateSetter = (type, setter) =>
                setter(values.find(v => v.type === type)?.value);
              stateSetter("ORDER", setOrderNumber);
              stateSetter("EMAIL", setEmail);
              stateSetter("GIFT_CODE", setGiftField);
            }}
            onClose={e => {
              const toggleGiftReturn = e.subComponent === "return";
              actions.setIsGuestReturn(toggleGiftReturn);
            }}
            onSubmit={e => {
              handleSubmitForm();
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid className={classes.root} spacing={2} container>
      <Grid xs={12} md={8} item>
        {shopNowSessionReminder}
        {details ? (
          <OrderLookupDetails
            details={details}
            email={email}
            returnHistory={returnHistory}
            onExitScreen={handleExit}
          />
        ) : (
          <>
            {translation1 && translation1 !== "" && (
              <Grid spacing={2} container>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: translation1 }}
                    className={classes.supplementaryText1}
                  />
                </Grid>
              </Grid>
            )}
            <Typography
              align="center"
              className={classes.title}
              variant="h1"
              data-styleid="order-lookup-title">
              {title}
            </Typography>

            {loginNode}

            {translation2 && translation2 !== "" && (
              <Grid spacing={2} container>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="body1"
                    align="center"
                    dangerouslySetInnerHTML={{ __html: translation2 }}
                    className={classes.supplementaryText2}
                  />
                </Grid>
              </Grid>
            )}
            {orderFetchLoading && (
              <>
                <Spacer height={16} />
                <Spinner />
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
OrderLookup.propTypes = {
  onOrderFetch: PropTypes.func,
  orderFetchData: PropTypes.object,
  orderFetchError: PropTypes.any,
  orderFetchLoading: PropTypes.any,

  shopNowData: PropTypes.any,
  checkShopNow: PropTypes.func,
  cancelShopNow: PropTypes.func,
  shopNowCancelData: PropTypes.any, // apollo data
  shopNowCancelError: PropTypes.any, // apollo error
  shopNowCancelLoading: PropTypes.bool, // apollo loading flag
};
export default React.memo(OrderLookup);
