import { useMemo, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  compose,
  toPairs,
  map,
  snakeCase,
  groupBy,
  mapValues,
  sumBy,
  get,
  filter,
  fromPairs,
} from "lodash/fp";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import { useRequestStatus } from "../../shared/modules/hooks";

const EMIT_METRICS = gql`
  mutation emitMetrics($metrics: [MetricEmissionInput!]!) {
    emitMetrics(metricEmissions: $metrics)
  }
`;

export const toMetric = (key, value, currencyCode, usdConversionRate) => ({
  metricType: "count",
  metricNameSuffix: `consumer.${snakeCase(key)}`,
  value:
    usdConversionRate > 0
      ? Math.round(value * usdConversionRate)
      : Math.round(value),
  currencyCode: usdConversionRate ? "USD" : currencyCode,
});

// emit datadog metrics
export function useEmitMetrics() {
  const [mutate, mutated] = useMutation(EMIT_METRICS);
  const emitMetrics = useCallback(
    metrics => mutate({ variables: { metrics } }),
    [mutate],
  );

  const status = useRequestStatus(mutated);

  return memoObjectByKeyValues({
    emitMetrics,
    status,
  });
}

export const calculateReturnMetrics = (
  order,
  refundMethod,
  items = [],
  lineItemIntensions = {},
  theOnlyRefundMethodIsOriginalPayment = false,
) => {
  const positiveOnly = num => Math.max(0, num);
  const accumulateValues = (p, c) =>
    compose(
      fromPairs,
      map(([key, value]) => [key, value + c[key] ?? 0]),
      toPairs,
    )(p);
  const currencyCode = order.presentmentCurrency ?? "USD";
  const usdConversionRate = order.presentmentCurrencyEstimatedUsdConversionRate;

  let metrics = items
    .map(item => {
      const ri = item;
      const ei = item.exchange;
      const intension = get([ri.id, ri.localId], lineItemIntensions);
      const riItem = order.itemsById[ri.id];
      const originalValue = (riItem?.priceAmount ?? 0) * ri.quantity;
      const originalValueExchangeable = intension?.exchangeEnabled
        ? originalValue
        : 0;
      let exchangeRetention = ei ? ei.priceAmount * ri.quantity : 0;
      let returnValue = 0;
      let giftCardRetention = 0;
      let shopNowRetention = 0;
      if (refundMethod === "gift_card") {
        giftCardRetention = positiveOnly(originalValue - exchangeRetention);
      } else if (refundMethod === "shop_now") {
        shopNowRetention = positiveOnly(originalValue - exchangeRetention);
      } else {
        returnValue = positiveOnly(originalValue - exchangeRetention);
      }
      let upsellValue = 0;
      if (intension?.changedTo === "exchange") {
        upsellValue = exchangeRetention;
        exchangeRetention = 0;
      }

      // This metric `returnValueNonexchangable` is a subset of `returnValue`
      // (refunded to original payment). It counts the return value which has no
      // choice, because the line item which is return only and refunded to
      // original payment only due to rule settings.
      const returnValueNonexchangable =
        theOnlyRefundMethodIsOriginalPayment && !riItem?.allowExchange
          ? returnValue
          : 0;

      return {
        originalValue, // line item original return value
        originalValueExchangeable, // line item original return value (excluding non-exchangeable)
        returnValue, // total refunded to original payment (including non-refundable)
        returnValueNonexchangable, // refunded to original payment (non-refundable)
        exchangeRetention, // exchanging new line item price at first place
        giftCardRetention, // amount issued as gift card
        shopNowRetention, // amount issued as shop now store credit
        upsellValue, // exchanging new line item price converted in upsell flow
      };
    })
    .reduce(accumulateValues, {
      originalValue: 0,
      originalValueExchangeable: 0,
      returnValue: 0,
      returnValueNonexchangable: 0,
      exchangeRetention: 0,
      giftCardRetention: 0,
      shopNowRetention: 0,
      upsellValue: 0,
    });
  metrics = compose(
    map(([key, value]) =>
      toMetric(key, value, currencyCode, usdConversionRate),
    ),
    toPairs,
  )(metrics);

  let behaviorMetrics = items.map(item => {
    const ri = item;
    const ei = item.exchange;
    const intension = get([ri.id, ri.localId], lineItemIntensions);

    // skip counting non-exchangeable line item
    if (!intension?.exchangeEnabled) return null;

    let group = null;
    if (intension.changedTo === "exchange") {
      group = "line_item_return_upsell";
    } else {
      group = `line_item_${intension.initial}_as_${intension.initial}`;
    }
    // group = "line_item_exchange_deflection"; // @deprecated new intention tracking can not track this event

    return [group, ri.quantity];
  });
  behaviorMetrics = compose(
    mapValues(sumBy(get(1))),
    groupBy(get(0)),
    filter(d => d?.[1] > 0),
  )(behaviorMetrics);
  metrics = metrics.concat(
    toPairs(behaviorMetrics).map(([key, value]) => toMetric(key, value)),
  );

  const rmaBehavior =
    behaviorMetrics.line_item_return_upsell > 0
      ? "rma_return_upsell"
      : behaviorMetrics.line_item_exchange_as_exchange > 0
      ? "rma_exchange_as_exchange"
      : behaviorMetrics.line_item_exchange_deflection > 0
      ? "rma_exchange_deflection"
      : behaviorMetrics.line_item_return_as_return > 0
      ? "rma_return_as_return"
      : "";
  if (rmaBehavior) {
    metrics.push(toMetric(rmaBehavior, 1));
  }

  return metrics;
};
