import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

export const LIST_COUNTRIES = gql`
  query ListCountries($locale: String) {
    listCountries(locale: $locale) {
      countryCode
      country
    }
  }
`;

export const LIST_STATES = gql`
  query ListStates($locale: String, $countryCode: String!) {
    listStatesOfCountryCode(locale: $locale, countryCode: $countryCode) {
      regionCode
      region
    }
  }
`;

export const useCountriesList = locale => {
  const countriesResponse = useQuery(LIST_COUNTRIES);
  const { data: { listCountries = [] } = {} } = countriesResponse;

  const countriesTextMap = useMemo(
    () =>
      listCountries.reduce(
        (acc, { countryCode, country }) => ({
          ...acc,
          [countryCode]: country,
        }),
        {},
      ),
    [listCountries],
  );

  const queryStatus = useMemo(
    () => ({
      called: countriesResponse.called,
      loading: countriesResponse.loading,
      error: countriesResponse.error,
    }),
    [
      countriesResponse.called,
      countriesResponse.loading,
      countriesResponse.error,
    ],
  );

  return useMemo(
    () => ({
      state: {
        countriesTextMap,
        listCountries,
      },
      queryStatus,
    }),
    [countriesTextMap, listCountries, queryStatus],
  );
};

export const useStatesList = countryCode => {
  const statesResponse = useQuery(LIST_STATES, {
    variables: { countryCode },
  });

  const {
    data: { listStatesOfCountryCode: listStates = [] } = {},
  } = statesResponse;

  const statesTextMap = useMemo(
    () =>
      listStates.reduce(
        (acc, { regionCode, region }) => ({
          ...acc,
          [regionCode]: region,
        }),
        {},
      ),
    [listStates],
  );

  const queryStatus = useMemo(
    () => ({
      called: statesResponse.called,
      loading: statesResponse.loading,
      error: statesResponse.error,
    }),
    [statesResponse.called, statesResponse.loading, statesResponse.error],
  );

  return useMemo(
    () => ({
      state: { listStates, statesTextMap },
      queryStatus,
    }),
    [queryStatus, listStates, statesTextMap],
  );
};
