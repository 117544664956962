import { randomIdString } from "./localId";
import { safeLocalStorage } from "./localStorage";

const getAnonymousId = () => {
  let anonymousId = safeLocalStorage.getItem("nv_anonymous_id");
  if (!anonymousId) {
    anonymousId = randomIdString(32);
    safeLocalStorage.setItem("nv_anonymous_id", anonymousId);
  }
  return anonymousId;
};

const newSessionId = () => randomIdString(32);

export const createSessionTracking = ({
  sessionId,
  anonymousId,
  ...cfg
} = {}) => {
  let sid = sessionId ?? newSessionId();
  let getAid = anonymousId ? () => anonymousId : getAnonymousId;

  return {
    get anonymousId() {
      return getAid();
    },
    get sessionId() {
      return sid;
    },
    reset() {
      sid = newSessionId();
      return sid;
    },
    ...cfg,
  };
};

export default createSessionTracking();
