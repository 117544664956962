import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";

import { makeRootStyles } from "../../theme/styles";
import ConfirmationDialog from "../../../shared/components/modal/ConfirmationDialog";
import Spinner from "../../../shared/components/Spinner";
import { config } from "../../config";
import { interpolate } from "../../../shared/modules/template";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      justifyContent: "center",
    },
    title: {
      marginBottom: theme.spacing(2),
      wordWrap: "break-word",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      width: "100%",
    },
    cancel: {
      textTransform: "capitalize",
    },
  }),
  { name: "N0ShopNowExpiration" },
);

const ShopNowExpiration = ({ value, code, onCancel, cancelStatus = {} }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const showCancelReturn = () => setOpen(true);
  const cancelCancelReturn = () => setOpen(false);

  const confirmCancelReturn = useCallback(
    function confirmCancelReturn() {
      setOpen(false);
      onCancel(code);
    },
    [onCancel],
  );

  if (!value) return null;

  return (
    <Grid className={classes.root} spacing={2} container>
      <Grid xs={12} md={8} item>
        <Paper className={classes.container}>
          <>
            {!cancelStatus.data && !cancelStatus.loading && (
              <>
                <Typography variant="h3" className={classes.title}>
                  {interpolate(
                    config.translations.shopnow_outstanding_credit_alert,
                    { amount: value },
                  )}
                </Typography>
                <Link className={classes.cancel} onClick={showCancelReturn}>
                  {config.translations.cancel}
                </Link>
                <ConfirmationDialog
                  cancelButtonVariant="contained"
                  message={
                    config.translations.shopnow_cancel_session_confirm_message
                  }
                  open={open}
                  onConfirm={confirmCancelReturn}
                  onCancel={cancelCancelReturn}
                />
              </>
            )}
            {cancelStatus.loading && !cancelStatus.error && <Spinner />}
            {cancelStatus.data && (
              <Typography variant="h3">
                {config.translations.shopnow_cancel_session_success}
              </Typography>
            )}
          </>
        </Paper>
      </Grid>
    </Grid>
  );
};
ShopNowExpiration.propTypes = {
  value: PropTypes.string,
  code: PropTypes.string,
  onCancel: PropTypes.func,
  cancelStatus: PropTypes.any,
};
export default ShopNowExpiration;
