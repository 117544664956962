import React, { useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageIcon from "@material-ui/icons/Image";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(
  theme => ({
    root: {
      flexDirection: "column",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(32),
      marginBottom: theme.spacing(1),
      overflow: "hidden",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    image: {
      width: "90%",
    },
    imageIcon: {
      fontSize: theme.spacing(6),
    },
    buttonContainer: {
      display: "block",
    },
    button: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginBottom: theme.spacing(1),
    },
    label: {
      color: theme.palette.action.disabled,
      marginLeft: theme.spacing(1),
    },
  }),
  { name: "N0ImageUploadLarge" },
);

const ImageUploadLarge = ({
  className,
  helperText = "Upload a JPG or PNG",
  errorText = "",
  label = "Upload Image",
  onChange,
  preview,
  variant = "normal",
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <div className={classes.root} onClick={handleClick}>
      <Paper className={classes.container}>
        {preview ? (
          <img alt="" className={classes.image} src={preview} />
        ) : (
          <React.Fragment>
            <div className={classes.imageContainer}>
              <ImageIcon color="disabled" className={classes.imageIcon} />
            </div>
            <div className={classes.buttonContainer}>
              <div className={classes.button}>
                <CloudUploadIcon color="disabled" />
                <Typography className={classes.label} variant="body2">
                  <strong>{label}</strong>
                </Typography>
              </div>
            </div>
          </React.Fragment>
        )}
      </Paper>
      {errorText && (
        <Typography color="error" component="p" variant="caption">
          {errorText}
        </Typography>
      )}
      <Typography color="textSecondary" component="p" variant="caption">
        {helperText}
      </Typography>
    </div>
  );
};

export default ImageUploadLarge;
