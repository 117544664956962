import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { useElementId } from "../../modules/localId";

const Autocomplete = ({
  multiple,
  onChange,
  options,
  placeholder,
  value,
  required,
  autoFill,
  hideOptions,
  PopperComponent,
  ...other
}) => {
  const [inputValue, setInputValue] = useState("");
  const randomID = useElementId("autocomplete-");
  const Popper = useMemo(() => (hideOptions ? () => <></> : PopperComponent), [
    hideOptions,
    PopperComponent,
  ]);

  const handleChange = (event, selectedValue) => {
    setInputValue("");

    if (!onChange) return;

    if (multiple && selectedValue !== null) {
      onChange([...selectedValue]);
    } else {
      onChange(selectedValue);
    }
  };

  const handleInputChange = (event, newValue = "") => {
    setInputValue(newValue);
  };

  return (
    <MuiAutocomplete
      multiple={multiple}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      PopperComponent={Popper}
      value={value}
      renderInput={({ inputProps, ...params }) => {
        // HACK: disable browser auto-complete / auto-fill
        inputProps = autoFill
          ? inputProps
          : {
              autoComplete: randomID,
              name: randomID,
              id: randomID,
              ...inputProps,
            };

        return (
          <TextField
            inputProps={inputProps}
            variant="outlined"
            placeholder={placeholder}
            fullWidth
            required={required}
            {...params}
          />
        );
      }}
      {...other}
    />
  );
};

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  autoFill: PropTypes.bool,
  hideOptions: PropTypes.bool,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  PopperComponent: PropTypes.elementType,
};

Autocomplete.defaultProps = {
  options: [],
  autoFill: false,
  hideOptions: false,
};

export default Autocomplete;
