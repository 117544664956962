import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from "@material-ui/core";

const SMALL = 12;
const MEDIUM = 14;
const LARGE = 16;

const useStyle = makeStyles(
  theme => ({
    spinner: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(0.5),
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
  }),
  { name: "N0ButtonBusy" },
);

function ButtonBusy({ isBusy, disabled, size, component, children, ...props }) {
  const classes = useStyle();
  const Component = component;
  const iconSizes = { small: SMALL, medium: MEDIUM, large: LARGE };
  return (
    <Component disabled={isBusy || disabled} size={size} {...props}>
      <div className={classes.wrapper}>
        {children}
        {isBusy && (
          <CircularProgress
            className={classes.spinner}
            size={iconSizes[size] || iconSizes.medium}
          />
        )}
      </div>
    </Component>
  );
}

ButtonBusy.propTypes = {
  isBusy: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  component: PropTypes.elementType,
  ...Button.propTypes,
};

ButtonBusy.defaultProps = {
  isBusy: false,
  size: "medium",
  component: Button,
};

export default ButtonBusy;
