import sessionTracking from "./sessionTracking";

export class Titan {
  constructor({
    endpoint,
    retailerMoniker,
    product = "returns",
    ...defaults
  } = {}) {
    this.endpoint = endpoint; // api endpoint, set to empty string for disabling the logging
    this.retailerMoniker = retailerMoniker;
    this.product = product;
    this.defaults = {
      anonymousId: sessionTracking.anonymousId,
      userId: null,
      channel: "web",
      context: {},
      ...defaults,
    };
  }

  log(type, { props = {}, extra = [], ...payload }) {
    if (this.endpoint === undefined) {
      console.error(new Error("Titan endpoint is undefined"));
    }
    if (!this.retailerMoniker) {
      console.error(new Error("Retailer Moniker is undefined"));
    }
    if (!this.endpoint) return Promise.resolve(false);

    const now = new Date().toISOString();
    let data = {
      ...this.defaults,
      retailer_moniker: this.retailerMoniker,
      type,
      event,
      sentAt: now,
      originalTimestamp: now,
      ...payload,
      properties: {
        narvar_product_context: this.product,
        ...props,
        extra_properties: extra,
      },
    };

    return fetch(`${this.endpoint}/v1/${type}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(this.retailerMoniker + ":")}`,
      },
      body: JSON.stringify(data),
    })
      .then(res => res.text())
      .then(res => res === "OK")
      .catch(err => {
        console.warn(err);
        return false;
      });
  }

  identify(userId, rest) {
    this.defaults.userId = userId;
    return this.log("identify", { userId, ...rest });
  }
  page(name, rest) {
    return this.log("page", { name, ...rest });
  }
  track(event, rest) {
    return this.log("track", { event, ...rest });
  }
}

const titan = new Titan();
export default titan;

window.titan = titan;
