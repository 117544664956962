import { set, merge } from "lodash/fp";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import ResizeObserver from "resize-observer-polyfill";
import { getBranding } from "../../shared/modules/config";

import { memoObjectByKeyValues } from "../../shared/modules/object";

export const FrameDimensionsContext = createContext();

const positiveOnly = num => Math.max(num, 0);

function useFrameDimensionsHook() {
  const [dimensions, setDimensions] = useState({
    windowHeight: window.parentData?.innerHeight ?? 0,
    offsetTop: window.parentData?.offsetTop ?? 0,
    scrollY: window.parentData?.scrollY ?? 0,
    headerHeight: window.parentData?.headerHeight ?? 0,
    footerHeight: window.parentData?.footerHeight ?? 0,
    frameHeight: window.innerHeight ?? 0,
  });

  const initiated = dimensions.windowHeight > 0;
  const isFixed = getBranding("fixed_app_bar");

  const viewportHeight = useMemo(() => {
    const bottom = positiveOnly(
      -dimensions.offsetTop + dimensions.windowHeight - dimensions.frameHeight,
    );
    const top = isFixed
      ? dimensions.headerHeight
      : positiveOnly(dimensions.headerHeight - dimensions.scrollY);
    const ret = dimensions.windowHeight - top - bottom;
    return ret;
  }, [dimensions]);

  useEffect(() => {
    const eventHandler = event => {
      if (event?.data?.type !== "nvo" || !event.isTrusted) return;

      const { action, data } = event.data;
      const updateMapping = {
        "receive:innerHeight": "windowHeight",
        "receive:scrollY": "scrollY",
        "receive:offsetTop": "offsetTop",
      };
      const updateField = updateMapping[action];
      if (updateField) {
        setDimensions(set(updateField, data || 0));
      } else if (action === "receive:layoutDimensions") {
        setDimensions(current => merge(current, data));
      }
    };
    window.addEventListener("message", eventHandler);

    return () => window.removeEventListener("message", eventHandler);
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setDimensions(set("frameHeight", entries[0].contentRect.height));
    });
    observer.observe(document.querySelector("body"));

    return () => observer.disconnect();
  }, []);

  const bottomOfViewport = useCallback(
    (bottom = 0) =>
      initiated
        ? positiveOnly(
            dimensions.frameHeight -
              dimensions.windowHeight +
              dimensions.offsetTop +
              bottom,
          )
        : 0,
    [initiated, dimensions],
  );

  const topOfViewport = useCallback(
    (top = 0) =>
      initiated
        ? positiveOnly(
            -dimensions.offsetTop +
              top +
              (isFixed ? dimensions.headerHeight : 0),
          )
        : 0,
    [initiated, dimensions],
  );

  return memoObjectByKeyValues({
    initiated,
    dimensions: memoObjectByKeyValues({
      ...dimensions,
      viewportHeight,
    }),
    bottomOfViewport,
    topOfViewport,
  });
}

export function useFrameDimensions() {
  return useContext(FrameDimensionsContext);
}

export default function FrameDimensionsProvider({ children }) {
  const state = useFrameDimensionsHook();

  return (
    <FrameDimensionsContext.Provider value={state}>
      {children}
    </FrameDimensionsContext.Provider>
  );
}
