import React, { useState, useCallback, useEffect, useMemo } from "react";
import { makeRootStyles } from "../../../theme/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

export const useStyles = makeRootStyles(
  theme => ({
    previewImage: {
      maxWidth: 100,
      maxHeight: 100,
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
    previewWrapper: {
      width: 100,
      height: 100,
      marginRight: 10,
      position: "relative",
    },
    icon: {
      position: "absolute",
      right: 5,
      top: 5,
      backgroundColor: "white",
      opacity: 0.7,
      mixBlendMode: "normal",
      height: 20,
      borderRadius: 20,
    },
  }),
  { name: "N0PicturePreview" },
);
const PicturePreview = ({ picture, removePicture }) => {
  const classes = useStyles();
  const lUrl = URL.createObjectURL(picture);
  return (
    <div className={classes.previewWrapper}>
      <CloseIcon
        className={classes.icon}
        onClick={() => removePicture(picture)}
      />
      <img src={lUrl} className={classes.previewImage} />
    </div>
  );
};

export default PicturePreview;
