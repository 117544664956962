import React, { FunctionComponent } from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  theme => ({
    labelStrong: {
      ...theme.typography.h4,
    },
  }),
  { name: "N0Checkbox" },
);

const Checkbox = ({ checked, color = "primary", labelStrong, ...props }) => {
  const classes = useStyles();
  const classesOverride = {
    label: labelStrong ? classes.labelStrong : null,
  };
  return (
    <FormControlLabel
      classes={classesOverride}
      checked={checked ? true : false}
      control={<MuiCheckbox style={{ color }} />}
      {...props}
    />
  );
};

export default Checkbox;
