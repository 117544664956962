import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  theme => ({
    root: {},
    title: {
      "& > .MuiTypography-root.MuiTypography-h6": {
        ...theme.typography.h3,
        color: theme.palette.text.primary,
      },
    },
  }),
  { name: "N0ConfirmationDialog" },
);

function ConfirmationDialog(props) {
  const classes = useStyles();
  const {
    title,
    message,
    error,
    loading,
    color,
    open,
    onConfirm,
    onCancel,
    confirmText,
    cancelText,
    cancelButtonVariant,
    confirmButtonVariant,
    confirmOnly,
    confirmButtonAutoFocusDisabled,
    ...otherProps
  } = props;

  const hasContent = message || error;

  const messageNode = useMemo(
    () =>
      typeof message === "string" ? (
        <DialogContentText
          color="textPrimary"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      ) : (
        message
      ),
    [message],
  );
  const errorNode = useMemo(
    () =>
      typeof error === "string" ? (
        <DialogContentText
          color="error"
          dangerouslySetInnerHTML={{
            __html: error,
          }}
        />
      ) : (
        error
      ),
    [error],
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className={classes.root}
      {...otherProps}>
      {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
      {hasContent && (
        <DialogContent>
          {messageNode}
          {errorNode}
        </DialogContent>
      )}
      <DialogActions>
        {!confirmOnly && (
          <Button
            size="medium"
            variant={cancelButtonVariant}
            disabled={loading}
            onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button
          size="medium"
          color={color}
          variant={confirmButtonVariant}
          autoFocus={!confirmButtonAutoFocusDisabled}
          disabled={loading}
          onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  color: PropTypes.string,
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmButtonVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmOnly: PropTypes.bool,
  confirmButtonAutoFocusDisabled: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
  color: "primary",
  confirmText: "Confirm",
  cancelText: "Cancel",
  cancelButtonVariant: "outlined", // other options are outlined and text
  confirmButtonVariant: "contained", // other options are outlined and text
};

export default ConfirmationDialog;
