const n3 = {
  example: "123",
  regex: /^[0-9]{3}$/,
};
const n4 = {
  example: "1234",
  regex: /^[0-9]{4}$/,
};
const n5 = {
  example: "12345",
  regex: /^[0-9]{5}$/,
};
const n6 = {
  example: "123456",
  regex: /^[0-9]{6}$/,
};
const n7 = {
  example: "1234567",
  regex: /^[0-9]{7}$/,
};

export default {
  AD: {
    example: "12345, AA123",
    regex: /^[0-9A-Za-z]{2}[0-9]{3}$/,
  },
  AM: n4,
  AR: n4,
  AS: n5,
  AT: n4,
  AU: n4,
  AZ: {
    example: "1234, 123456",
    regex: /^([0-9]{4}|[0-9]{6})$/,
  },
  BA: n5,
  BD: n4,
  BE: n4,
  BG: n4,
  BN: {
    example: "AB1234",
    regex: /^[a-zA-Z]{2}[0-9]{4}$/,
  },
  BR: {
    example: "12345, 12345-678, 12345678",
    regex: /^([0-9]{5}-[0-9]{3}|[0-9]{8}|[0-9]{5})$/,
  },
  BY: n6,
  CA: {
    example: "A1B 2C, A1B 2C3",
    regex: /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]?$/,
  },
  CH: n4,
  CN: n6,
  CO: n6,
  CU: n5,
  CY: n4,
  CZ: {
    example: "123 45",
    regex: /^[0-9]{3} [0-9]{2}$/,
  },
  DE: n5,
  DK: n4,
  DZ: n5,
  EC: n6,
  EE: n5,
  ES: n5,
  FI: n5,
  FM: n5,
  FO: n3,
  FR: n5,
  GB: {
    example: "EC1A 1BB",
    regex: /^([a-zA-Z][a-zA-Z]?[0-9][0-9]?[a-zA-Z]?) [0-9][a-zA-Z]{2}$/,
  },
  GE: n4,
  GF: n5,
  GG: {
    example: "EC1A 1BB",
    regex: /^([a-zA-Z][a-zA-Z]?[0-9][0-9]?[a-zA-Z]?) [0-9][a-zA-Z]{2}$/,
  },
  GU: {
    example: "12345, 12345-1234",
    regex: /^[0-9]{5}(-[0-9]{4})?$/,
  },
  HR: n5,
  HU: n4,
  IC: n5,
  ID: n5,
  IL: n7,
  IN: n6,
  IS: n3,
  IT: n5,
  JE: {
    example: "EC1 1BB",
    regex: /^([a-zA-Z][a-zA-Z]?[0-9][0-9]?[a-zA-Z]?) [0-9][a-zA-Z]{2}$/,
  },
  JP: {
    example: "123-1234",
    regex: /^[0-9]{3}-[0-9]{4}$/,
  },
  KG: n6,
  KH: n6,
  KR: n5,
  KV: n5,
  KZ: n6,
  LI: n4,
  LT: n5,
  LU: n4,
  LV: n4,
  MA: n5,
  MC: n5,
  MD: n4,
  ME: n5,
  MG: n3,
  MH: n5,
  MK: n4,
  MN: {
    example: "12345, 123456",
    regex: /^[0-9]{5}[0-9]?$/,
  },
  MP: n5,
  MQ: n5,
  MV: {
    example: "1234, 12345",
    regex: /^([0-9]{4}|[0-9]{5})$/,
  },
  MX: n5,
  MY: n5,
  NC: n5,
  NL: {
    example: "1234, 1234 AB",
    regex: /^[0-9]{4}( [a-zA-Z]{2})?$/,
  },
  NO: n4,
  NZ: n4,
  PF: n5,
  PG: n3,
  PH: n4,
  PK: n5,
  PL: {
    example: "12-123",
    regex: /^[0-9]{2}-[0-9]{3}$/,
  },
  PR: n5,
  PT: {
    example: "1234-123",
    regex: /^[0-9]{4}-[0-9]{3}$/,
  },
  PW: n5,
  RE: n5,
  RO: n6,
  RS: n5,
  RU: n6,
  SE: {
    example: "123 12",
    regex: /^[0-9]{3} [0-9]{2}$/,
  },
  SG: n6,
  SH: {
    example: "ABCD 1AB",
    regex: /^[a-zA-Z]{4} [0-9][a-zA-Z]{2}$/,
  },
  SI: n4,
  SK: {
    example: "123 12",
    regex: /^[0-9]{3} [0-9]{2}$/,
  },
  SM: n5,
  SZ: {
    example: "A1234",
    regex: /^[a-zA-Z][0-9]{4}$/,
  },
  TH: n5,
  TM: n6,
  TN: n4,
  TR: n5,
  TW: {
    example: "123, 12345",
    regex: /^([0-9]{3}|[0-9]{5})$/,
  },
  UA: n5,
  US: {
    example: "12345, 12345-1234",
    regex: /^[0-9]{5}(-[0-9]{4})?$/,
  },
  UZ: n6,
  VI: n5,
  XY: n5,
  YT: n5,
  ZA: n4,
};
