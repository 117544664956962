import React, { useState, useCallback, useMemo } from "react";
import { makeRootStyles } from "../../../theme/styles";
import Typography from "@material-ui/core/Typography";
import { useDropzone } from "react-dropzone";
import PicturePreview from "./PicturePreview";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import uniq from "lodash/uniq";
import Button from "@material-ui/core/Button";
import { config } from "../../../config";
import { flatten } from "lodash/fp";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      width: "100%",
      padding: theme.spacing(0, 3, 3),
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
    },
    previewSection: {
      display: "flex",
      marginBottom: 20,
    },
    helperText: {
      marginBottom: 15,
    },
    dropzoneText: {
      marginTop: 10,
      color: "#999",
    },
    errorText: {
      color: "red",
      marginTop: 20,
    },
    submitButton: {},
  }),
  { name: "N0PictureUpload" },
);

const errorDictionary = {
  "file-invalid-type":
    config.translations.choose_items_upload_pictures_error_invalid_type,
  "file-too-large":
    config.translations.choose_items_upload_pictures_error_file_size,
  "too-many-files":
    config.translations.choose_items_upload_pictures_error_too_many,
};

const PictureUpload = ({
  pictures = [],
  setPicturesForItem,
  onContinue,
  required,
}) => {
  const classes = useStyles();
  // const [pictures, setPictures] = useState([])
  const [errorMessage, setErrorMessage] = useState();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (pictures.length > 2) {
        setErrorMessage(errorDictionary["too-many-files"]);
      } else {
        const newPics = [...pictures, ...acceptedFiles];
        setPicturesForItem(newPics);
      }
      if (rejectedFiles.length > 0) {
        const msg = uniq(
          flatten(rejectedFiles.map(file => file.errors.map(e => e.code))),
        ).reduce((memo, error) => {
          return memo + errorDictionary[error] + " ";
        }, "");
        setErrorMessage(msg);
      } else {
        setErrorMessage(null);
      }
    },
    [pictures, setPicturesForItem, setErrorMessage],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 2,
    maxSize: 10000000,
  });

  const removePicture = picture => {
    const newPics = pictures.filter(oldpic => picture != oldpic);
    setPicturesForItem(newPics);
  };

  const baseDropzoneStyle = {
    display: "flex",
    height: 150,
    backgroundColor: "#f6f6f6",
    borderColor: "#999",
    borderWidth: 2,
    borderStyle: "dashed",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    marginBottom: 20,
  };

  const smallerDropZoneStyle = {
    height: 80, // The thumbnails take some space, so make it shorter
  };

  const activeDropzoneStyle = {
    backgroundColor: "#ccc",
  };

  const dropzoneStyle = useMemo(
    () => ({
      ...baseDropzoneStyle,
      ...(isDragActive ? activeDropzoneStyle : {}),
      ...(pictures.length > 0 ? smallerDropZoneStyle : {}),
    }),
    [isDragActive, pictures],
  );

  return (
    <div className={classes.root}>
      {pictures.length < 2 && (
        <Typography variant="body2" className={classes.helperText}>
          {config.translations.choose_items_upload_pictures_error_too_many}
        </Typography>
      )}
      {pictures.length < 2 && (
        <div {...getRootProps({ style: dropzoneStyle })}>
          <input {...getInputProps()} />
          {
            <>
              <PhotoLibraryIcon />
              <Typography className={classes.dropzoneText}>
                {config.translations.choose_items_upload_add_photos}
              </Typography>
            </>
          }
        </div>
      )}
      <div className={classes.previewSection}>
        {pictures &&
          pictures.map(picture => (
            <PicturePreview
              picture={picture}
              key={picture.name}
              removePicture={removePicture}
            />
          ))}
      </div>
      {errorMessage && (
        <Typography className={classes.errorText}>{errorMessage}</Typography>
      )}

      <Button
        className={classes.submitButton}
        color="primary"
        disabled={required && pictures.length < 1}
        size="medium"
        onClick={onContinue}
        variant="contained"
        fullWidth>
        <strong>
          {config.translations.choose_items_comment_continue_label}
        </strong>
      </Button>
    </div>
  );
};

export default PictureUpload;
