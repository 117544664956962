import { dinero, toDecimal } from "dinero.js";
/*eslint import/namespace: [2, { allowComputed: true }]*/
import * as currencies from "@dinero.js/currencies";

export const getDineroCurrency = (currency = "USD") =>
  currencies[currency.toUpperCase()] ?? currencies.USD;

export const getCurrencyMultiplier = (currency = "USD") =>
  10 ** getDineroCurrency(currency).exponent;

export const subunitToAmount = (subunit = 0, currency = "USD") =>
  subunit / getCurrencyMultiplier(currency);

export const amountToSubunit = (amount = 0, currency = "USD") =>
  amount * getCurrencyMultiplier(currency);

export const stringToSubunit = (amount = "0") =>
  parseInt(amount.replace(/[^0-9-]/g, ""));

export const stringToAmount = (amount = "0", currency = "USD") =>
  typeof amount === "string"
    ? subunitToAmount(stringToSubunit(amount), currency)
    : isNaN(amount)
    ? 0
    : amount || 0;

export const formattedStringToAmount = (
  formattedString = "0",
  currency = "USD",
  locale = "en",
) => {
  const format = getCurrencyFormat(currency, locale);
  const str = formattedString
    .replace(new RegExp("\\" + format.thousandSeparator, "g"), "")
    .replace(new RegExp("\\" + format.decimalSeparator, "g"), ".");
  return parseFloat(str);
};

export const formattedStringToSubunit = (
  formattedString = "0",
  currency = "USD",
  locale = "en",
) =>
  amountToSubunit(
    formattedStringToAmount(formattedString, currency, locale),
    currency,
  );

export const formatCurrency = (amount = 0, currency = "USD", locale = "en") => {
  const amountNumber = stringToAmount(amount, currency);

  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    });
    return formatter.format(amountNumber);
  } catch (e) {
    const value = dinero({
      currency: getDineroCurrency(currency),
      amount: amountNumber * getCurrencyMultiplier(currency),
    });
    return toDecimal(value);
  }
};

export const getCurrencyFormat = (currency = "USD", locale = "en") => {
  const cur = getDineroCurrency(currency);
  const testStr = formatCurrency(1000, currency, locale);
  const reg =
    new RegExp(
      `^(.*)1(.*)000${
        cur.exponent > 0
          ? "(.*)" + new Array(cur.exponent).fill("0").join("")
          : ""
      }(.*)$`,
    ) ?? [];
  const matches = testStr.match(reg);
  const thousandSeparator = matches[2] ?? ",";
  const decimalSeparator = cur.exponent > 0 ? matches[3] ?? "." : "";
  const symbol = ((matches[1] ?? "") + (matches[4] ?? "")).trim() || "$";
  const ret = {
    ...cur,
    thousandSeparator,
    decimalSeparator,
    symbol,
    code: currency,
  };
  return ret;
};
