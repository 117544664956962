import { GrowthBook } from "@growthbook/growthbook-react";
// FIXME: rudder-sdk-js will throw fatal error during import on incognito mode,
// because it would access local storage with our ifrmae.
// import * as rudder from "rudder-sdk-js";
import { safeLocalStorage } from "./localStorage";
import { randomIdString } from "./localId";
import sessionTracking from "./sessionTracking";

const GB_API_HOST = "https://cdn.growthbook.io";
const GB_API_KEY = "sdk-ow4DG1Yy8SUTad99";
const GB_DECRYP_KEY = "LuPQKx9+tsIo1I3fk/PAhA==";
const GB_API_KEY_DEV = "sdk-IcniNcaWcDp3BNFw";
const GB_DECRYP_KEY_DEV = "";

let defaultAttributes = {};

export const initGrowthBook = cfg => {
  const dev = cfg?.enableDevMode;
  defaultAttributes = {
    ...cfg?.attributes,
    sessionId: sessionTracking.sessionId,
    anonymousId: sessionTracking.anonymousId,
    userId: sessionTracking.anonymousId,
  };

  return new GrowthBook({
    apiHost: GB_API_HOST,
    ...(dev
      ? {
          clientKey: GB_API_KEY_DEV,
          decryptionKey: GB_DECRYP_KEY_DEV,
        }
      : {
          clientKey: GB_API_KEY,
          decryptionKey: GB_DECRYP_KEY,
        }),
    ...cfg,
    attributes: defaultAttributes,
  });
};

export const updateGrowthBookAttributes = (growthBook, attrs) => {
  return growthBook?.setAttributes({
    ...growthBook?.getAttributes(),
    ...attrs,
  });
};

export const resetGrowthBookAttributes = growthBook => {
  return growthBook?.setAttributes({
    ...defaultAttributes,
    sessionId: sessionTracking.reset(),
    anonymousId: sessionTracking.anonymousId,
    userId: sessionTracking.anonymousId,
  });
};
