import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgress } from "@material-ui/core";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { useCancelReturn } from "../../data/cancelReturn";
import ConfirmationDialog from "../../../shared/components/modal/ConfirmationDialog";

const useStyles = makeRootStyles(
  theme => ({
    orderNumber: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    orderNumberText: {},
    exitButton: {
      display: "flex",
    },
    exitIcon: {},
    cancelReturnsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      marginTop: theme.spacing(2),
    },
    cancel: {},
  }),
  { name: "N0OrderLookupDetails" },
);

function OrderLookupDetails({ details, email, returnHistory, onExitScreen }) {
  const classes = useStyles();
  const { cancelReturn, cancelStatus } = useCancelReturn();
  const [open, setOpen] = useState(false);

  const hasCancellableReturns =
    (!config.isNthUi && returnHistory?.length > 0) ||
    (config.isNthUi && returnHistory?.filter(r => r.cancellable)?.length > 0);

  useEffect(() => {
    // TODO: cancelStatus loading and error handling
    if (!cancelStatus.loading && cancelStatus.data) {
      onExitScreen();
    }
  }, [cancelStatus, onExitScreen]);

  const showCancelReturn = () => setOpen(true);

  const cancelCancelReturn = () => setOpen(false);

  const confirmCancelReturn = useCallback(
    function confirmCancelReturn() {
      setOpen(false);

      cancelReturn({
        orderNumber: details.orderNumber,
        email,
      });
    },
    [details.orderNumber, cancelReturn],
  );

  return (
    <>
      <div className={classes.orderNumber}>
        <Typography
          align="center"
          variant="h3"
          className={classes.orderNumberText}>
          {config.translations.order_lookup_order_number} {details.orderNumber}
        </Typography>
        {!cancelStatus.loading && (
          <IconButton
            id="order-lookup-exit"
            className={classes.exitButton}
            color="primary"
            size="small"
            edge="end"
            onClick={onExitScreen}>
            <CloseIcon className={classes.exitIcon} />
          </IconButton>
        )}
      </div>
      {!cancelStatus.loading && cancelStatus.error && (
        <Typography align="center">Error</Typography>
      ) // TODO: have a global consumer app error snackbar.
      }
      {hasCancellableReturns && (
        <div className={classes.cancelReturnsContainer}>
          {cancelStatus.loading && (
            <CircularProgress color="inherit" size={20} />
          )}
          {!cancelStatus.loading && !cancelStatus.data && (
            <>
              <Typography variant="body1">
                {config.translations.order_lookup_option_return_progress}
                &nbsp;
              </Typography>
              <Link
                className={classes.cancel}
                onClick={showCancelReturn}
                dangerouslySetInnerHTML={{
                  __html: config.translations.order_lookup_option_cancel_return,
                }}
              />
              <ConfirmationDialog
                cancelButtonVariant="contained"
                message={config.translations.order_lookup_cancel_return_modal}
                open={open}
                onConfirm={confirmCancelReturn}
                onCancel={cancelCancelReturn}
              />
            </>
          )}
          {cancelStatus.data && (
            <Typography
              dangerouslySetInnerHTML={{
                __html:
                  config.translations.order_lookup_return_has_been_cancelled,
              }}
            />
          )}
        </div>
      )}
    </>
  );
}
OrderLookupDetails.propTypes = {
  details: PropTypes.object,
  email: PropTypes.string,
  returnHistory: PropTypes.array,
  onExitScreen: PropTypes.func,
};
export default React.memo(OrderLookupDetails);
