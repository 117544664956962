import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import every from "lodash/every";
import keyBy from "lodash/keyBy";
import some from "lodash/some";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import {
  reduce,
  pick,
  set,
  compose,
  merge,
  filter,
  map,
  sortBy,
  sumBy,
  find,
  fromPairs,
  groupBy,
  stubTrue,
} from "lodash/fp";

import { config } from "../../config";
import {
  useReturnStepsActions,
  useReturnStepsState,
  CHOOSE_METHOD_STEP,
  CHOOSE_ITEMS_STEP,
  useReturnStepIsActive,
  ORDER_LOOKUP_STEP,
  useRegisterStepStatus,
} from "../../contexts/returnSteps";
import { makeRootStyles } from "../../theme/styles";
import Item from "./Item";
import ItemsCarousel from "./ItemsCarousel";
import {
  buildCarouselItems,
  buildVariants,
  createCarouselItemKey,
  isSameCarouselItem,
} from "../../modules/items";
import { ItemSelector, ReturnReasons } from "@narvar/nth-kit-returns-headless";
import { renameKeys } from "../../../shared/modules/object";
import { useFrameDimensions } from "../../contexts/frameDimensions";
import useEvenExchangeProduct from "../../data/evenExchangeProduct";
import locale from "../../../shared/modules/lang";
import { useCancelReturn } from "../../data/cancelReturn";
import { simplifyReturnStatus } from "../../../shared/modules/returnStatuses";
import { getTranslation } from "../../../shared/modules/config";
import { useTypeForm } from "../../contexts/typeForm";
import useLoginSession from "../../hooks/useLoginSession";
import { useNthReturnsActions } from "../../contexts/nthContext";
import Sparsem, {
  UNAVAILABLE_IN_ANY_SET,
  UNAVAILABLE_IN_THIS_SET,
} from "../../modules/sparsem";
import { toggleContainerClass } from "../../../shared/modules/frame";
import { getReshopCancelError } from "../../../shared/modules/decodeError";
import { interpolate } from "../../../shared/modules/template";
import { useChangeRefundMethod } from "../../data/changeRefundMethod";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    supplementaryText: {
      marginBottom: theme.spacing(3),
    },
    chooseButton: {
      marginTop: theme.spacing(1),
    },
    newUiDisclaimer: {
      padding: theme.spacing(1.5, 1.5, 0, 1.5),
    },
  }),
  { name: "N0ChooseItems" },
);

const isEligibleItem = (item, returnReasons) => {
  if (!item) return false;

  const {
    quantity,
    returnableQuantity,
    returnReason,
    eligible,
    childReturnReason,
    comment,
    pictures = [],
  } = item;

  // quantity
  if (!quantity || quantity <= 0) return false;

  // picture required
  const rrLookup = returnReasons.reduce((memo, rr) => {
    memo[rr.id] = rr;
    return memo;
  }, {});
  // source of data maybe dirty (pictures disabled but required)
  if (
    rrLookup?.[returnReason]?.enableCustomerPictures &&
    rrLookup?.[returnReason]?.requireCustomerPictures &&
    pictures.length < 1
  )
    return false;

  // test on childReturnReason if it is given
  const target = childReturnReason || item;
  if (!target.eligible) return false; // return reason / child reason is eligible
  if (!rrLookup[target.returnReason]) return false; // reason code exists
  // comment required
  if (rrLookup[target.returnReason]?.isCommentMandatory) {
    if (!comment || typeof comment !== "string" || !comment.trim()) {
      return false;
    }
  }

  return true;
};

const ChooseItems = ({ orderFetchData }) => {
  const itemsCarouselRef = useRef(null);
  const classes = useStyles();
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const loginSession = useLoginSession();
  const [localSelected, setLocalSelected] = useState([]);

  const { order, returnReasons, returnHistory = [] } = orderFetchData;
  const selectedByKey = useMemo(
    () => keyBy(localSelected, createCarouselItemKey),
    [localSelected],
  );
  const [continuePendingByKey, setContinuePendingByKey] = useState({});
  const localItems = useMemo(
    () => buildCarouselItems(order.items, localSelected),
    [localSelected, order.items],
  );
  const sortedLocalItems = useMemo(() => {
    return sortBy(i => !i.someReasonsEligible, localItems);
  }, [localItems]);
  const addExtraSpace = useMemo(
    () =>
      some(localSelected, item => {
        const found = order.items.find(i => i.id === item.id);
        return found.returnableQuantity > 1;
      }),
    [order.items, localSelected],
  );
  const hasExchanges = useMemo(
    () => state.items.some(i => i.type === "exchange"),
    [state.items],
  );
  const hasReturns = useMemo(() => state.items.some(i => i.type === "return"), [
    state.items,
  ]);
  const hasStoreCredit = some(state.items, "isStoreCredit");
  const showItemsLimitDisclaimer = order.items.length >= 100;

  const isActiveStep = useReturnStepIsActive(CHOOSE_ITEMS_STEP);
  const showNextBtn = isActiveStep;

  const isGiftReturnsEnabled =
    config.isGiftZipEnabled || config.isGiftReceiptEnabled;
  const showCreateGiftReturn =
    isGiftReturnsEnabled && !state.guestReturn && !!state.csid;

  const handleCreateGiftReturn = () => {
    const zip = order.fromZip || order.billingZip;
    const { orderNumber } = state;
    loginSession.logout();
    actions.setOrderNumber(orderNumber);
    actions.setEmail("");
    actions.setGiftField(zip);
    actions.setIsGuestReturn(true);
  };

  const getSelectedItem = useCallback(
    item => selectedByKey[createCarouselItemKey(item)],
    [selectedByKey],
  );

  const addLocalItem = useCallback(
    item => {
      setLocalSelected(items => [
        ...items.filter(
          i =>
            !!i.type &&
            !isSameCarouselItem(i, item) &&
            order.items.findIndex(s => s.id === i.id) > -1,
        ),
        item,
      ]);
    },
    [order.items],
  );

  const removeLocalItem = useCallback(item => {
    setLocalSelected(items => items.filter(i => !isSameCarouselItem(i, item)));
    actions.clearLineItemIntension({
      lineItemId: item.id,
      localId: item.localId,
    });
  }, []);

  const setStateToLocalItem = useCallback(
    (item, data = {}) => {
      let localItem = {
        id: item.id,
        localId: item.localId,
        ...getSelectedItem(item),
        ...data,
      };
      // TODO: isStoreCredit items will come in as return items in submit_return
      if (localItem?.exchange?.isStoreCredit) {
        localItem.type = "return";
        localItem.exchange = undefined;
        localItem.isStoreCredit = true;
      }
      addLocalItem(localItem);
      actions.setLineItemInitialIntension({
        lineItemId: item.id,
        localId: item.localId,
        type: localItem.type,
        exchangeEnabled: item.allowExchange,
      });
    },
    [addLocalItem, getSelectedItem],
  );

  const setPicturesForItem = useCallback(
    (item, pictures) => {
      setStateToLocalItem(item, { pictures });
    },
    [setStateToLocalItem],
  );

  const handleRemoveItem = useCallback(item => removeLocalItem(item), [
    removeLocalItem,
  ]);

  useEffect(() => {
    const keys = Object.keys(selectedByKey);
    setContinuePendingByKey(pick(keys));
  }, [selectedByKey]);

  const handleChangeContinuePending = useCallback((item, isContinuePending) => {
    const key = createCarouselItemKey(item);
    setContinuePendingByKey(set(key, isContinuePending));
  }, []);

  const handleChangeReason = useCallback(
    (item, reason) => {
      const { eligible, ineligibleReason, returnReason } = reason;

      const hasChildReturnReason = item.eligibilityCriteria.some(
        i => i.returnReasonParent === returnReason,
      );

      setStateToLocalItem(item, {
        eligible,
        ineligibleReason,
        returnReason,
        childReturnReason: hasChildReturnReason ? { returnReason: null } : null,
      });
    },
    [setStateToLocalItem],
  );

  const handleChangeChildReason = useCallback(
    (item, reason) => {
      const { eligible, ineligibleReason, returnReason } = reason;

      setStateToLocalItem(item, {
        eligible,
        ineligibleReason,
        childReturnReason: { eligible, ineligibleReason, returnReason },
      });
    },
    [setStateToLocalItem],
  );

  const handleChangeQuantity = useCallback(
    (item, quantity) => {
      setStateToLocalItem(item, { quantity });
    },
    [setStateToLocalItem],
  );

  const handleChangeComment = useCallback(
    (item, comment) => {
      setStateToLocalItem(item, { comment: (comment || "").trim() });
    },
    [setStateToLocalItem],
  );

  const handleNextStep = useCallback(() => {
    actions.goToStep(CHOOSE_METHOD_STEP);
  }, []);

  const isValidSelected = useMemo(() => {
    if (state.items.length < 1) return false;
    if (Object.values(continuePendingByKey).some(d => d)) return false; // no pending (eg. opened exchange variants, pictures upload or comment)

    const allItemsEligible = every(localSelected, item =>
      isEligibleItem(item, returnReasons),
    );
    return allItemsEligible;
  }, [returnReasons, state, localSelected, continuePendingByKey]);

  const actionNode = useMemo(() => {
    return (
      <Grid xs={12} sm={6} md={4} item>
        <Button
          id="choose-items-next"
          className={classes.chooseButton}
          color="primary"
          disabled={!isValidSelected}
          onClick={handleNextStep}
          size="large"
          variant="contained"
          fullWidth>
          <strong>{config.translations.choose_items_next_button_label}</strong>
        </Button>
      </Grid>
    );
  }, [handleNextStep, isValidSelected]);

  // TRICKS: memorized an empty array to avoid irrelevant rerender. Without the
  // memorized array, it cases the `useMemo` of all child components, and hooks
  // (eg. use-item-state, use-item-selectors), who depends on ineligibleReasons,
  // would rerender every time.
  const emptyIneligibleReasons = useMemo(() => [], []);
  const itemIneligibleReasons = item => {
    if (item.someReasonsEligible) {
      return emptyIneligibleReasons;
    }

    const matrixIntersection = ary =>
      reduce(intersection, ary && ary[0], ary) || emptyIneligibleReasons;
    const reasonsMatrix = item.eligibilityCriteria.map(
      ec => ec.ineligibleReason,
    );
    return uniq(matrixIntersection(reasonsMatrix));
  };

  const commonIneligibleReasons = intersection(
    ...sortedLocalItems.map(itemIneligibleReasons),
  );

  useEffect(() => {
    const eligibleItems = localSelected.filter(item =>
      isEligibleItem(item, returnReasons),
    );
    actions.setSelectedItems(eligibleItems);
  }, [actions, localSelected, returnReasons]);

  const supplementaryText =
    config.translations.choose_items_supplementary &&
    config.translations.choose_items_supplementary !== "" &&
    config.translations.choose_items_supplementary !== " ";

  // TODO: fix the backend then use order.eligible instead
  const orderEligible = useMemo(
    () => order.items.some(item => item.someReasonsEligible),
    [order],
  );

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const useNewDesign = config.isNthUi;
  const isTrack = state.mode === "track";
  useRegisterStepStatus(ORDER_LOOKUP_STEP, { skipped: useNewDesign });

  const typeForm = useTypeForm();
  const { bottomOfViewport, topOfViewport } = useFrameDimensions();
  const viewportAnchor = useMemo(
    () => ({
      offsetTop: topOfViewport(0),
      offsetBottom: bottomOfViewport(0),
    }),
    [topOfViewport, bottomOfViewport],
  );
  const nthReturnsActions = useNthReturnsActions();
  const mapShipmentActionStatus = status => {
    return status?.error
      ? "error"
      : status?.loading
      ? "loading"
      : status?.data
      ? "success"
      : undefined;
  };
  const {
    changeRefundMethod,
    status: changeRefundMethodStatus,
  } = useChangeRefundMethod();
  const { cancelReturn, cancelStatus } = useCancelReturn();
  const [returnAction, setReturnAction] = useState();
  const returnActionStatus = useMemo(() => {
    const { returnId } = returnAction ?? {};
    if (!returnId) return;

    const reshopCancelError = getReshopCancelError(cancelStatus.error);
    const reshopCancelErrorMessage =
      reshopCancelError &&
      interpolate(
        config.translations.choose_items_rma_action_reshop_cancel_error,
        { refund_id: reshopCancelError.reshop_refund_id },
      );

    if (returnAction.action === "cancel") {
      const status = mapShipmentActionStatus(cancelStatus);
      const message =
        status === "error"
          ? reshopCancelError
            ? reshopCancelErrorMessage
            : config.translations.choose_items_rma_action_cancel_error
          : undefined;

      return { returnId, status, message };
    }
    if (returnAction.action === "changeRefundMethod") {
      const status = mapShipmentActionStatus(changeRefundMethodStatus);
      const message = reshopCancelError
        ? reshopCancelErrorMessage
        : changeRefundMethodStatus.error?.message;
      return { returnId, status, message };
    }
  }, [returnAction, cancelStatus, changeRefundMethodStatus]);

  const mapVariantInfo = (variantInfoArray, options) =>
    fromPairs(
      variantInfoArray.map((v, i) => [
        v.name,
        {
          optionId: v.name,
          optionName: v.name,
          optionPosition:
            v.position ??
            options?.find(opt => opt.name === v.name)?.position ??
            i,
          value: v.value,
          name: v.value,
        },
      ]),
    );

  const newLineItems = useMemo(
    () =>
      sortedLocalItems.map(item => {
        const sortByReasonPriority = sortBy(
          criteria =>
            orderFetchData?.returnReasonsById?.[criteria.returnReason]
              ?.priority,
        );
        let ret = compose(
          merge({
            price: item.hideDisplayPrice
              ? undefined
              : {
                  value:
                    item.priceAmount * order.presentmentCurrencySubunitToUnit,
                  currency: order.presentmentCurrency,
                },
            eligibilities: {
              return: {
                isEligible: item.allowReturn,
                message:
                  !item.allowReturn && !item.allowExchange
                    ? itemIneligibleReasons(item).join("<br/>")
                    : undefined,
              },
              exchange: {
                isEligible: item.allowExchange,
              },
              repair: {
                isEligible: false,
              },
            },
            availableReasons: compose(
              map(criteria => ({
                ...criteria,
                id: criteria.returnReason,
                name: criteria.returnReasonTitle,
                childIds: criteria.returnReasonParent
                  ? null
                  : compose(
                      map(c => c.returnReason),
                      sortByReasonPriority,
                      filter(
                        c => criteria.returnReason === c.returnReasonParent,
                      ),
                    )(item.eligibilityCriteria),
              })),
              sortByReasonPriority,
              filter(
                criteria =>
                  !criteria.returnReasonParent ||
                  item.eligibilityCriteria.some(
                    parent =>
                      parent.returnReason === criteria.returnReasonParent,
                  ),
              ),
            )(item.eligibilityCriteria),
            variantInfo: mapVariantInfo(item.variantInfo),
          }),
          renameKeys({
            id: "itemId",
            productTitle: "name",
            productImageUrl: "imageUrl",
            returnableQuantity: "remainingQuantity",
            variantInfo: "variantInfoOriginal",
            sku: "_sku",
          }),
        )(item);

        return ret;
      }),
    [orderFetchData, order, sortedLocalItems],
  );
  const [selectingReasonId, setSelectingReasonId] = useState();
  const [newSelectingItem, setNewSelectingItem] = useState();
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const [exchangeVariantInfo, setExchangeVariantInfo] = useState();

  const displayEmail = state.guestReturn
    ? state.decodedGuestEmail
    : state.email;
  const customer = useMemo(
    () =>
      order
        ? {
            firstName: order.fromFirstName,
            lastName: order.fromLastName,
            fullName: `${order.fromFirstName} ${order.fromLastName}`,
            email: displayEmail,
          }
        : undefined,
    [order, displayEmail],
  );

  const stepsProps = useMemo(() => {
    if (!newSelectingItem) return {};
    const reason = orderFetchData.returnReasonsById[selectingReasonId];
    const parentReason = orderFetchData.returnReasonsById[reason?.parentId];
    return {
      exchanges: {
        enabled: newSelectingItem?.eligibilities?.exchange?.isEligible,
        shopNow: config.isShopNowEnabled && config.isShopNowWithCreditEnabled,
      },
      pictures: {
        // picture requirements are set in parent reason
        enabled: (parentReason ?? reason)?.enableCustomerPictures,
        required: (parentReason ?? reason)?.requireCustomerPictures,
        maxCount: 2,
        maxSize: 10 << 20, // 10MB
      },
      comments: {
        required: reason?.isCommentMandatory,
      },
    };
  }, [newSelectingItem, selectingReasonId, orderFetchData.returnReasonsById]);

  const exchangeCurrency = order?.presentmentCurrency;
  const {
    result: exchangeResult,
    status: exchangeStatus,
  } = useEvenExchangeProduct({
    query: newSelectingItem?.name ?? "",
    productId: newSelectingItem?.productId ?? "",
    variantId: newSelectingItem?.variantId ?? "",
    price: newSelectingItem?.priceAmount ?? 0,
    compareAtPrice: newSelectingItem?.compareAtPriceAmount,
    displayPrice: newSelectingItem?.displayPrice,
    currency: exchangeCurrency,
    locale,
    countryCode: order?.fromCountryCode,
    showProduct: true,
  });

  const hideExchangeOptions =
    config.isReturnUpsellDisabled && state.selectingItem?.type === "return";
  const exchangeVariants = useMemo(
    () =>
      exchangeResult && !hideExchangeOptions
        ? buildVariants(exchangeResult).map(({ sku, ...v }) => ({
            ...v,
            _sku: sku,
            price: {
              value: v.priceAmount * order.presentmentCurrencySubunitToUnit,
              currency: exchangeCurrency,
            },
            imageUrl: v.productImageUrl,
            variantInfo: mapVariantInfo(v.variantInfo, exchangeResult.options),
          }))
        : [],
    [exchangeResult, hideExchangeOptions],
  );
  const exchangeOptions = useMemo(() => {
    if (!exchangeResult?.options || hideExchangeOptions) return [];

    // setup sparsem availability check
    const sparsem = new Sparsem();
    const sparsemVariants = exchangeVariants
      .filter(v => v.available)
      .map(v => {
        let values = compose(
          infos => infos.map(info => info.value),
          // map(get("value")),
          sortBy("optionPosition"),
        )(Object.values(v.variantInfo || {}));
        return values;
      });
    const sparsemOptions = sortBy("position", exchangeResult.options).map(
      opt => opt.values,
    );
    sparsem.setArr(sparsemVariants, sparsemOptions);

    return exchangeResult.options.map(opt => {
      // When there are no available variants of a product (completely missing
      // in the array), we hide the option from the exchange swatches. It is
      // meaningless to show it on UI, because we don't know the product image,
      // there are no `availabilityStatus` we can show and no available variants
      // as well. We only filter this for "Product" option, because consumer may
      // still want to know other out of stock options (e.g. all sizes including
      // those were out of stock)
      const filterUnavailableOption = v =>
        exchangeResult.variantInfo.some(
          info => info[`option${opt.position}`] === v,
        );
      let values = opt.values
        .filter(opt.name === "Product" ? filterUnavailableOption : stubTrue)
        .map((v, i) => ({
          name: v,
          value: v,
          position: i,
        }));
      if (config.sortExchangeVariantsByAvailability) {
        // query the availability of different values under the current option and exchange selections
        const sparsemQuery = exchangeResult.options.map(o =>
          o.name === opt.name
            ? null
            : exchangeVariantInfo?.[o.name]?.value ?? null,
        );
        const sparsemResult = sparsem.search(sparsemQuery);
        const availability = values.reduce((acc, v) => {
          acc[v.value] =
            sparsemResult[v.value] === UNAVAILABLE_IN_THIS_SET ||
            sparsemResult[v.value] == UNAVAILABLE_IN_ANY_SET;
          return acc;
        }, {});
        values = compose(
          vs => vs.map((v, i) => set("position", i, v)),
          sortBy(v => availability[v.value]),
        )(values);
      }
      return {
        ...opt,
        id: opt.name,
        displayType: /^product$/i.test(opt.name) ? "image" : undefined,
        values,
      };
    });
  }, [
    hideExchangeOptions,
    config.sortExchangeVariantsByAvailability,
    exchangeResult,
    exchangeVariants,
    exchangeVariantInfo,
  ]);

  const orderIneligibleReason =
    orderEligible || isTrack
      ? undefined
      : order.ineligibleDisplayText ??
        commonIneligibleReasons.join("<br/>") ??
        config.translations.choose_items_none_eligible;

  const submittedReturns = useMemo(
    () =>
      returnHistory.map(history => {
        const convertLineItem = item =>
          compose(
            set("itemId", `gid://shopify/LineItem/${item.itemId}`),
            merge(
              newLineItems.find(
                localItem =>
                  localItem.itemId === `gid://shopify/LineItem/${item.itemId}`,
              ) ?? {},
            ),
            merge({
              reason: {
                id: item.childReturnReasonCode ?? item.returnReasonCode,
                name: item.returnReason,
              },
            }),
            renameKeys({
              itemName: "name",
            }),
          )(item);

        // Since we will split exchange line item to multiple return line items,
        // we need to group it back.
        const groupSplittedLineItem = items => {
          const ret = compose(
            map(itemsWithSameId => ({
              ...itemsWithSameId[0],
              quantity: sumBy("quantity", itemsWithSameId),
            })),
            groupBy("itemId"),
          )(items);
          return ret;
        };

        const returnLabels = [];
        const returnShipmentActions = [];

        if (history.returnLabel) {
          returnLabels.push({
            dataUrl: null,
            labelType: "PACKING_SLIP_AND_SHIPPING_LABEL",
            labelUrl: history.returnLabel,
            mimeType: "application/pdf",
          });
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_reprint_labels"),
            href: history.returnLabel,
          });
        }

        if (history.qrCodeUrl) {
          returnLabels.push({
            dataUrl: null,
            labelType: "QR_CODE",
            labelUrl: history.qrCodeUrl,
            mimeType: "image/png",
          });
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_reprint_qr_code"),
            href: history.qrCodeUrl,
          });
        }

        const isMultiLabel = history.returnShipments.length > 1;
        history.returnShipments?.forEach(ship => {
          if (ship.returnTrackingLink) {
            returnShipmentActions.push({
              title: isMultiLabel
                ? interpolate(
                    getTranslation(
                      "choose_items_rma_action_track_return_multi_label",
                    ),
                    {
                      letter: ship.letter,
                      tracking_number: ship.returnTrackingNumber,
                    },
                  )
                : getTranslation("choose_items_rma_action_track_return"),
              href: ship.returnTrackingLink,
            });
          }
        });

        if (
          history.refundMethod === "reshop" &&
          [
            "initiated",
            "on_its_way_to_retailer",
            "delivered_to_retailer",
            "received_by_retailer",
          ].includes(history.currentStatus)
        ) {
          returnShipmentActions.push({
            title: getTranslation(
              "choose_items_rma_action_change_refund_method_original_payment",
            ),
            onClick: () => {
              setReturnAction({
                returnId: history.id,
                action: "changeRefundMethod",
              });
              changeRefundMethod({
                id: history.id,
                refundMethod: "original_payment",
                note: "",
              })
                .then(result => {
                  loginSession.logout();

                  if (config.isNthLoginUi) {
                    toggleContainerClass("nvo_container--new-login");
                    toggleContainerClass("nvo_container--logged-in");
                  }
                })
                .catch(error => {
                  console.error("error", error);
                });
            },
            status:
              returnAction?.returnId === history.id &&
              returnAction?.action === "changeRefundMethod"
                ? returnActionStatus
                : undefined,
          });
        }

        if (history.cancellable) {
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_cancel_return"),
            onClick: () => {
              setReturnAction({
                returnId: history.id,
                action: "cancel",
              });
              cancelReturn({
                orderNumber: order.orderNumber,
                email: displayEmail,
                returnId: history.id,
              })
                .then(result => {
                  loginSession.logout();

                  if (config.isNthLoginUi) {
                    toggleContainerClass("nvo_container--new-login");
                    toggleContainerClass("nvo_container--logged-in");
                  }
                })
                .catch(error => {
                  console.error("error", error);
                });
            },
            status:
              returnAction?.returnId === history.id &&
              returnAction?.action === "cancel"
                ? returnActionStatus
                : undefined,
          });
        }

        const ret = compose(
          merge({
            displayStatus: getTranslation(
              `return_status_simplified_to_consumer_${simplifyReturnStatus(
                history.currentStatus,
              )}`,
            ),
            canBeCancelled: history.cancellable,
            returnShipments: [
              {
                returnLabels,
                items: groupSplittedLineItem(
                  history.returnItems.map(convertLineItem),
                ),
                returnShipmentActions,
                returnTracking: history.carrierTrackingNumber
                  ? {
                      carrierMoniker: history.returnMethod,
                      carrierName: history.returnMethod,
                      narvarTrackingUrl: `?rid=${history.id}&tracking_number=${history.carrierTrackingNumber}`,
                      trackingNumber: history.carrierTrackingNumber,
                    }
                  : undefined,
              },
            ],
          }),
          renameKeys({
            currentStatus: "status",
            returnShipments: "returnShipmentsRaw",
          }),
        )(history);

        return ret;
      }),
    [returnHistory, newLineItems, returnAction, returnActionStatus],
  );

  const eventDataToNewSelectedItem = value => {
    let { item, selectedReason, ...others } = value;
    return {
      ...item,
      ...others,
      reason: selectedReason,
    };
  };
  const newSelectedItemToState = item => {
    const parentId = item.reason.parent || item.reason.id;
    const childId = item.reason.parent ? item.reason.id : null;
    const parentReason = find({ id: parentId }, item.availableReasons);
    const childReason = find({ id: childId })(item.availableReasons);
    const isShopNow = item.type === "exchangeForCredit";
    return {
      localId: item.localId,
      id: item.itemId,
      sku: item._sku,
      type: isShopNow ? "return" : item.type,
      isStoreCredit: isShopNow,
      eligible: !!parentReason?.eligible,
      ineligibleReason: parentReason?.ineligibleReason ?? [],
      returnReason: parentReason?.id,
      childReturnReason: childReason
        ? {
            eligible: !!childReason.eligible,
            ineligibleReason: childReason.ineligibleReason ?? [],
            returnReason: childReason.id,
          }
        : null,
      quantity: item.quantity,
      pictures: item.pictures ?? [],
      comment: item.customerComments,
      exchange:
        item.exchangeTo && !isShopNow
          ? {
              ...item.exchangeTo,
              sku: item.exchangeTo._sku,
              variantInfo: Object.values(
                item.exchangeTo.variantInfo ?? {},
              ).map(v => ({ name: v.optionId, value: v.value })),
            }
          : undefined,
    };
  };

  const disclaimerNew = useMemo(() => {
    if (!showItemsLimitDisclaimer && !showCreateGiftReturn) return null;

    return (
      <>
        {showItemsLimitDisclaimer && (
          <div className={classes.newUiDisclaimer}>
            <Typography align="center">
              {config.translations.choose_items_line_items_limit_remarks}
            </Typography>
          </div>
        )}

        {showCreateGiftReturn && (
          <div className={classes.newUiDisclaimer}>
            <Link onClick={handleCreateGiftReturn}>
              {config.translations.choose_items_cs_create_gift_return}
            </Link>
          </div>
        )}
      </>
    );
  }, [showItemsLimitDisclaimer, showCreateGiftReturn]);

  const loading = exchangeStatus.loading || cancelStatus.loading;

  return (
    <Grid
      className={classes.root}
      justifyContent="center"
      spacing={2}
      container>
      {useNewDesign ? (
        <Grid xs={12} item>
          {!newSelectingItem && (
            <ItemSelector
              progressiveStep
              activeStep={isActiveStep}
              title={orderIneligibleReason}
              customer={customer}
              allItems={isTrack ? [] : newLineItems}
              selectedItems={newSelectedItems}
              submittedReturns={submittedReturns}
              splitSelectedItems
              viewportAnchor={viewportAnchor}
              cancelReturnStatus={returnActionStatus}
              showOfflineReturns="ineligible"
              lineItemGroupings={order.orderLineItemGroupings}
              itemGroupingModeEnabled={config.shopifyCollectiveEnabled}
              disclaimer={disclaimerNew}
              onClick={event => {
                setNewSelectingItem(event.value.item);
                actions.setSelectedItems(state.items); // reset step, clean up the UI when user clicking from a later step back to item selection

                const newSelected = find({
                  itemId: event.value.item.itemId,
                  sku: event.value.item.sku,
                  localId: event.value.item.localId,
                })(newSelectedItems);
                if (newSelected) {
                  const selecting = newSelectedItemToState(newSelected);
                  actions.setSelectingItem(selecting);
                }

                typeForm.scrollToActive();
              }}
              onSubmit={event => {
                nthReturnsActions.setOrderData({
                  orderId: order.orderNumber,
                  items: newLineItems,
                });
                nthReturnsActions.setSelectedItems(newSelectedItems);
                handleNextStep();
              }}
            />
          )}
          {isTrack && submittedReturns.length === 0 && (
            <Typography align="center">
              {config.translations.track_app_choose_items_not_found}
            </Typography>
          )}
          {newSelectingItem && (
            <ReturnReasons
              progressiveStep
              activeStep={isActiveStep}
              layout="footerNav"
              loading={loading}
              orderItem={newSelectingItem}
              selectedItem={newSelectedItems.find(
                i =>
                  i.itemId === newSelectingItem.itemId &&
                  i.localId === newSelectingItem.localId,
              )}
              stepsProps={stepsProps}
              exchangeVariants={exchangeVariants}
              exchangeOptions={exchangeOptions}
              onChange={event => {
                const {
                  item,
                  selectedReason,
                  selectedExchangeOptions,
                } = event.value;
                const selected = eventDataToNewSelectedItem(event.value);
                const selecting = newSelectedItemToState(selected);

                setExchangeVariantInfo(selectedExchangeOptions);
                setSelectingReasonId(selectedReason.id);
                actions.setLineItemInitialIntension({
                  lineItemId: item.itemId,
                  localId: item.localId,
                  type: selecting.type, // `event.value.type` may have "exchangeForCredit" which we don't want
                  exchangeEnabled: item.eligibilities.exchange?.isEligible,
                });
                actions.setSelectingItem(selecting);
              }}
              onNext={event => {
                typeForm.scrollToActive();
              }}
              onBack={event => {
                typeForm.scrollToActive();
              }}
              onClose={event => {
                setNewSelectingItem(null);
                setSelectingReasonId(null);
                actions.setSelectingItem(null);
                actions.clearLineItemIntension({
                  lineItemId: newSelectingItem.itemId,
                  localId: newSelectingItem.localId,
                });
                typeForm.scrollToActive();
              }}
              onSubmit={event => {
                const { item, quantity, type } = event.value;

                const isEdit = event.subComponent === "edit";
                // clicked on "No, continue with return"
                if (isEdit && quantity > 0) {
                  setNewSelectingItem(null);
                  actions.setSelectingItem(null);
                  typeForm.scrollToActive();
                  return;
                }

                let updates = newSelectedItems.filter(
                  i => i.itemId !== item.itemId || i.localId !== item.localId,
                );
                if (quantity) {
                  updates = []
                    .concat(updates)
                    .concat(eventDataToNewSelectedItem(event.value));
                }
                setNewSelectedItems(updates);
                setNewSelectingItem(null);

                const selectedItems = updates.map(newSelectedItemToState);

                actions.setSelectedItems(selectedItems);
                actions.setSelectingItem(null);
                if (isEdit && quantity === 0) {
                  actions.clearLineItemIntension({
                    lineItemId: item.itemId,
                    localId: item.localId,
                  });
                } else {
                  actions.confirmLineItemIntension({
                    lineItemId: item.itemId,
                    localId: item.localId,
                    type,
                  });
                }
                typeForm.scrollToActive();
              }}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid xs={12} item>
            {!orderEligible ? (
              order.ineligibleDisplayText ? (
                <Typography
                  align="center"
                  className={classes.title}
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: order.ineligibleDisplayText,
                  }}
                />
              ) : (
                <>
                  {isEmpty(commonIneligibleReasons) && (
                    <Typography
                      align="center"
                      className={classes.title}
                      variant="h2"
                      dangerouslySetInnerHTML={{
                        __html: config.translations.choose_items_none_eligible,
                      }}
                    />
                  )}
                  {commonIneligibleReasons.map(commonIneligibleReason => (
                    <Typography
                      align="center"
                      className={classes.title}
                      variant="h2"
                      key={commonIneligibleReason}
                      dangerouslySetInnerHTML={{
                        __html: commonIneligibleReason,
                      }}
                    />
                  ))}
                </>
              )
            ) : (
              <>
                <Typography
                  align="center"
                  className={classes.title}
                  variant="h1">
                  {config.translations.choose_items_title}
                </Typography>
                {supplementaryText && (
                  <Typography
                    align="center"
                    className={classes.supplementaryText}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: config.translations.choose_items_supplementary,
                    }}
                  />
                )}
              </>
            )}
            <ItemsCarousel ref={itemsCarouselRef} data-styleid="item-carousel">
              {sortedLocalItems.map(item => {
                const key = createCarouselItemKey(item);

                return (
                  <Item
                    key={key}
                    addExtraSpace={addExtraSpace}
                    disabled={
                      !order.eligible ||
                      !item.someReasonsEligible ||
                      !item.returnableQuantity
                    }
                    enableExchange={config.isExchangesEnabled}
                    itemIneligibleReasons={itemIneligibleReasons(item)}
                    item={item}
                    onChangeContinuePending={handleChangeContinuePending}
                    onChangeReason={handleChangeReason}
                    onChangeChildReason={handleChangeChildReason}
                    onChangeQuantity={handleChangeQuantity}
                    onChangeComment={handleChangeComment}
                    onChoose={setStateToLocalItem}
                    onRemove={handleRemoveItem}
                    orderFetchData={orderFetchData}
                    setPicturesForItem={setPicturesForItem}
                    selectedState={selectedByKey[key]}
                  />
                );
              })}
            </ItemsCarousel>
          </Grid>
          {showCreateGiftReturn && (
            <Grid xs={12} item>
              <Typography align="center">
                <Link onClick={handleCreateGiftReturn}>Create Gift Return</Link>
              </Typography>
            </Grid>
          )}
          {showNextBtn && actionNode}
          {hasStoreCredit && (
            <Grid xs={12} item>
              <Typography align="center">
                {config.translations.choose_items_shopnow_remarks}
              </Typography>
            </Grid>
          )}
          {showItemsLimitDisclaimer && (
            <Grid xs={12} item>
              <Typography align="center">
                {config.translations.choose_items_line_items_limit_remarks}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
export default ChooseItems;
