import React from "react";
import PropTypes from "prop-types";
import TextField from "./TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  theme => ({
    counter: {
      color: "#9E9E9E",
    },
  }),
  { name: "N0TextFieldWithCharacterCount" },
);

function TextFieldWithCharacterCount({ value, maxCount, ...props }) {
  const classes = useStyles();

  return (
    <TextField
      value={value}
      endAdornment={
        <div>
          <span className={classes.counter}>
            {value?.length ?? 0}
            {maxCount > 0 && `/${maxCount}`}
          </span>
        </div>
      }
      maxLength={maxCount}
      {...props}
    />
  );
}

TextFieldWithCharacterCount.propTypes = {
  maxCount: PropTypes.number,
  ...TextField.propTypes,
};

TextFieldWithCharacterCount.defaultProps = {
  maxCount: 0,
};

export default TextFieldWithCharacterCount;
