import React, { useMemo } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(
  theme => ({
    adornedEnd: {
      paddingRight: 0,
    },
  }),
  { name: "N0DatePicker" },
);

function DatePicker({
  disabled,
  fullWidth,
  maxDate,
  minDate,
  onChange,
  value,
}) {
  const classes = useStyles();

  const handleChange = (date, value) => onChange(value);

  // value props in DatePicker assumes the input is ParableDate,
  // as MM-DD-YYYY is a non RFC2822/ISO format, it may throw deprecate warning on Firefox v93.0
  // we need to convert back to a Date object
  const format = "MM-DD-YYYY";
  const date = useMemo(() => {
    const d = moment(value, format);
    return d.isValid() ? d.toDate() : null; // keep it blank when input is invalid or null
  }, [value, format]);

  return (
    <KeyboardDatePicker
      autoOk
      disableToolbar
      fullWidth={fullWidth}
      disabled={disabled}
      InputProps={{ classes }}
      format={format}
      inputVariant="outlined"
      margin="none"
      maxDate={maxDate}
      minDate={minDate}
      onChange={handleChange}
      placeholder="Pick a date…"
      value={date}
      variant="inline"
    />
  );
}
export default DatePicker;
