import React, { useMemo, forwardRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import map from "lodash/map";
import startCase from "lodash/startCase";
import clsx from "clsx";

import { config } from "../../../config";
import { makeRootStyles } from "../../../theme/styles";
import ExchangeItemThumbnail from "../ExchangeItemWizard/ExchangeItemThumbnail";
import ItemThumbnail from "./ItemThumbnail";
import { ACTION_HEIGHT_SPACING, getPadding } from "../utils";
import {
  useReturnStepsActions,
  useReturnStepsState,
} from "../../../contexts/returnSteps";
import ExchangeItemWizard from "../ExchangeItemWizard/ExchangeItemWizard";
import PictureUpload from "./PictureUpload";

export const useStyles = makeRootStyles(
  theme => ({
    thumbContainer: {
      position: "relative",
    },
    disabledOpacity: {
      opacity: 0.5,
    },
    thumb: {},
    details: {
      display: "flex",
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      padding: getPadding(theme),
      boxSizing: "border-box",
      position: "relative",
      maxWidth: "100%",
    },
    detailsSelected: {
      cursor: "auto",
    },
    sm: {
      flexDirection: "column",
      "& $thumbContainer": {
        marginBottom: getPadding(theme),
      },
      "& $thumb": {
        width: "100%",
        height: theme.spacing(36),
        [theme.breakpoints.up("sm")]: {
          height: theme.spacing(52),
        },
        [theme.breakpoints.up("md")]: {
          height: theme.spacing(26),
        },
      },
      "& $typePickerContainer": {
        background:
          "linear-gradient(to top, rgba(255, 255, 255, 1) 66%, rgba(255, 255, 255, 0.75) 100%)",
      },
    },
    lg: {
      flexDirection: "row",
      paddingRight: 38, // hotfix, make select menu in single item view won't overlap the "close" icon
      "& $thumbContainer": {
        marginRight: getPadding(theme),
      },
      "& $thumb": {
        width: theme.spacing(30),
        height: theme.spacing(30),
        [theme.breakpoints.up("md")]: {
          width: theme.spacing(35),
          height: theme.spacing(35),
        },
      },
    },
    descriptionContainer: {
      flex: 1,
      flexDirection: "column",
      display: "flex",
      position: "relative",
    },
    description: {
      flex: 1,
      maxWidth: "100%",
    },
    exchangeTitle: {},
    name: {
      marginBottom: theme.spacing(2),
    },
    price: {},
    variant: {},
    ineligibleReason: {
      marginTop: theme.spacing(1),
    },
    editCommentButton: {
      height: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    editPictureButton: {
      height: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    fullSizeContainer: {
      position: "absolute",
      zIndex: 1,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    commentContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    pictureUploadContainer: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(2),
    },
    typePickerContainer: {},
    typePickerContainerRow: {
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        "& $typePickerButton": {
          marginBottom: theme.spacing(0),
          marginRight: theme.spacing(2),
        },
      },
    },
    typePickerButton: {
      marginBottom: theme.spacing(1),
    },
    actions: {
      display: "flex",
      flexDirection: "column",
      minHeight: theme.spacing(ACTION_HEIGHT_SPACING),
      marginTop: theme.spacing(2),
      marginBottom: -theme.spacing(0.5),
      gap: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
    ineligibleReasonItem: {
      fontWeight: "bold",
    },
  }),
  { name: "N0ItemDetails" },
);

const ItemDetails = forwardRef(
  (
    {
      className,
      disabled,
      enableExchange,
      item,
      onToggleExchangeVariants,
      onChoose,
      onShowComment,
      openComment,
      openPictures,
      setOpenPictures,
      setPicturesForItem,
      photoUploadEnabled,
      photoUploadRequired,
      renderComment,
      renderSelectors,
      setUserHasContinuedPastPictures,
      size,
      state,
      style,
      orderFetchData,
    },
    ref,
  ) => {
    const classes = useStyles();
    const appState = useReturnStepsState() || {};
    const actions = useReturnStepsActions();

    const [showExchangeVariants, setShowExchangeVariants] = useState(false);
    const [exchangeVariants, setExchangeVariants] = useState(state.exchange); // intermediate item while selection exchange variants
    const localItem = useMemo(
      () =>
        (showExchangeVariants && exchangeVariants) || state.exchange || item,
      [item, state.exchange, showExchangeVariants, exchangeVariants],
    );
    useEffect(() => {
      setShowExchangeVariants(
        showExchangeVariants => state.selected && showExchangeVariants,
      );
    }, [state.selected]);
    useEffect(() => {
      setExchangeVariants(state.exchange);
    }, [state.exchange]);

    useEffect(() => {
      onToggleExchangeVariants(showExchangeVariants);
    }, [showExchangeVariants]);

    const isReturn = state.type === "return";
    const isExchange = state.type === "exchange";
    const isExchangeStoreCredit = state.isStoreCredit;
    const isUnknown = !isReturn && !isExchange;

    const handleChooseReturn = e => {
      if (e) e.stopPropagation();

      if (disabled) return;

      onChoose(
        item,
        item.returnableQuantity === 1
          ? { quantity: 1, type: "return" }
          : { type: "return" },
      );
    };

    const handleChooseExchange = exchange => {
      if (disabled) return;

      const selection = { type: "exchange", exchange };
      if (item.returnableQuantity === 1) selection.quantity = 1;
      onChoose(item, selection);
      setShowExchangeVariants(false);
    };

    const handleChoose = () => {
      if (disabled) return;

      if (openComment || openPictures) return;

      if (enableExchange) {
        if (!isUnknown) {
          if (isExchange) {
            setShowExchangeVariants(true);
          }
          return;
        }

        return onChoose(item, { type: null });
      }

      handleChooseReturn();
    };

    const returnButtonText = appState.guestReturn
      ? config.translations.choose_items_return_item_button_label_gift
      : config.translations.choose_items_return_item_button_label;
    const price = localItem.priceFormatted || localItem.displayPrice;

    const itemSelectors = renderSelectors && size === "lg" && renderSelectors();

    return (
      <div
        ref={ref}
        className={clsx(
          classes.details,
          classes[size],
          {
            [classes.detailsSelected]: state.selected,
          },
          className,
        )}
        onClick={handleChoose}
        style={style}>
        <div
          className={clsx(
            classes.thumbContainer,
            {
              [classes.disabledOpacity]: disabled,
            },
            className,
          )}>
          {isExchange || isExchangeStoreCredit ? (
            <ExchangeItemThumbnail
              className={classes.thumb}
              imageAltTxt={localItem.productImageAltTxt}
              imageUrl={localItem.productImageUrl}
              prevImageAltTxt={item.productImageAltTxt}
              prevImageUrl={item.productImageUrl}
              storeCredit={isExchangeStoreCredit}
            />
          ) : (
            <ItemThumbnail
              fade
              className={classes.thumb}
              imageAltTxt={localItem.productImageAltTxt}
              imageUrl={localItem.productImageUrl}
            />
          )}
        </div>

        <div className={classes.descriptionContainer}>
          <div className={classes.description}>
            {showExchangeVariants && (
              <Typography
                className={classes.exchangeTitle}
                variant="h3"
                gutterBottom>
                {config.translations.choose_items_exchange_wizard_title}
              </Typography>
            )}
            <div
              className={clsx({
                [classes.disabledOpacity]: disabled,
              })}>
              <Typography
                className={classes.name}
                variant="h4"
                dangerouslySetInnerHTML={{
                  __html: localItem.productTitle || localItem.name,
                }}
              />
              {!localItem.hideDisplayPrice && (
                <Typography variant="body1" className={classes.price}>
                  {price}
                </Typography>
              )}
              {map(localItem.variantInfo, (variantInfo, i) => (
                <Typography variant="body1" className={classes.variant} key={i}>
                  {startCase(variantInfo.name)}: {variantInfo.value}
                </Typography>
              ))}
            </div>
            {!state.eligible && (
              <List className={classes.ineligibleReason} variant="body1">
                {state.ineligibleReason.map(r => (
                  <ListItem key={r} disableGutters>
                    <Typography
                      className={classes.ineligibleReasonItem}
                      dangerouslySetInnerHTML={{ __html: r }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {renderComment && state.selected && state.comment != null && (
              <div
                className={clsx(classes.editCommentButton, {
                  [classes.disabledOpacity]: disabled,
                })}>
                <Link
                  onClick={event => {
                    event.stopPropagation();
                    onShowComment(true);
                  }}>
                  {state.comment
                    ? config.translations.choose_items_edit_comment
                    : config.translations.choose_items_add_comment}
                </Link>
              </div>
            )}
            {photoUploadEnabled && state.selected && (
              <div
                className={clsx(classes.editPictureButton, {
                  [classes.disabledOpacity]: disabled,
                })}>
                <Link
                  onClick={event => {
                    event.stopPropagation();
                    setOpenPictures(true);
                  }}>
                  {state.pictures.length > 0
                    ? `${config.translations.choose_items_upload_pictures_edit_pictures} (${state.pictures.length})`
                    : config.translations
                        .choose_items_upload_pictures_add_pictures}
                </Link>
              </div>
            )}
            {state.selected && isUnknown && !showExchangeVariants && (
              <div
                className={clsx(
                  classes.fullSizeContainer,
                  classes.typePickerContainer,
                  { [classes.typePickerContainerRow]: size === "lg" },
                )}>
                {item.allowReturn && !showExchangeVariants && (
                  <Button
                    className={clsx("return-button", classes.typePickerButton)}
                    color="primary"
                    onClick={handleChooseReturn}
                    size="medium"
                    variant="contained"
                    fullWidth>
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: returnButtonText,
                      }}
                    />
                  </Button>
                )}
                {item.allowExchange && !showExchangeVariants && (
                  <Button
                    className="exchange-button"
                    color="primary"
                    onClick={() => {
                      setShowExchangeVariants(true);
                    }}
                    size="medium"
                    variant="contained"
                    fullWidth>
                    <strong
                      dangerouslySetInnerHTML={{
                        __html:
                          config.translations
                            .choose_items_exchange_item_button_label,
                      }}
                    />
                  </Button>
                )}
              </div>
            )}
            {showExchangeVariants && (
              <ExchangeItemWizard
                exchangeItem={state.exchange}
                lineItem={item}
                onChange={setExchangeVariants}
                onConfirm={handleChooseExchange}
                orderFetchData={orderFetchData}
              />
            )}
          </div>
          {itemSelectors && (
            <div
              className={clsx(classes.actions, classes.actionsBig)}
              onClick={e => e.stopPropagation()}>
              {itemSelectors}
            </div>
          )}
        </div>
        {renderComment && openComment && (
          <div
            className={clsx(
              classes.fullSizeContainer,
              classes.commentContainer,
            )}>
            {renderComment({ state })}
          </div>
        )}
        {photoUploadEnabled && openPictures && (
          <div
            className={clsx(
              classes.fullSizeContainer,
              classes.pictureUploadContainer,
            )}>
            <PictureUpload
              pictures={state.pictures}
              setPicturesForItem={pictures =>
                setPicturesForItem(state, pictures)
              }
              onContinue={() => {
                setOpenPictures(false);
                setUserHasContinuedPastPictures(true);
              }}
              required={photoUploadRequired}
            />
          </div>
        )}
      </div>
    );
  },
);
ItemDetails.displayName = "ItemDetails";
export default ItemDetails;
