import React, { useRef, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { useEffectOnce } from "../../../shared/modules/hooks";
import { getBranding } from "../../../shared/modules/config";
import { useFrameDimensions } from "../../contexts/frameDimensions";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export const useStyles = makeRootStyles(
  theme => ({
    rootMargin: {
      marginTop: -getBranding("step_delimiter_position"),
    },
    rootPadding: {
      paddingTop: getBranding("step_delimiter_position"),
    },
    cover: {
      minHeight: theme.mixins.verticalHeight(100),
    },
  }),
  { name: "N0TypeFormSection" },
);

function TypeFormSection({ children, isNext, activeIndex, onRenderNext }) {
  const sectionRef = useRef(null);
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { dimensions, initiated } = useFrameDimensions();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (isNext) onRenderNext(sectionRef.current, activeIndex, firstRender);
    setFirstRender(false);
  }, [isNext, firstRender]);

  // set min-height for steps after order lookup and item selection
  const style = useMemo(() => {
    const ret = {};
    if (isNext && activeIndex > 1 && initiated) {
      let minHeight = dimensions.windowHeight;
      minHeight -= isMobile
        ? 0
        : dimensions.footerHeight + getBranding("root_margin_bottom");
      minHeight += getBranding("fixed_app_bar")
        ? getBranding("step_delimiter_position")
        : 0;
      ret.minHeight = minHeight;
    }
    return ret;
  }, [isNext, dimensions, isMobile]);

  return (
    <div
      ref={sectionRef}
      style={style}
      className={clsx(classes.rootMargin, classes.rootPadding, {
        [classes.cover]: isNext,
      })}>
      {children}
    </div>
  );
}
TypeFormSection.propTypes = {
  children: PropTypes.node,
  isNext: PropTypes.bool,
  activeIndex: PropTypes.number,
  onRenderNext: PropTypes.func,
};
export default TypeFormSection;
