import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MuiTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import isAbsoluteUrl from "is-absolute-url";

import FieldLabel from "./FieldLabel";
import isNumber from "lodash/isNumber";
import { useElementId } from "../../modules/localId";
import clsx from "clsx";

const useStyles = makeStyles(
  () => ({
    root: {},
    required: {
      "& > *": { display: "inline" },
    },
    alignCenter: {
      "& > input": {
        textAlign: "center",
      },
    },
    alignRight: {
      "& > input": {
        textAlign: "right",
      },
    },
  }),
  { name: "N0TextField" },
);

const validate = (type, { inputProps, required, value }) => {
  if (required && !isNumber(value)) return { result: false };

  if (type === "number") {
    value = parseInt(value, 10);

    if (inputProps) {
      if (value < inputProps.min) {
        return false;
      }

      if (value > inputProps.max) {
        return false;
      }
    }
  }

  if (type === "url") {
    if (value && !isAbsoluteUrl(value)) return { result: false };
  }

  return { result: true };
};

const TextField = forwardRef(
  (
    {
      fullWidth,
      id,
      inputProps: _inputProps,
      labelProps,
      label: _label,
      onBlur,
      onChange,
      required: _required,
      shrink,
      textAlign,
      type: _type,
      value,
      ...other
    },
    ref,
  ) => {
    const defaultValueRef = useRef(value);
    const classes = useStyles();
    const isNewPassword = _type === "new-password";
    const type = isNewPassword ? "password" : _type; // new-password field is a password input
    const required = _required && !isNewPassword; // new-password field is optional
    const label =
      isNewPassword && typeof _label === "string" ? `New ${_label}` : _label;
    const inputProps =
      type === "password"
        ? {
            // default pattern for password field, ensures that there is at
            // least one non-whitespace character
            pattern: ".*\\S.*",
            ..._inputProps,
          }
        : _inputProps;
    const result = validate(type, {
      inputProps,
      required,
      value,
    });
    // auto-generate the DOM element ID, when label exists but ID is not
    // provided. This improves accessability of UI (linkage of input and label),
    // also make test suit easier to interact the UI.
    const uid = useElementId("N0TextField-");
    const elId = id || inputProps?.id || (label ? uid : undefined);
    const inputClassName = clsx({
      [classes.alignCenter]: textAlign === "center",
      [classes.alignRight]: textAlign === "right",
    });

    const handleBlur = event => {
      if (onBlur) onBlur(event);

      if (result || !onChange) return;
      return onChange(event);
    };

    useImperativeHandle(
      ref,
      () => ({
        validate: () => result,
      }),
      [result],
    );

    if (label && shrink && type !== "select") {
      return (
        <MuiTextField
          error={!result}
          fullWidth={fullWidth}
          id={elId}
          className={inputClassName}
          inputProps={inputProps}
          InputLabelProps={labelProps}
          label={label}
          onBlur={handleBlur}
          onChange={onChange}
          required={required}
          type={type}
          value={value}
          variant="outlined"
          {...other}
        />
      );
    }

    return (
      <FormControl
        className={classes.root}
        error={!result}
        fullWidth={fullWidth}
        required={required}
        variant="outlined">
        {label && (
          <FieldLabel
            htmlFor={elId}
            {...labelProps}
            className={required ? classes.required : null}>
            {label}
            {required && <span>*</span>}
          </FieldLabel>
        )}
        {type === "select" ? (
          <Select
            id={elId}
            className={inputClassName}
            displayEmpty
            inputProps={inputProps}
            onChange={onChange}
            value={value}
            {...other}
          />
        ) : (
          <OutlinedInput
            id={elId}
            className={inputClassName}
            inputProps={inputProps}
            onBlur={handleBlur}
            onChange={onChange}
            type={type}
            value={value}
            {...other}
          />
        )}
      </FormControl>
    );
  },
);
TextField.displayName = "TextField";
TextField.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  shrink: PropTypes.bool,
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
TextField.defaultProps = {
  textAlign: "left",
};
export default TextField;
