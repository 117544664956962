import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";
import { compose, map, toPairs, fromPairs, isNumber } from "lodash/fp";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, Typography } from "@material-ui/core";
import { NumericFormat } from "react-number-format";

import TextField from "./TextField";
import { getCurrencyFormat } from "../../modules/currency";

const useStyles = makeStyles(
  theme => ({
    adornment: {
      gap: theme.spacing(0.5),
    },
  }),
  { name: "N0NumberField" },
);

const NumberField = forwardRef(
  (
    { type, unit, unitPosition, min, max, showLimit, inputProps, ...props },
    ref,
  ) => {
    const classes = useStyles();
    const currencyFormat = useMemo(
      () => (type === "currency" ? getCurrencyFormat(unit) : null),
      [type, unit],
    );
    if (currencyFormat) {
      unit = currencyFormat.symbol;
      unitPosition = "start";
    }
    const adornmentProps = useMemo(() => {
      let ret = {
        startAdornment: [],
        endAdornment: [],
      };

      if (max > 0 && showLimit) {
        ret.endAdornment.push(
          <Typography color="textSecondary">{`/ ${max}`}</Typography>,
        );
      }
      if (unit) {
        (unitPosition === "start" ? ret.startAdornment : ret.endAdornment).push(
          unit,
        );
      }

      ret = compose(
        fromPairs,
        map(([key, nodes]) => {
          const adornmentNode = (
            <InputAdornment
              className={classes.adornment}
              position={key.replace("Adornment", "")}>
              {nodes.map((node, i) => (
                <React.Fragment key={i}>{node}</React.Fragment>
              ))}
            </InputAdornment>
          );
          return [key, adornmentNode];
        }),
        toPairs,
      )(ret);

      return ret;
    }, [unit, unitPosition, max, showLimit]);

    const iProps = useMemo(
      () => ({
        min,
        max,
        ...inputProps,
      }),
      [min, max, inputProps],
    );

    if (type === "currency") {
      return (
        <NumericFormat
          getInputRef={ref}
          decimalScale={currencyFormat.exponent}
          decimalSeparator={currencyFormat.decimalSeparator}
          thousandSeparator={currencyFormat.thousandSeparator}
          fixedDecimalScale
          allowNegative={!isNumber(min) || min < 0}
          onValueChange={values => {
            props.onChange?.({
              target: {
                name: props.name,
                ...values,
              },
            });
          }}
          customInput={TextField}
          inputProps={iProps}
          {...adornmentProps}
          {...props}
        />
      );
    }

    return (
      <TextField
        ref={ref}
        type="number"
        inputProps={iProps}
        {...adornmentProps}
        {...props}
      />
    );
  },
);
NumberField.displayName = "NumberField";
NumberField.propTypes = {
  ...TextField.propTypes,
  type: PropTypes.oneOf(["number", "currency"]),
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  unitPosition: PropTypes.oneOf(["start", "end"]),
  min: PropTypes.number,
  max: PropTypes.number,
  showLimit: PropTypes.bool,
};

NumberField.defaultProps = {
  type: "number",
  unitPosition: "end",
};

export default NumberField;
