import { config } from "../config";
import { camelCase, compose, fromPairs, map, set } from "lodash/fp";
import * as sharedConfig from "../../shared/modules/config";
import { mergeIgnoreNil } from "../../shared/modules/object";

/**
 * @typedef {Object} Address
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} address1
 * @property {string} address2
 * @property {string} city
 * @property {string} zip
 * @property {string} province
 * @property {string} provinceCode
 * @property {string} country
 * @property {string} countryCode
 * @property {string} countryCodeV2
 */
export const ADDRESS_DEFAULT = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  province: "",
  provinceCode: "",
  country: "",
  countryCode: "",
  countryCodeV2: "",
};

export const getCountryNameByCode = countryCode => {
  return (
    config.countryNamesByCode[countryCode] ||
    sharedConfig.countryCodes.find(
      country => country.country_code === countryCode,
    )?.country ||
    ""
  );
};

/**
 * Merge address object, the later arguments will overwrite the existing attributes
 * @param {Address} address
 * @param {...Address} updates
 * @returns {Address}
 */
export const mergeAddress = (address, ...updates) => {
  const addr = mergeIgnoreNil(ADDRESS_DEFAULT, address, ...updates);
  const countryCode = addr?.countryCode || addr?.countryCodeV2 || "";
  addr.countryCode = addr.countryCodeV2 = countryCode;
  addr.country = getCountryNameByCode(countryCode);
  return addr;
};

/**
 * Patch zip code for address object. For example Hong Kong address may not have
 * a zip code.
 * @param {Address} address
 * @returns {Address}
 */
export const patchZipCode = address => {
  let zip = address?.zip;
  if (address?.countryCode === "HK" || address?.countryCodeV2 === "HK") {
    // Hong Kong Post suggested to fill "000", "0000", "000000" or "HKG"
    // https://www.hongkongpost.hk/en/about_us/tips/postcode/index.html
    zip = "000000";
  }

  if (zip !== address?.zip) {
    return { ...address, zip };
  }
  return address;
};

/**
 * Pick and rename address object to a standard address object
 * @param {any} address
 * @param {String} [attrPrefix]
 * @param {String} [attrSuffix]
 * @returns {Address}
 * @example
 * const addr1 = normalizeAddressKeys({billingZip: '', billingCountry: 'HK'}, 'billing');
 * // addr1 = {zip: '', country: 'HK', firstName: '', ... }
 *
 * const addr2 = normalizeAddressKeys({zip2: '', country2: 'HK'}, '', '2');
 * // addr2 = {zip: '', country: 'HK', firstName: '', ... }
 */
export const normalizeAddressKeys = (
  address,
  attrPrefix = "",
  attrSuffix = "",
) =>
  compose(
    fromPairs,
    map(key => {
      const oldKey = camelCase([attrPrefix, key, attrSuffix].join("_"));
      const value = address?.[oldKey] ?? null;
      return [key, value];
    }),
  )(Object.keys(ADDRESS_DEFAULT));
