import {
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useSnackbar } from "notistack";
import { debounce, get, noop } from "lodash/fp";
import { useElementId } from "./localId";
import { memoObjectByKeyValues } from "./object";

export function useEffectOnce(fn) {
  return useEffect(fn, []);
}

export function useElementSize(element) {
  const [size, setSize] = useState(() => {
    if (element) {
      return {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
    }

    return {
      width: "auto",
      height: "auto",
    };
  });

  useLayoutEffect(() => {
    function updateSize() {
      if (element) {
        setSize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }
    }

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [element]);

  return size;
}

export const useModal = (initialValue = false) => {
  const id = useElementId("modal-");
  const [open, setOpen] = useState(initialValue);
  const toggle = () => setOpen(state => !state);
  const register = useCallback(
    ({ handleCancel, handleConfirm }) => ({
      open,
      onCancel: async () => {
        await handleCancel?.();
        setOpen(false);
      },
      onConfirm: async () => {
        await handleConfirm?.();
        setOpen(false);
      },
    }),
    [id, open],
  );
  return { id, open, setOpen, toggle, register };
};

export function useRequestStatus(requestState, customErrorsPath, showRawData) {
  const data = showRawData ? requestState.data : !!requestState.data;
  const customErrors = useMemo(
    () =>
      customErrorsPath ? get(customErrorsPath, requestState.data) ?? [] : [],
    [customErrorsPath, requestState.data],
  );
  return useMemo(
    () => ({
      data,
      called: requestState.called,
      loading: requestState.loading,
      error: requestState.error ?? customErrors?.[0],
      errors: [].concat(requestState.error ?? []).concat(customErrors),
    }),
    [
      data,
      requestState.called,
      requestState.error,
      requestState.loading,
      customErrors,
    ],
  );
}

export function useSnackbarEffect(status, snackbars) {
  const { called, data, error, loading } = status || {};
  const { enqueueSnackbar } = useSnackbar();
  const { error: errorMsg, success: successMsg } = snackbars;

  useEffect(() => {
    if (loading || !called) return;

    if (error && errorMsg) {
      enqueueSnackbar(errorMsg, {
        autoHideDuration: 5000,
        variant: "error",
      });
    }

    if (!error && successMsg) {
      enqueueSnackbar(successMsg, {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, called, error, loading, data, errorMsg, successMsg]);
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

/**
 * @callback featureFlaggedHookWrapper
 * @param {Function} hook a function, a react hook
 * @returns {Function} the wrapped hook
 */
/**
 * Avoid calling the hook when the feature is disabled in the shop, it always
 * return the default value if `isEnabled` is false. It is good enhance network
 * performance and avoid graphql error during new feature rollout.
 *
 * @param {boolean} isEnabled
 * @param {*} defaultValue
 * @returns {featureFlaggedHookWrapper}
 */
export const featureFlaggedHook = (isEnabled, defaultValue = {}) => hook => (
  ...args
) => {
  if (!isEnabled) {
    return useMemo(() => defaultValue, []);
  }
  return hook(...args);
};

export const useDebounceCallback = (func = noop, wait = 500, deps = null) =>
  useCallback(debounce(wait, func), deps || [func]);

/**
 * @function APIFunction
 * @returns {Promise<Result>}
 */
/**
 * @typedef {Object} APIStatus
 * @property {boolean} loading
 * @property {boolean} called
 * @property {Result} [data]
 * @property {Error} [error]
 * @property {Function} reset
 */
/**
 * Wrapped an async API function to an apollo like function, thus it is similar
 * to `useLazyQuery` and `useMutation`, we got an API function and the status of
 * the API call (e.g. loading, called, data, error, etc.)
 * @param {APIFunction} [apiFunc]
 * @returns {[Function, APIStatus]}
 */
export const useApolloCompatibleAPI = (apiFunc = noop) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const reset = useCallback(() => {
    setData();
    setError();
    setLoading(false);
    setCalled(false);
  }, []);

  const status = memoObjectByKeyValues({
    data,
    error,
    loading,
    called,
    reset,
  });

  const wrappedFunc = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        const result = await apiFunc(...args);
        setCalled(true);
        setData(result);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [apiFunc],
  );

  return useMemo(() => [wrappedFunc, status], [wrappedFunc, status]);
};
