import { get, getOr, set, sumBy, max, sortBy, flatten, range } from "lodash/fp";

export const buildReturnItem = (item, lineItem, returnReasonsById = {}) => {
  const { comment, quantity, pictures } = item;
  const childReturnReasonId = get("returnReason", item.childReturnReason);
  const childReturnReason = childReturnReasonId
    ? returnReasonsById[childReturnReasonId].reasonTitle
    : undefined;

  return {
    comment,
    quantity,
    pictures,
    returnReason: returnReasonsById[item.returnReason].reasonTitle,
    returnReasonId: item.returnReason,
    childReturnReason,
    childReturnReasonId,
    lineItemId: lineItem.id,
  };
};

export const buildExchangeItem = (item, lineItem, returnReasonsById) => {
  const { exchange } = item;
  const returnItem = buildReturnItem(item, lineItem, returnReasonsById);

  return {
    returnItem,
    newProductId: exchange.productId,
    newProductVariantId: exchange.variantId,
    newProductVariantInfo: exchange.variantInfo,
    newProductSku: exchange.sku,
    newProductName: exchange.name,
    newProductImage: exchange.productImageUrl,
    newProductPriceAmount: exchange.priceAmount,
    newProductPriceCurrency: exchange.priceCurrency,
  };
};

export const buildItemsByType = (items, lineItemsById, returnReasonsById) => {
  const returnItems = items
    .filter(item => item.type === "return")
    .map(item =>
      buildReturnItem(item, lineItemsById[item.id], returnReasonsById),
    );

  let exchangeItems = items
    .filter(item => item.type === "exchange")
    .map(item =>
      // Due to backend limitation, we convert a exchange with quantity > 1
      // into multiple exchanges which quantity is 1
      range(0, item.quantity).map(() =>
        buildExchangeItem(
          set("quantity", 1, item),
          lineItemsById[item.id],
          returnReasonsById,
        ),
      ),
    );
  exchangeItems = flatten(exchangeItems);

  return { returnItems, exchangeItems };
};

// build carousel items (swiper items), if there are exchange items selected and
// that line item still have returnable quantity, create one more carousel item
// for user to select. (item.id, item.localId) are used to distinguish different
// carousel items
export const buildCarouselItems = (orderItems, selected) =>
  orderItems.reduce((remapped, item) => {
    let matching = selected.filter(i => i.id === item.id);
    matching = sortBy("localId", matching);
    const returnItem = matching.find(i => i.type === "return");
    let exchangeItems = matching.filter(i => i.type === "exchange");

    if (exchangeItems.length) {
      // the sum of quantity user selected for exchange and return
      const returningQuantity =
        sumBy(getOr(0, "quantity"), exchangeItems) +
        (returnItem?.quantity ?? 0);
      const returnableQuantity = item.returnableQuantity - returningQuantity;
      exchangeItems = exchangeItems.map(i => ({
        ...item,
        localId: i.localId,
        returnableQuantity: returnableQuantity + (i.quantity ?? 0),
      }));

      let additionalCarouselItems = [];
      if (returnableQuantity || returnItem) {
        const maxExId = max(exchangeItems.map(get("localId")));
        const localId = returnItem?.localId || maxExId + 1;
        additionalCarouselItems.push({
          ...item,
          localId,
          returnableQuantity: returnableQuantity + (returnItem?.quantity ?? 0),
        });
      }

      return [...remapped, ...exchangeItems, ...additionalCarouselItems];
    } else {
      // item is not selected or only selected for return, keep the existing
      // localId or default set to 1
      const localId = returnItem?.localId || 1;
      return [...remapped, { ...item, localId }];
    }
  }, []);

export const buildVariantInfoMap = variantInfo =>
  variantInfo.reduce((result, variant) => {
    result[variant.name] = variant.value;
    return result;
  }, {});

export const getVariantOption = (name, options) =>
  options.find(o => o.name === name);

export const getMostSimilarVariantsForSelection = (
  variants,
  variantsOptions,
  selection,
) => {
  const filterByOption = (arr, optionName) => {
    return arr.filter(
      v => v.variantInfoMap[optionName] === selection[optionName],
    );
  };

  const reduced = variantsOptions.reduce((result, option) => {
    const filtered = filterByOption(result, option.name);
    return filtered.length ? filtered : result;
  }, variants);

  return reduced;
};

export const buildVariantName = option => `option${option.position}`;

export const buildVariantAvailaibityByValue = (
  variantOption,
  currentSelection,
  itemDetails,
) => {
  const { position, values } = variantOption;
  // Names of all previous variants
  const names = itemDetails.options
    .slice(0, position - 1)
    .map(({ name }) => name);
  const filtered = itemDetails.variantInfo.filter(
    v =>
      v.availableForSale &&
      // Filter variants which value matches user selection
      names.every(n => {
        if (!currentSelection[n]) return true;
        const option = getVariantOption(n, itemDetails.options);
        if (!option) return false;
        return v[buildVariantName(option)] === currentSelection[n];
      }),
  );

  if (!filtered.length) return;

  const optionName = buildVariantName(variantOption);

  return values.reduce((result, val) => {
    result[val] = filtered.some(v => v[optionName] === val);
    return result;
  }, {});
};

export const buildVariants = details =>
  details.variantInfo.map(v => {
    const { variantInfo, variantInfoMap } = details.options.reduce(
      (result, option) => {
        const { name } = option;
        const value = v[buildVariantName(option)];
        result.variantInfo.push({ name, value });
        result.variantInfoMap[name] = value;
        return result;
      },
      { variantInfo: [], variantInfoMap: {} },
    );

    return {
      variantInfo,
      variantInfoMap,
      available: v.availableForSale,
      displayPrice: details.displayPrice,
      displayName: v.displayName,
      availabilityStatus: v.availabilityStatus,
      // id: details.id,
      name: v.displayName || v.option0 || details.name,
      productImageAltTxt: v.imageAlt || details.productImageAltTxt,
      productImageUrl: v.imageSrc || details.productImageUrl,
      variantId: v.variantId,
      productId: v.productId,
      sku: v.sku,
      option0: v.option0,
      priceAmount: v.priceAmount,
      priceCurrency: v.priceCurrency,
      priceFormatted: v.priceFormatted,
      hideDisplayPrice: details.hideDisplayPrice,
    };
  });

export const buildVariantsOptionsFallback = item =>
  item.variantInfo.map((i, index) => ({
    name: i.name,
    position: index + 1,
    values: [i.value],
  }));

export const createCarouselItemKey = item => `${item.id}_${item.localId}`;

export const isSameCarouselItem = (itemA, itemB) =>
  itemA.id === itemB.id && itemA.localId === itemB.localId;

export const rebuildVariantSelection = (name, value, selection, variants) => {
  const newVariants = { ...selection, [name]: value };
  const availableVariants = variants.filter(
    v => v.available && v.variantInfoMap[name] === value,
  );

  if (availableVariants.length) {
    const variantNames = Object.keys(newVariants);
    const availabilityByVariant = variantNames.reduce((result, n) => {
      result[n] = availableVariants.filter(
        v => v.variantInfoMap[n] === newVariants[n],
      );
      return result;
    }, {});
    const availabilityCounter = availableVariants.map(v =>
      variantNames.reduce((counter, n) => {
        if (availabilityByVariant[n].includes(v)) return counter + 1;
        return counter;
      }, 0),
    );
    const maxCounter = max(availabilityCounter);
    const maxVariantIndex = availabilityCounter.indexOf(maxCounter);
    const maxVariant = availableVariants[maxVariantIndex];

    return variantNames.reduce((result, n) => {
      const val = maxVariant.variantInfoMap[n];
      if (val) result[n] = val;
      return result;
    }, {});
  }

  return newVariants;
};
