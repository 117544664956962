import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

import { config } from "../../../config";
import { makeRootStyles } from "../../../theme/styles";
import CollapsibleOptions, {
  CollapsibleOptionItem,
} from "../CollapsibleOptions";
import ReturnComment from "./ReturnComment";
import PictureUpload from "./PictureUpload";
import ItemDetails from "./ItemDetails";
import ItemBase from "./ItemBase";
import { getBorderSelected, getBorderUnselected, getPadding } from "../utils";
import ItemMenuList from "./ItemMenuList";
import useReturnState from "./use-return-state";
import useItemSelectors from "./use-item-selectors";

export const useStyles = makeRootStyles(
  theme => ({
    collapsibleOption: {
      "&:after": {
        content: "' '",
        display: "block",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        border: getBorderUnselected(theme),
        pointerEvents: "none",
      },
    },
    collapsibleOptionSelected: {
      "&:after": {
        border: getBorderSelected(theme),
      },
    },
    optionLabel: {
      paddingRight: theme.spacing(2),
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
  { name: "N0ItemCollapsible" },
);

const ItemCollapsible = ({
  addExtraSpace,
  enableExchange,
  disabled,
  item,
  itemIneligibleReasons,
  onChangeContinuePending,
  onChangeQuantity,
  onChangeReason,
  onChangeChildReason,
  onChangeComment,
  onChoose,
  onRemove,
  setPicturesForItem,
  selectedState,
  size = "sm",
  orderFetchData,
}) => {
  const classes = useStyles();
  const detailsSummaryRef = useRef();
  const itemDetailsRef = useRef();

  const { eligible, isExchange, isReturn, isUnknown, state } = useReturnState({
    disabled,
    item,
    itemIneligibleReasons,
    selectedState,
  });

  const {
    childReturnReasonsOptions,
    isCommentMandatory,
    openChildReasonsSelect,
    openComment,
    openPictures,
    openQuantitySelect,
    openReasonsSelect,
    confirmedInComment,
    quantityOptions,
    returnReasonsOptions,
    setOpenChildReasonsSelect,
    setOpenComment,
    setOpenPictures,
    setOpenQuantitySelect,
    setOpenReasonsSelect,
    setConfirmedInComment,
    onChangeChildReason: handleChangeChildReason,
    onChangeComment: handleChangeComment,
    onChangeQuantity: handleChangeQuantity,
    onChangeReason: handleChangeReason,
    setUserHasContinuedPastPictures,
    photoUploadEnabled,
    photoUploadRequired,
  } = useItemSelectors({
    isReturn,
    isExchange,
    item,
    onChangeChildReason,
    onChangeComment,
    onChangeQuantity,
    onChangeReason,
    selectedState,
    state,
    orderFetchData,
  });
  const { returnReasonsById = {} } = orderFetchData || {};

  const returnReasonSummary = useMemo(() => {
    const reason = returnReasonsById[state.returnReason];
    return reason && reason.reasonTitle;
  }, [returnReasonsById, state.returnReason]);

  const childReturnReasonSummary = useMemo(() => {
    const reason =
      state.childReturnReason &&
      returnReasonsById[state.childReturnReason.returnReason];
    return reason && reason.reasonTitle;
  }, [returnReasonsById, state.childReturnReason]);

  const quantitySummary =
    state.quantity &&
    `${config.translations.choose_items_quantity_label}: ${state.quantity}`;

  const pictureSummary =
    state.pictures.length > 0 &&
    `${config.translations.choose_items_upload_pictures_summary}: ${state.pictures.length}`;

  const expanded = useMemo(() => {
    if (openReasonsSelect) return "return-reason";
    if (openChildReasonsSelect) return "return-child-reason";
    if (openQuantitySelect) return "quantity";
    if (openPictures) return "upload-pictures";
    if (openComment) return "comments";
    return "details";
  }, [
    openReasonsSelect,
    openChildReasonsSelect,
    openQuantitySelect,
    openPictures,
    openComment,
  ]);

  const isSelected = state.selected && !isUnknown;

  const detailsSummaryHeight = useMemo(() => {
    if (!detailsSummaryRef.current) return;
    return detailsSummaryRef.current.offsetHeight;
  }, [detailsSummaryRef.current]);

  const requiredConfirm =
    expanded === "upload-pictures" || expanded === "comments";
  useEffect(() => {
    onChangeContinuePending(item, requiredConfirm);
  }, [item, requiredConfirm]);

  const handleChangeExpanded = expandedId => {
    setOpenReasonsSelect(expandedId === "return-reason");
    setOpenChildReasonsSelect(expandedId === "return-child-reason");
    setOpenQuantitySelect(expandedId === "quantity");
    setOpenPictures(expandedId === "upload-pictures");
    setOpenComment(expandedId === "comments");
  };

  const handleToggleExchangeVariants = useCallback(
    toggle => onChangeContinuePending(item, toggle),
    [item],
  );

  const handleOnRemove = useCallback(
    (...args) => {
      setConfirmedInComment(false);
      setUserHasContinuedPastPictures(false);
      return onRemove(...args);
    },
    [onRemove],
  );

  return (
    <ItemBase
      item={item}
      onRemove={handleOnRemove}
      setPicturesForItem={setPicturesForItem}
      selectedState={selectedState}
      size={size}
      state={state}
      data-styleid="item">
      <CollapsibleOptions
        className={clsx(classes.collapsibleOption, {
          [classes.collapsibleOptionSelected]: state.selected,
        })}
        expanded={expanded}
        height={itemDetailsRef.current && itemDetailsRef.current.offsetHeight}
        onChange={handleChangeExpanded}>
        <CollapsibleOptionItem
          buttonRef={detailsSummaryRef}
          collapseId="details"
          collapseSummary={
            <Typography
              className={classes.optionLabel}
              color="inherit"
              variant="h4"
              dangerouslySetInnerHTML={{
                __html: (state.exchange || item).name,
              }}
            />
          }
          highlight>
          <ItemDetails
            ref={itemDetailsRef}
            disabled={disabled || !eligible}
            enableExchange={enableExchange}
            item={item}
            onToggleExchangeVariants={handleToggleExchangeVariants}
            onChoose={onChoose}
            size={size}
            state={state}
            style={{ marginTop: detailsSummaryHeight && -detailsSummaryHeight }}
            orderFetchData={orderFetchData}
          />
        </CollapsibleOptionItem>

        {returnReasonsOptions && (
          <CollapsibleOptionItem
            collapseId="return-reason"
            collapseLabel={
              isExchange
                ? config.translations.choose_items_select_reason_prompt_exchange
                : config.translations.choose_items_select_reason_prompt
            }
            collapseSummary={returnReasonSummary}
            highlight={!!returnReasonSummary}>
            <ItemMenuList
              onChange={handleChangeReason}
              options={returnReasonsOptions}
              value={state.returnReason}
            />
          </CollapsibleOptionItem>
        )}

        {childReturnReasonsOptions && (
          <CollapsibleOptionItem
            collapseId="return-child-reason"
            collapseLabel={
              config.translations.choose_items_select_subreason_prompt
            }
            collapseSummary={childReturnReasonSummary}
            highlight={!!childReturnReasonSummary}>
            <ItemMenuList
              onChange={handleChangeChildReason}
              options={childReturnReasonsOptions}
              value={state.childReturnReason.returnReason}
            />
          </CollapsibleOptionItem>
        )}

        {quantityOptions && (
          <CollapsibleOptionItem
            collapseId="quantity"
            collapseLabel={
              isExchange
                ? config.translations
                    .choose_items_select_quantity_prompt_exchange
                : config.translations.choose_items_select_quantity_prompt
            }
            collapseSummary={quantitySummary}
            highlight={!!quantitySummary}>
            <ItemMenuList
              onChange={handleChangeQuantity}
              options={quantityOptions}
              value={state.quantity}
            />
          </CollapsibleOptionItem>
        )}

        {photoUploadEnabled && isSelected && (
          <CollapsibleOptionItem
            collapseId="upload-pictures"
            collapseLabel={
              config.translations.choose_items_upload_pictures_prompt
            }
            collapseSummary={pictureSummary}
            highlight={!!pictureSummary}>
            <PictureUpload
              pictures={state.pictures}
              setPicturesForItem={pictures =>
                setPicturesForItem(state, pictures)
              }
              onContinue={() => {
                setOpenPictures(false);
                setUserHasContinuedPastPictures(true); // don't keep popping this up if user has declined to upload picture initially
                setPicturesForItem(state, state.pictures); // update the state, trigger recompute of `useItemSelectors` hook
              }}
              required={photoUploadRequired}
            />
          </CollapsibleOptionItem>
        )}

        {isSelected && (
          <CollapsibleOptionItem
            collapseId="comments"
            collapseLabel={config.translations.choose_items_comment_title}
            highlight={!!state.comment}>
            <ReturnComment
              onContinue={comment => {
                handleChangeExpanded("details");
                handleChangeComment(comment || "");
              }}
              required={isCommentMandatory}
              value={state.comment}
            />
          </CollapsibleOptionItem>
        )}
      </CollapsibleOptions>
    </ItemBase>
  );
};
export default ItemCollapsible;
