import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { useGrowthBook } from "@growthbook/growthbook-react";

import { memoObjectByKeyValues } from "../../shared/modules/object";
import { resetGrowthBookAttributes } from "../../shared/modules/growthBook";
import {
  useOrderFetchData,
  useReturnStepsActions,
} from "../contexts/returnSteps";

const useLoginSession = () => {
  const { actions: orderFetchDataActions } = useOrderFetchData();
  const returnStepsActions = useReturnStepsActions();
  const apolloClient = useApolloClient();
  const growthBook = useGrowthBook();

  const logout = useCallback(() => {
    returnStepsActions.clearAll();
    orderFetchDataActions.clearOrderData();
    apolloClient.cache.reset();
    resetGrowthBookAttributes(growthBook);
  }, []);

  return memoObjectByKeyValues({
    logout,
  });
};

export default useLoginSession;
