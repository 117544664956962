import { useCallback } from "react";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { isArray, isNil, last, merge } from "lodash/fp";
import noflake from "../../shared/modules/noflake";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import { config } from "../config";

export const PAGE_VIEW_EVENT = "event.page_view";
export const EXPERIMENT_VIEW_EVENT = "event.experiment_view";
export const LOGIN_SUCCESS_EVENT = "event.login_success";
export const SUBMIT_RETURN_SUCCESS_EVENT = "event.submit_return_success";
export const SAVE_PREFERENCES_CHECKED_EVENT = "event.save_preferences_checked";
export const MANAGE_PROFILE_CLICKED_EVENT =
  "action.click.user_preferences_manage_link.choose_method_step";
export const MANAGE_PROFILE_CONFIRMATION_CLICKED_EVENT =
  "action.click.user_preferences_manage_link.confirmation_step";
export const COMPLETE_PROFILE_CLICKED_EVENT =
  "action.click.user_preferences_complete_profile_link.confirmation_step";

export const countingEvent = (eventType, measureValue = 1) => ({
  eventType,
  measureValue,
});

const asyncNoop = async () => {};

export const convertFromDatadogMetrics = (metrics = [], overrides) =>
  metrics
    .filter(metric => metric.value > 0)
    .map(metric => {
      let eventType = last(metric.metricNameSuffix.split("."));
      if (metric.currencyCode) {
        eventType += "_" + metric.currencyCode.toLowerCase();
      }

      return {
        eventType,
        measureValue: metric.value,
        ...overrides,
      };
    });

const batchSubmit = (events = []) => {
  if (!isArray(events)) {
    return batchSubmit([events]);
  }

  const result = events.map(event => noflake.log("shopify_ab_test", event));
  return Promise.all(result);
};

export const abTestingTrackResult = (
  growthBook,
  events = [],
  testId = null,
) => {
  if (!growthBook) {
    return Promise.resolve([]);
  }
  // TODO: remove me. for now we won't capture all page view and login events.
  if (!config.isNthUi) {
    return Promise.resolve([]);
  }

  const userAttributes = growthBook.getAttributes();
  let variantKey = null;
  if (testId) {
    variantKey = growthBook.getFeatureValue(testId, null);
    if (typeof variantKey !== "string" && !isNil(variantKey)) {
      variantKey = JSON.stringify(variantKey);
    }
  }

  const patchDefaultValue = merge({
    ...userAttributes,
    testId,
    variantKey,
    eventType: null,
    measureValue: 0,
  });

  events = isArray(events) ? events : [events];
  return batchSubmit(events.map(patchDefaultValue));
};

export const useAbTesting = () => {
  const growthBook = useGrowthBook();

  const trackResult = useCallback(
    (events = [], testId = null) =>
      abTestingTrackResult(growthBook, events, testId),
    [growthBook],
  );

  return memoObjectByKeyValues({
    trackResult: growthBook ? trackResult : asyncNoop,
  });
};
