import React from "react";

import ItemCollapsible from "./ItemCollapsible";
import ItemDefault from "./ItemDefault";

const Item = ({ size = "sm", ...rest }) => {
  const Component = size === "sm" ? ItemCollapsible : ItemDefault;
  return <Component size={size} {...rest} />;
};
export default Item;
