import { useState } from "react";
import { v4 } from "uuid";

export const randomIdString = (length = 0) => {
  const rand = () =>
    Math.random()
      .toString(36)
      .substring(2);
  if (!length) return rand();

  let ret = "";
  while (ret.length < length) {
    ret += rand();
  }
  return ret.substring(0, length);
};

const autoIncrementIds = {};

export const autoIncrementId = group => {
  autoIncrementIds[group] = autoIncrementIds[group] ?? 0;
  return ++autoIncrementIds[group];
};

export const useElementId = (prefix = "") => {
  const [id] = useState(prefix + randomIdString());
  return id;
};

export const uuid = () => v4();

export const useUuid = () => {
  const [id] = useState(uuid());
  return id;
};
