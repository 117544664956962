import { omitNullValues } from "./object";

export const buildToolbarHeightForProp = (
  muiTheme,
  cssProp = "minHeight",
  negative,
) => ({
  [cssProp]: negative ? -64 : 64,
  [`${muiTheme.breakpoints.up("xs")} and (orientation: landscape)`]: {
    [cssProp]: negative ? -48 : 48,
  },
  [muiTheme.breakpoints.up("sm")]: {
    [cssProp]: negative ? -80 : 80,
  },
});

/**
 * Helper for adjust grid responsive config, it is useful when you are building
 * a shared component and expect there will be nested girds in parent.
 * @param {object} config grid sizing config eg. {xs: 12, sm: 10, md: 8, lg: 6, xl: 4}
 * @param {string|number} size small / medium / large / -2, -1, 0, 1, ...
 * @returns {object}
 */
export const controlGridSize = (config, size) => {
  const mapping = { medium: 0, small: -1, large: 1 };
  let dir = mapping[size] !== undefined ? mapping[size] : size;
  if (dir === 0 || isNaN(dir)) return config;

  let cfg = [config.xs, config.sm, config.md, config.lg, config.xl];
  const modify = dir > 0 ? "shift" : "unshift";
  dir = Math.abs(dir);
  for (let i = 0; i < dir; i++) {
    cfg[modify](undefined);
  }

  return omitNullValues({
    xs: cfg[0],
    sm: cfg[1],
    md: cfg[2],
    lg: cfg[3],
    xl: cfg[4],
  });
};
