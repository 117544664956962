import React, { useRef, useState, useEffect, useMemo } from "react";
import ImageUploadLarge from "./ImageUploadLarge";
import ImageUploadSmall from "./ImageUploadSmall";
import ImageUploadNormal from "./ImageUploadNormal";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(
  theme => ({
    root: {
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
    },
    inputContainer: {
      height: 0,
      width: 0,
      overflow: "hidden",
      transform: "translateY(-100px)",
    },
  }),
  { name: "N0ImageUpload" },
);

const ImageUpload = ({
  className,
  helperText = "Upload a JPG or PNG",
  label = "Upload Image",
  value,
  errorText,
  onChange,
  variant = "small",
}) => {
  const inputRef = useRef(null);
  const [preview, setPreview] = useState(value);
  const classes = useStyles();

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleChange = event => {
    const file = event.target.files[0];
    if (!file) return; // user click "cancel" in popup

    const reader = new FileReader();
    reader.onload = event => {
      setPreview(event.target.result);
    };

    reader.readAsDataURL(file);
    onChange(event.target.files[0]);
  };

  useEffect(() => {
    setPreview(value);
  }, [value]);

  const inputNode = useMemo(() => {
    if (variant === "large") {
      return (
        <ImageUploadLarge
          className={className}
          helperText={helperText}
          errorText={errorText}
          label={label}
          preview={preview}
        />
      );
    } else if (variant === "normal") {
      return (
        <ImageUploadNormal
          className={className}
          helperText={helperText}
          errorText={errorText}
          label={label}
          preview={preview}
        />
      );
    }

    return (
      <ImageUploadSmall
        className={className}
        helperText={helperText}
        errorText={errorText}
        label={label}
        preview={preview}
      />
    );
  }, [className, helperText, label, preview, variant]);

  return (
    <div className={clsx(classes.root, className)} onClick={handleClick}>
      <div className={classes.inputContainer}>
        <input
          accept=".png, .jpg, .jpeg"
          ref={inputRef}
          type="file"
          name="file"
          onChange={handleChange}
        />
      </div>
      {inputNode}
    </div>
  );
};

export default ImageUpload;
