import React, { useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";
import { config } from "../../../config";
import { getMostSimilarVariantsForSelection } from "../../../modules/items";
import { makeRootStyles } from "../../../theme/styles";

import Sparsem, {
  UNAVAILABLE_IN_THIS_SET,
  UNAVAILABLE_IN_ANY_SET,
} from "../../../modules/sparsem";
import { useFrameDimensions } from "../../../contexts/frameDimensions";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      height: "calc(100% - 3.125em)",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    select: {
      marginTop: theme.spacing(2),
    },
    content: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    helperText: {},
    availabilityStatus: {},
    optionInputValue: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    optionMenuItem: {
      whiteSpace: "normal",
    },
    optionUnavailable: {
      color: theme.palette.text.hint,
    },
    shopNowContainer: {},
  }),
  { name: "N0ExchangeItemDetails" },
);

const ExchangeItemDetails = ({
  item,
  loading,
  onConfirm,
  onChange,
  selectedItem,
  variants,
  variantsOptions,
}) => {
  const classes = useStyles();
  const {
    initiated,
    dimensions,
    bottomOfViewport,
    topOfViewport,
  } = useFrameDimensions();

  const [localVariants, setLocalVariants] = useState({});

  // It is possible for an item to have no variant options, when a Shopify
  // product does not have any variant options and we set
  // `shop.variant_option_exclusion = ['title']` in our backend.
  const hasOptions = variantsOptions?.length > 0;

  // HACK:
  // Fixing the jumping focus issue of select menu input in consumer app.
  //
  // Due to the dimension of iframe's viewport refers to the width and height of
  // iframe (not the window's width and height). The initial position of popover
  // content (the select menu) might be rendered out of the visible area of the
  // window, which causes the jumping focus. For web accessibility, material UI
  // components should always auto-focus to the popover / modal / select menu,
  // the following hack is going to perform the auto-focus manually and to
  // adjust the initial position of the popover content.
  //
  // Events flow:
  // Select.onOpen > Menu.onEnter > Menu.onEntering > Menu.onEntered
  const [activeSelectInputEl, setActiveSelectInputEl] = useState(null);
  const [popoverEl, setPopoverEl] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({});
  const selectInputProps = useMemo(
    () => ({
      onOpen: event =>
        setActiveSelectInputEl(event.target.closest(".MuiSelect-root")), // remember the TextField(select input) on UI
      onClose: event => {
        // cleanup
        setActiveSelectInputEl(null);
        setPopoverEl(null);
      },
      MenuProps: {
        autoFocus: false, // disable auto-focus on menu item, this cause MUI to focus on the menu (.MuiMenu-paper) instead
        TransitionProps: {
          onEnter: popover => {
            popover.parentElement.style.top = `${window.parentData?.scrollY ||
              0}px`; // adjust the initial position of the popover backdrop (.MuiPopover-root[role=presentation])
          },
          onEntering: popover => {
            popover.parentElement.style.top = "0px"; // reset the backdrop position
            activeSelectInputEl?.focus(); // focus on the select input, so that if the select menu is closed, there is no jumping focus when MUI tries to focus back to the select input.
            popover.focus(); // focus on the popover menu
            popover.querySelector('[tabindex="0"]')?.focus(); // focus on any selected option
            setPopoverEl(popover);

            // HACK:
            // when popover select menu show up, manually adjust the vertical position so
            // that it won't go outside the viewport.
            const top = parseInt(popover.style.top || 0);
            const height =
              popover.querySelector("ul")?.clientHeight || popover.clientHeight;
            const bottomFromTop = top + height;
            const padding = 20;
            const viewportHeight = dimensions.viewportHeight;
            if (height + padding * 2 > viewportHeight) {
              setPopoverPosition({
                top: `${topOfViewport(padding)}px`,
                bottom: `${bottomOfViewport(padding)}px`,
              });
            } else {
              const viewportTop = topOfViewport(padding);
              const viewportBottomFromTop = topOfViewport(viewportHeight);
              if (top < viewportTop) {
                setPopoverPosition({
                  top: `${viewportTop}px`,
                  bottom: "",
                });
              } else if (bottomFromTop > viewportBottomFromTop) {
                const diff = bottomFromTop - viewportBottomFromTop + padding;
                setPopoverPosition({
                  top: `${top - diff}px`,
                  bottom: "",
                });
              }
            }
          },
        },
      },
    }),
    [activeSelectInputEl],
  );

  // Apply popover position style when dimensions change (eg. scrolling), fix
  // popover to a specific location to prevent MUI adjust it. Thus, improve the
  // flicking/jumping UI cause by the incompatible of MUI and long iframe.
  useEffect(() => {
    if (popoverEl && initiated) {
      const popover = popoverEl;
      for (let key in popoverPosition) {
        popover.style[key] = popoverPosition[key];
      }
    }
  }, [popoverEl, popoverPosition, initiated, dimensions]);

  useEffect(() => {
    setLocalVariants(selectedItem?.variantInfoMap || {});
  }, [selectedItem]);

  const variantSelected = useMemo(
    () =>
      variants.find(v =>
        v.variantInfo.every(i => localVariants[i.name] === i.value),
      ),
    [localVariants, variants],
  );
  const isValidVariant = variantSelected && variantSelected.available;
  const areAllVariantsOutOfStock = variants.every(
    variant => !variant.available,
  );

  const variantPreview = useMemo(() => {
    const filtered = getMostSimilarVariantsForSelection(
      variants,
      variantsOptions,
      localVariants,
    );

    return filtered[0] || item;
  }, [item, localVariants, variants, variantsOptions]);

  const sparsemVariants = variants
    .filter(variant => variant.available)
    .map(variant => {
      return variant.variantInfo.map(v => v.value);
    });
  const sparsemOptions = variantsOptions.map(option => option.values);

  const sparsem = new Sparsem();
  sparsem.setArr(sparsemVariants, sparsemOptions);

  const getVariantValueForOption = optionName => {
    const value = localVariants[optionName];
    if (
      value &&
      variantsOptions.some(
        option => option.name === optionName && option.values.includes(value),
      )
    ) {
      return value;
    }
    return "";
  };

  const createChangeVariantHandler = name => ({ target }) => {
    const { value } = target;
    const newVariants = {
      ...localVariants,
      [name]: value,
    };
    setLocalVariants(newVariants);
    onChange(newVariants);
  };

  const handleShopNow = e => {
    if (e) e.stopPropagation();
    onConfirm({
      available: true,
      isStoreCredit: true,
      variantInfo: [],
      variantInfoMap: {},
    });
  };

  const handleConfirm = e => {
    if (e) e.stopPropagation();
    if (!isValidVariant) return;

    onConfirm(variantSelected);
  };

  const variantSelectedAvailable = variantSelected && variantSelected.available;
  const title = variantPreview.option0 || item.productTitle || item.name;

  return (
    <div className={classes.root} data-styleid="exchange">
      <div className={classes.content}>
        {hasOptions &&
          config.translations
            .choose_items_choose_exchange_variant_helper_text && (
            <Typography className={classes.helperText} variant="h5">
              {
                config.translations
                  .choose_items_choose_exchange_variant_helper_text
              }
            </Typography>
          )}
        {variantPreview.availabilityStatus && (
          <Typography
            className={classes.availabilityStatus}
            variant="h5"
            color="primary">
            {variantPreview.availabilityStatus}
          </Typography>
        )}

        {variantsOptions.map(variant => {
          const value = getVariantValueForOption(variant.name);

          const sparsemQuery = variantsOptions.map(v =>
            v.name === variant.name ? null : localVariants[v.name],
          );
          const sparsemResult = sparsem.search(sparsemQuery);

          const availability = variant.values.reduce((acc, value) => {
            acc[value] =
              sparsemResult[value] == UNAVAILABLE_IN_THIS_SET ||
              sparsemResult[value] == UNAVAILABLE_IN_ANY_SET;
            return acc;
          }, {});
          const unavailableText = variant.values.reduce((acc, value) => {
            if (sparsemResult[value] == UNAVAILABLE_IN_THIS_SET) {
              acc[value] =
                " " +
                config.translations
                  .choose_items_choose_exchange_variant_not_available_in_selected;
            } else if (sparsemResult[value] == UNAVAILABLE_IN_ANY_SET) {
              acc[value] =
                " " +
                config.translations
                  .choose_items_choose_exchange_variant_not_available;
            }
            return acc;
          }, {});

          const sortedVariantValues = config.sortExchangeVariantsByAvailability
            ? sortBy(variant.values, v => !!availability[v])
            : variant.values;

          const variantHeader = `${config.translations.choose_items_choose_exchange_variant_label_general} ${variant.name}`;

          return (
            <TextField
              key={variant.name}
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              label={variant.name}
              color="primary"
              margin="normal"
              value={value}
              disabled={loading}
              onChange={createChangeVariantHandler(variant.name)}
              SelectProps={{
                className: classes.select,
                displayEmpty: true,
                renderValue: val =>
                  loading ? (
                    <CircularProgress color="secondary" size={16} />
                  ) : (
                    <Typography className={classes.optionInputValue}>
                      {val || variantHeader}
                      {unavailableText && unavailableText[val] && (
                        <Typography
                          className={classes.optionUnavailable}
                          component="span">
                          {unavailableText[val]}
                        </Typography>
                      )}
                    </Typography>
                  ),
                ...selectInputProps,
              }}>
              <MenuItem value="" disabled>
                {variantHeader}
              </MenuItem>
              {sortedVariantValues.map(val => (
                <MenuItem
                  key={val}
                  className={clsx(classes.optionMenuItem, {
                    [classes.optionUnavailable]:
                      availability && availability[val],
                  })}
                  value={val}>
                  {val}
                  {unavailableText && unavailableText[val]}
                </MenuItem>
              ))}
            </TextField>
          );
        })}
        {hasOptions &&
          config.isShopNowEnabled &&
          config.isShopNowWithCreditEnabled && (
            <div className={classes.shopNowContainer}>
              <Typography variant="body1">
                {
                  config.translations
                    .choose_items_choose_exchange_variant_shopnow_action_description
                }
              </Typography>
              <Link className="shopnow-instead-link" onClick={handleShopNow}>
                {
                  config.translations
                    .choose_items_choose_exchange_variant_shopnow_action_link
                }
              </Link>
            </div>
          )}
      </div>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}>
        <Grid item xs={12} md={12}>
          <Button
            fullWidth
            className="confirm-exchange-variant-button"
            color="primary"
            disabled={!isValidVariant}
            onClick={handleConfirm}
            size="medium"
            variant="contained"
            data-styleid="exchange-submit-button">
            <strong
              dangerouslySetInnerHTML={{
                __html:
                  !loading && areAllVariantsOutOfStock
                    ? config.translations
                        .choose_items_exchange_item_button_out_of_stock
                    : config.translations
                        .choose_items_exchange_item_button_label,
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default ExchangeItemDetails;
