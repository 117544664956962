import React, { useCallback, useRef, useMemo } from "react";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import MenuList from "@material-ui/core/MenuList";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PropTypes from "prop-types";

import { useElementSize } from "../../../shared/modules/hooks";
import { makeRootStyles } from "../../theme/styles";

const useStyles = makeRootStyles(
  theme => ({
    menuContainer: {
      position: "absolute",
      left: -3,
      bottom: 0,
      width: "100%",
      zIndex: 2,
    },
  }),
  { name: "N0Select" },
);

const useInputStyles = makeRootStyles(
  theme => ({
    root: {
      height: "100%",
      lineHeight: `${theme.spacing(3)}px`,
      position: "relative",
      borderRadius: 0,
      fontFamily: theme.typography.secondaryFontFamily,
    },
    input: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3) - 3,
      paddingRight: theme.spacing(0.5),
      fontWeight: "bold",
      fontFamily: "inherit",
    },
    notchedOutline: {
      border: "0 none",
    },
  }),
  { name: "N0SelectInput" },
);

function Select({
  children,
  classes: parentClasses,
  onChange,
  onClose,
  onOpen,
  open,
  value,
  ...props
}) {
  const anchorRef = useRef(null);
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { width } = useElementSize(anchorRef.current);

  const handleChange = useCallback(
    function handleChange(selectedValue) {
      onChange(selectedValue);
      onClose();
    },
    [onChange, onClose],
  );

  const mappedChildren = useMemo(
    () =>
      React.Children.map(children, element => {
        if (element.props.disabled) return element;

        return (
          <element.type
            {...element.props}
            onClick={() => handleChange(element.props.value)}
            selected={element.props.value === value}
          />
        );
      }),
    [children, handleChange, value],
  );

  const label = useMemo(() => {
    const element = mappedChildren.find(el => el.props.value === value);
    return element.props.children;
  }, [mappedChildren, value]);

  return (
    <FormControl
      ref={anchorRef}
      variant="outlined"
      className={parentClasses.root}
      fullWidth>
      <OutlinedInput
        {...props}
        classes={inputClasses}
        endAdornment={<ArrowDropDownIcon />}
        onClick={onOpen}
        value={label}
        readOnly
      />
      <div className={classes.menuContainer} style={{ width }}>
        <Collapse in={open} mountOnEnter>
          <MenuList
            className={parentClasses.menuList}
            variant="selectedMenu"
            disablePadding>
            {mappedChildren}
          </MenuList>
        </Collapse>
      </div>
    </FormControl>
  );
}
Select.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    menuList: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.any,
};
export default React.memo(Select);
