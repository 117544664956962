import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeRootStyles } from "../../../theme/styles";
import { config } from "../../../config";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      width: "100%",
      padding: theme.spacing(0, 3, 3),
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
    },
    helperText: {},
    input: {
      margin: theme.spacing(2, 0),
      flex: 1,
    },
    inputMultiline: {
      height: "100%",
      alignItems: "flex-start",
    },
    submitButton: {},
  }),
  { name: "N0ReturnComment" },
);

const ReturnComment = ({ item, onContinue, required, value }) => {
  const classes = useStyles();
  const [comment, setComment] = useState(value || "");

  return (
    <form
      className={classes.root}
      onSubmit={event => {
        event.preventDefault();
        onContinue(comment);
      }}>
      <Typography
        className={classes.helperText}
        color="textSecondary"
        component="p"
        variant="caption">
        {config.translations.choose_items_comment_helper_text}:
      </Typography>
      <TextField
        autoFocus
        fullWidth
        multiline
        InputProps={{
          classes: { multiline: classes.inputMultiline },
        }}
        className={classes.input}
        color="primary"
        onChange={({ target: { value } }) => setComment(value)}
        placeholder={
          required
            ? config.translations.choose_items_comment_required_placeholder
            : config.translations.choose_items_comment_optional_placeholder
        }
        value={comment}
        variant="outlined"
      />
      <Button
        className={classes.submitButton}
        color="primary"
        disabled={
          required &&
          (!comment || typeof comment !== "string" || !comment.trim())
        }
        size="medium"
        type="submit"
        variant="contained"
        fullWidth>
        <strong>
          {config.translations.choose_items_comment_continue_label}
        </strong>
      </Button>
    </form>
  );
};

export default ReturnComment;
