import { useState, useEffect, useMemo } from "react";
import range from "lodash/range";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import find from "lodash/find";

const isEscKey = e =>
  (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) &&
  e.target.nodeName === "BODY";

const useItemSelectors = ({
  isReturn,
  isExchange,
  item,
  onChangeChildReason,
  onChangeComment,
  onChangeQuantity,
  onChangeReason,
  selectedState,
  state,
  orderFetchData = {},
}) => {
  const isUnknown = !isReturn && !isExchange;
  const photoUploadEnabled =
    orderFetchData["returnReasonsById"] &&
    orderFetchData.returnReasonsById[state.returnReason] &&
    orderFetchData.returnReasonsById[state.returnReason].enableCustomerPictures;
  const photoUploadRequired =
    orderFetchData["returnReasonsById"] &&
    orderFetchData.returnReasonsById[state.returnReason] &&
    orderFetchData.returnReasonsById[state.returnReason]
      .requireCustomerPictures;

  const isCommentMandatory = useMemo(() => {
    if (!orderFetchData) return false;

    const parentReason = find(orderFetchData.returnReasons, {
      id: state.returnReason,
    });

    const childReason =
      parentReason &&
      find(orderFetchData.returnReasons, {
        id: state.childReturnReason?.returnReason,
      });

    return parentReason?.isCommentMandatory || childReason?.isCommentMandatory;
  }, [state.returnReason, state.childReturnReason?.returnReason]);

  const childrenReturnReason = useMemo(() => {
    const childrenReasons = item.eligibilityCriteria.filter(
      i => i.returnReasonParent === state.returnReason,
    );
    if (childrenReasons.length) return childrenReasons;
    return null;
  }, [item.eligibilityCriteria, state.returnReason]);

  const [openReasonsSelect, setOpenReasonsSelect] = useState(false);
  const [openChildReasonsSelect, setOpenChildReasonsSelect] = useState(false);
  const [openQuantitySelect, setOpenQuantitySelect] = useState(false);
  const [openPictures, setOpenPictures] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [confirmedInComment, setConfirmedInComment] = useState(false);
  const [
    userHasContinuedPastPictures,
    setUserHasContinuedPastPictures,
  ] = useState(false);

  const handleChangeReason = returnReason => {
    onChangeReason(
      item,
      item.eligibilityCriteria.find(c => c.returnReason === returnReason),
    );
  };

  const handleChangeChildReason = returnReason => {
    onChangeChildReason(
      item,
      item.eligibilityCriteria.find(c => c.returnReason === returnReason),
    );
  };

  const handleChangeQuantity = quantity => {
    onChangeQuantity(item, quantity);
  };

  const handleChangeComment = comment => {
    setConfirmedInComment(true);
    onChangeComment(item, comment);
  };

  // Return & Exchange types
  useEffect(() => {
    if (!state) return;

    setOpenPictures(() => {
      if (state.pictures.length > 0) return false;
      if (!state.returnReason) return false;
      if (state.childReturnReason && !state.childReturnReason.returnReason) {
        return false;
      }
      if (!photoUploadEnabled) return false;
      if (!photoUploadRequired && userHasContinuedPastPictures) return false;
      if (!state.quantity) return false;
      if (!state.eligible) return false;

      return true;
    });
  }, [state]);

  // Return & Exchange types
  useEffect(() => {
    if (!state) return;
    if (!orderFetchData) return;

    setOpenComment(() => {
      if (!state.returnReason) return false;
      if (state.childReturnReason && !state.childReturnReason.returnReason) {
        return false;
      }

      if (!state.quantity) return false;
      if (!state.eligible) return false;
      if (item.requirePicture && state.pictures.length == 0) return false;

      if (!confirmedInComment) return true;
      if (isCommentMandatory) {
        if (
          !state.comment ||
          typeof state.comment !== "string" ||
          !state.comment.trim()
        ) {
          return true;
        }
      }

      return false;
    });
  }, [state]);

  // Return & Exchange types
  useEffect(() => {
    if (!state || isUnknown || !state.selected) {
      setOpenReasonsSelect(false);
      setOpenChildReasonsSelect(false);
      setOpenQuantitySelect(false);
      setOpenPictures(false);
      setOpenComment(false);
      return;
    }

    if (!state.returnReason) {
      return setOpenReasonsSelect(true);
    }
    setOpenReasonsSelect(false);

    if (childrenReturnReason && !state.childReturnReason?.returnReason) {
      return setOpenChildReasonsSelect(true);
    }
    setOpenChildReasonsSelect(false);

    if (!state.quantity && item.returnableQuantity > 1 && state.eligible) {
      return setOpenQuantitySelect(true);
    }
    setOpenQuantitySelect(false);
  }, [isUnknown, childrenReturnReason, item.returnableQuantity, state]);

  const returnReasonsById = keyBy(get(orderFetchData, "returnReasons"), "id");

  const buildReturnReasonsOptions = () => {
    if (!state.selected || isUnknown) return;

    const options = item.eligibilityCriteria
      .filter(i => !i.returnReasonParent)
      .map(criteria => ({
        label: criteria.returnReasonTitle,
        value: criteria.returnReason,
      }));
    return sortBy(options, o => {
      return returnReasonsById[o.value] && returnReasonsById[o.value].priority;
    });
  };

  const buildChildReturnReasonsOptions = () => {
    if (
      !state.selected ||
      isUnknown ||
      !state.returnReason ||
      !childrenReturnReason
    ) {
      return;
    }

    const options = childrenReturnReason.map(criteria => ({
      label: criteria.returnReasonTitle,
      value: criteria.returnReason,
    }));
    return sortBy(options, o => {
      return returnReasonsById[o.value].priority;
    });
  };

  const buildQuantityOptions = () => {
    if (!state.selected || isUnknown || item.returnableQuantity <= 1) {
      return;
    }

    return range(1, item.returnableQuantity + 1).map(qty => ({
      label: qty,
      value: qty,
    }));
  };

  const returnReasonsOptions = buildReturnReasonsOptions();
  const childReturnReasonsOptions = buildChildReturnReasonsOptions();
  const quantityOptions = buildQuantityOptions();

  return {
    childReturnReasonsOptions,
    isUnknown,
    isCommentMandatory,
    openChildReasonsSelect,
    openComment,
    openPictures,
    openQuantitySelect,
    openReasonsSelect,
    confirmedInComment,
    quantityOptions,
    returnReasonsOptions,
    setUserHasContinuedPastPictures,
    setOpenChildReasonsSelect,
    setOpenComment,
    setOpenPictures,
    setOpenQuantitySelect,
    setOpenReasonsSelect,
    setConfirmedInComment,
    onChangeChildReason: handleChangeChildReason,
    onChangeComment: handleChangeComment,
    onChangeQuantity: handleChangeQuantity,
    onChangeReason: handleChangeReason,
    photoUploadEnabled,
    photoUploadRequired,
  };
};
export default useItemSelectors;
