import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import FieldLabel from "./FieldLabel";

const useStyles = makeStyles(
  () => ({
    root: {
      width: "100%",
      marginBottom: 16,
    },
  }),
  { name: "GroupField" },
);

function GroupField({ children, label, ...other }) {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <FormGroup>
        {React.Children.map(children, element => {
          const { label, ...other } = element.props;

          return (
            <FormControlLabel
              control={<element.type {...other} />}
              label={label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
GroupField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};
export default GroupField;
