import { compose, fromPairs, map, toPairs } from "lodash/fp";
import errorNotifier from "../../shared/modules/error-notifier";
import { TEMPLATE_VAR_REGEX } from "../../shared/modules/template";

export const toNthTemplateString = (string, variableRenames = {}) => {
  try {
    string = string ?? "";
    let ret = string.replace(TEMPLATE_VAR_REGEX, "{{$1}}");
    toPairs(variableRenames).forEach(([from, to]) => {
      ret = ret.replace(new RegExp(`{{${from}}}`, "g"), `{{${to}}}`);
    });
    return ret;
  } catch (err) {
    console.warn(err);
    console.warn(`NTH Template conversion error: "${string}"`);
    err.message = "NTH Template conversion error";
    errorNotifier.warn(err, { template: string });
    return string;
  }
};

export const toNthTemplateConfig = (config, variableRenames = {}) =>
  compose(
    fromPairs,
    map(([key, value]) => {
      return [key, toNthTemplateString(value, variableRenames[key])];
    }),
    toPairs,
  )(config);
