import React, { useCallback, useMemo } from "react";
import {
  ViewportConfigProvider,
  ReturnsConfigProvider,
  useReturns,
} from "@narvar/nth-kit-returns-headless";

import { darken, lighten } from "@material-ui/core/styles";
import { getBranding, getTranslation } from "../../shared/modules/config";
import { buildSansFontFamily } from "../../shared/modules/fonts";
import { useFrameDimensions } from "./frameDimensions";
import { openLabel } from "../../shared/modules/frame";
import { computeFontStyle } from "../../shared/theme/styles";
import { toNthTemplateConfig } from "../modules/template";
import { interpolate } from "../../shared/modules/template";
import { omit, startsWith } from "lodash/fp";
import {
  CONFIRMATION_STEP,
  useReturnStepIsActive,
  useReturnStepsState,
} from "./returnSteps";
import noflake from "../../shared/modules/noflake";
import { config } from "../config";
import { featureFlaggedHook } from "../../shared/modules/hooks";
import {
  METHOD_KEEP_THE_ITEM,
  METHOD_SHIP_ON_YOUR_OWN,
  RETURN_CREDIT_METHOD_RESHOP,
} from "../../retailer-app/constants/returns";
import {
  COMPLETE_PROFILE_CLICKED_EVENT,
  MANAGE_PROFILE_CLICKED_EVENT,
  MANAGE_PROFILE_CONFIRMATION_CLICKED_EVENT,
  countingEvent,
  useAbTesting,
} from "../data/abTesting";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import useConsumerSettings from "../hooks/useConsumerSettings";

const lomaUrl =
  config.environment === "production"
    ? "https://loma.narvar.com/graphql"
    : "https://loma-st01.narvar.qa/graphql";

export function NthContextProvider({ children }) {
  if (!config.isNthUi && !config.isNthLoginUi) return children; // skip all nth providers' specific data mapping

  const state = useReturnStepsState();
  const abTesting = useAbTesting();
  const isConfirmationStep = useReturnStepIsActive(CONFIRMATION_STEP);
  const { bottomOfViewport, topOfViewport } = useFrameDimensions();
  const externalLinkHandler = useCallback(
    event => {
      openLabel(event.url);

      if (startsWith(config.userPreferencesEndpoint, event.url)) {
        const event = !isConfirmationStep
          ? MANAGE_PROFILE_CLICKED_EVENT
          : state.savePreferences
          ? COMPLETE_PROFILE_CLICKED_EVENT
          : MANAGE_PROFILE_CONFIRMATION_CLICKED_EVENT;
        abTesting.trackResult(countingEvent(event));
      }
    },
    [openLabel, isConfirmationStep, state.savePreferences],
  );
  const viewportConfig = useMemo(
    () => ({
      anchor: {
        offsetTop: topOfViewport(0),
        offsetBottom: bottomOfViewport(0),
      },
      externalLinkHandler,
    }),
    [topOfViewport, bottomOfViewport, externalLinkHandler],
  );

  const translations = useMemo(() => {
    const isTrack = state.mode === "track";
    const isExchanging = state.selectingItem?.type === "exchange";
    const asyncLabel = state.submitReturnResultCode === "label_retry_enqueued";
    const noRefund = state.refundTotal === 0;
    const isGiftReturn = state.guestReturn;

    const confirmationTranslationsFactory = type => {
      const ts = {
        [`returnsConfirmation${type}ActionsPanelHeader`]:
          type === METHOD_SHIP_ON_YOUR_OWN || type === "store"
            ? getTranslation("return_confirmation_print_packing_slip_header")
            : getTranslation("return_confirmation_print_label_header"),
        [`returnsConfirmation${type}ActionsPanelMainButton`]:
          type === METHOD_SHIP_ON_YOUR_OWN || type === "store"
            ? getTranslation("return_confirmation_print_packing_slip")
            : getTranslation("return_confirmation_print_label"),
        [`returnsConfirmation${type}ActionsPanelTitle`]:
          type === METHOD_KEEP_THE_ITEM
            ? getTranslation("return_confirmation_title_keep_item")
            : getTranslation("return_confirmation_title"),
        [`returnsConfirmation${type}ActionsPanelDescription`]: getTranslation(
          asyncLabel
            ? "return_confirmation_subtitle_label_retry_enqueued"
            : type === METHOD_KEEP_THE_ITEM && noRefund
            ? "return_confirmation_subtitle_keep_item_no_refund"
            : type === METHOD_KEEP_THE_ITEM && !noRefund
            ? "return_confirmation_subtitle_keep_item"
            : "return_confirmation_subtitle",
        ),
        [`returnsConfirmation${type}Step1Title`]: getTranslation(
          `return_confirmation_instructions_${type}_step_1_title`,
        ),
        [`returnsConfirmation${type}Step1Details`]: getTranslation(
          `return_confirmation_instructions_${type}_step_1_subtitle`,
        ),
        [`returnsConfirmation${type}Step2Title`]: getTranslation(
          `return_confirmation_instructions_${type}_step_2_title`,
        ),
        [`returnsConfirmation${type}Step2Details`]: getTranslation(
          `return_confirmation_instructions_${type}_step_2_subtitle`,
        ),
        [`returnsConfirmation${type}Step3Title`]: getTranslation(
          `return_confirmation_instructions_${type}_step_3_title`,
        ),
        [`returnsConfirmation${type}Step3Details`]: getTranslation(
          `return_confirmation_instructions_${type}_step_3_subtitle`,
        ),
        [`returnsConfirmation${type}LocationPanelHeader`]: getTranslation(
          "return_confirmation_drop_off_location_header",
        ),
        [`returnsConfirmation${type}InstructionsPanelHeader`]: getTranslation(
          "return_confirmation_instructions_header",
        ),
        [`returnsConfirmation${type}ItemsPanelHeader`]: getTranslation(
          "return_confirmation_items_returning_header",
        ),
      };
      return ts;
    };

    let translations = {
      returnsOrderLoginAbove: getTranslation(
        isTrack
          ? "track_app_order_lookup_supplementary_text1"
          : "order_lookup_supplementary_text1",
      ),
      returnsOrderLoginBelow: getTranslation(
        isTrack
          ? "track_app_order_lookup_supplementary_text2"
          : "order_lookup_supplementary_text2",
      ),
      returnsOrderLoginFormAbove: "",
      returnsOrderLoginFormBelow: "",
      returnsOrderLoginSubmitAbove: "",
      returnsOrderLoginSubmitBelow: "",
      returnsOrderLoginTitleBelow: "",
      returnsOrderLoginFieldsAbove: "",
      returnsOrderLoginTitle: getTranslation(
        isTrack
          ? isGiftReturn
            ? "track_app_order_lookup_gift_title"
            : "track_app_order_lookup_title"
          : isGiftReturn
          ? "order_lookup_gift_title_start_return"
          : "order_lookup_title_start_return",
      ),
      returnsOrderLoginDescription: getTranslation(
        isTrack
          ? isGiftReturn
            ? "track_app_order_lookup_gift_start_instructions"
            : "track_app_order_lookup_start_return_instructions"
          : isGiftReturn
          ? "order_lookup_gift_start_instructions"
          : "order_lookup_start_return_instructions",
      ),
      returnsOrderLoginFormSubmit: getTranslation(
        isTrack ? "track_app_order_lookup_submit" : "order_lookup_submit",
      ),
      returnsOrderLoginFormLabelEmail: getTranslation(
        isGiftReturn ? "order_lookup_gift_email" : "order_lookup_email",
      ),
      returnsOrderLoginFormLabelOrder: getTranslation(
        isGiftReturn
          ? "order_lookup_gift_order_number"
          : "order_lookup_order_number",
      ),
      returnsOrderLoginFormLabelGiftCode: getTranslation(
        config.isGiftZipEnabled
          ? "order_lookup_gift_shipping_zip"
          : "order_lookup_gift_code",
      ),
      returnsOrderLoginFormCaptionEmail: getTranslation(
        isTrack
          ? "track_app_order_lookup_gift_email_helper"
          : "order_lookup_gift_email_helper",
      ),
      returnsOrderLoginFormCaptionOrder: "",
      returnsOrderLoginFormCaptionGiftCode: getTranslation(
        isTrack
          ? "track_app_order_lookup_gift_code_helper"
          : "order_lookup_gift_code_helper",
      ),
      returnsOrderLoginReturnPolicy: "", // "Read our Return Policy"
      returnsOrderLoginError: getTranslation(
        isGiftReturn ? "order_lookup_not_found_gift" : "order_lookup_not_found",
      ),
      returnsOrderLoginFormValidationErrorEmail: getTranslation(
        "order_lookup_email_invalid",
      ),
      returnsOrderLoginToggleGiftReturnTitle: getTranslation(
        isTrack ? "track_app_order_lookup_gift_link" : "order_lookup_gift_link",
      ),
      returnsOrderLoginToggleGiftReturnLinkText: getTranslation(
        isTrack
          ? "track_app_order_lookup_gift_title"
          : "order_lookup_gift_title_start_return",
      ),
      returnsOrderLoginToggleGiftReturnTitle_giftReturn: getTranslation(
        isTrack
          ? "track_app_order_lookup_not_gift_link"
          : "order_lookup_not_gift_link",
      ),
      returnsOrderLoginToggleGiftReturnLinkText_giftReturn: getTranslation(
        isTrack
          ? "track_app_order_lookup_title"
          : "order_lookup_title_start_return",
      ),

      returnsItemListTitle: getTranslation(
        isTrack ? "track_app_choose_items_title" : "choose_items_title",
      ),
      returnsItemListTitleDirty: getTranslation(
        isTrack ? "track_app_choose_items_title" : "choose_items_title",
      ),
      returnsItemListIneligibleTitle: getTranslation(
        isTrack ? "track_app_choose_items_title" : "choose_items_none_eligible",
      ),
      returnsItemListReturnInitiatedTitle: getTranslation(
        "return_status_simplified_to_consumer_initiated",
      ),
      returnsItemListPendingpacking_slip_and_shipping_labelLink: getTranslation(
        "choose_items_rma_action_reprint_labels",
      ),
      returnsItemListPendingqr_codeLink: getTranslation(
        "choose_items_rma_action_reprint_qr_code",
      ),
      returnsItemListPendingCancelReturnLink: getTranslation(
        "choose_items_rma_action_cancel_return",
      ),
      returnsItemListIneligibleDescription: getTranslation(
        "choose_items_return_not_eligible_for_return",
      ),
      returnsItemListSubmit: getTranslation("choose_items_next_button_label"),
      returnsItemListItemInfoskuLabel:
        getTranslation("choose_items_sku_label") + ": ",
      returnsItemListItemInfoqtyLabel:
        getTranslation("choose_items_quantity_label") + ": ",
      returnsItemListItemInfopriceLabel:
        getTranslation("choose_items_price_label") + ": ",
      returnsItemListItemInforeasonLabel:
        getTranslation("choose_items_reason_label") + ": ",
      returnsItemListItemTagReturning: getTranslation(
        "choose_items_tag_returning",
      ),
      returnsItemListItemTagExchanging: getTranslation(
        "choose_items_tag_exchanging",
      ),
      returnsItemListItemTagExchangingForCredit: getTranslation(
        "choose_items_tag_exchanging_for_credit",
      ),
      returnsLineItemGroupIneligibleDescription: getTranslation(
        "choose_items_group_ineligible_description",
      ),
      returnsReasonsItemInfoQuantitySelectPlaceholder: getTranslation(
        "choose_items_select_quantity_placeholder",
      ),
      returnsReasonsTypeTitle: getTranslation("choose_items_select_type_title"),
      returnsReasonsTypeReturn: getTranslation(
        state.guestReturn
          ? "choose_items_return_item_button_label_gift"
          : "choose_items_return_item_button_label",
      ),
      returnsReasonsTypeExchange: getTranslation(
        "choose_items_exchange_item_button_label",
      ),
      returnsReasonsListTitle: getTranslation(
        "choose_items_select_reason_prompt",
      ),
      returnsReasonsListTitleSecondary: getTranslation(
        "choose_items_select_subreason_prompt",
      ),
      returnsReasonsQuantityTitle: getTranslation(
        isExchanging
          ? "choose_items_select_quantity_prompt_exchange"
          : "choose_items_select_quantity_prompt",
      ),
      returnsReasonsPicturesTitle: getTranslation(
        "choose_items_upload_pictures_title",
      ),
      returnsReasonsPicturesUploadButton: getTranslation(
        "choose_items_upload_add_photos",
      ),
      returnsReasonsPicturesUploadDescription: getTranslation(
        "choose_items_upload_pictures_error_too_many",
      ),
      returnsReasonsPicturesUploadErrorMaxSize: getTranslation(
        "choose_items_upload_pictures_error_file_size",
      ),
      returnsReasonsPicturesUploadErrorMaxCount: getTranslation(
        "choose_items_upload_pictures_error_too_many",
      ),
      returnsReasonsCommentsTitle: getTranslation("choose_items_comment_title"), // TODO: `choose_items_comment_helper_text` seems better?
      returnsReasonsCommentsPlaceholder: getTranslation(
        "choose_items_comment_optional_placeholder",
      ),
      returnsReasonsCommentsPlaceholderRequired: getTranslation(
        "choose_items_comment_required_placeholder",
      ),
      returnsReasonsEditTitle: getTranslation("choose_items_remove_item_title"),
      returnsReasonsEditRemoveLabel: getTranslation(
        "choose_items_remove_item_confirm",
      ),
      returnsReasonsEditContinueLabel: getTranslation(
        isExchanging
          ? "choose_items_remove_item_cancel_exchange"
          : "choose_items_remove_item_cancel",
      ),
      returnsReasonsExchangeTitle: getTranslation(
        "choose_items_choose_exchange_variant_helper_text",
      ),
      returnsReasonsExchangeUnavailable: getTranslation(
        "choose_items_exchange_wizard_unavailable_title",
      ),
      returnsReasonsExchangeUpsellTitle: getTranslation(
        "choose_items_choose_exchange_variant_title_upsell",
      ),
      returnsReasonsExchangeUpsellNoThanks: getTranslation(
        "choose_items_choose_exchange_variant_skip_upsell",
      ),
      returnsReasonsShopNowDescription: getTranslation(
        "choose_items_choose_exchange_variant_shopnow_action_description",
      ),
      returnsReasonsShopNowLink: getTranslation(
        "choose_items_choose_exchange_variant_shopnow_action_link",
      ),
      returnsSwatchesListSeeMore: getTranslation(
        "choose_items_choose_exchange_variant_see_more",
      ),
      returnsSwatchesListSeeLess: getTranslation(
        "choose_items_choose_exchange_variant_see_less",
      ),
      returnsActionsNext: getTranslation("next"),
      returnsActionsBack: getTranslation("back"),
      returnsActionsCancel: getTranslation("cancel"),
      returnsActionsSubmit: getTranslation("submit"),

      // returnsItemListEligibleDescription: "These items can be returned until {{returnDate}}",
      // returnsItemListPendingpacking_slipLink: 'Reprint Packing Slip',
      // returnsItemListPendingshipping_labelLink: 'Reprint Shipping Label',
      // returnsItemListItemInfoTitle: '{{name}}',
      // returnsItemListItemInfocolorLabel: 'Color: ',
      // returnsItemListItemInfosizeLabel: 'Size: ',
      // returnsItemListItemInfoqtyTransactionSeparator: ' -> ',
      // returnsItemListNoItemError: 'No valid items in this order to be returned.',
      returnsItemListBannererror: "",
      // returnsItemListBannersuccess: 'Your return was successfully cancelled. ',
      // returnsReasonsItemInfoTitle: '{{name}}',
      // returnsReasonsItemInfoColor: 'Color: {{color}}',
      // returnsReasonsItemInfoColorLabel: 'Color:',
      // returnsReasonsItemInfoSize: 'Size: {{size}}',
      // returnsReasonsItemInfoSizeLabel: 'Size:',
      // returnsReasonsItemInfoQuantity: 'Quantity: {{quantity}}',
      // returnsReasonsItemInfoQuantityLabel: 'Quantity:',
      // returnsReasonsNoReasonsError: 'No return reasons available.',

      returnsPudoListTitle: getTranslation("choose_method_title"), // choose_method_substep_concierge_title, choose_method_substep_in_store_title?
      returnsPudoListFeeLabelFree: config.hideReturnMethodPrice
        ? ""
        : getTranslation("FREE"),
      returnsPudoListSwitchCategory: getTranslation(
        "choose_method_select_different_method",
      ),
      returnsPudoLocationsPostalcodeTitle: getTranslation(
        "choose_method_substep_in_store_form_find_stores_near",
      ),
      returnsPudoLocationsPostalcodeSearch: getTranslation("search"),
      returnsPudoLocationsPostalcodeCancel: getTranslation("cancel"),
      returnsPudoLocationsDistanceNounit: "Directions",
      returnsPudoLocationsSkipLink: getTranslation(
        "choose_method_substep_concierge_skip",
      ),
      returnsPudoSubmit: getTranslation("submit"),
      returnsPudoErrorNopudooptions: getTranslation(
        "choose_method_substep_no_locations_found",
      ),

      // returnsPudoListItem*Title, Subtitle // static translations for different pudo options, since we have dynamic return method translations, we don't need to set
      returnsPudoListItemmailFree: getTranslation(
        "choose_method_card_free_mail_it",
      ),
      returnsPudoListItemconciergeFree: getTranslation(
        "choose_method_card_free_mail_it",
      ),
      returnsPudoListItemprinterlessmailFree: getTranslation(
        "choose_method_card_free_mail_it",
      ),
      returnsPudoListItemboxlessFree: getTranslation(
        "choose_method_card_free_mail_it",
      ),
      returnsPudoListItempickupFree: getTranslation(
        "choose_method_card_free_mail_it",
      ),
      returnsPudoListItemstoreFree: getTranslation(
        "choose_method_card_free_in_store",
      ),
      returnsPudoListItemselfFree: getTranslation(
        "choose_method_card_free_ship_on_your_own",
      ),
      returnsPudoListItem: getTranslation("choose_method_card_keep_item"),
      returnsPudoMultiLabelsTitle: getTranslation(
        "choose_method_multi_labels_title",
      ),
      returnsPudoMultiLabelsDescription: getTranslation(
        "choose_method_multi_labels_copy",
      ),
      returnsPudoMultiLabelsInputPlaceholder: getTranslation(
        "choose_method_multi_labels_input_placeholder",
      ),
      returnsPudoMultiLabelsRemarks: getTranslation(
        "choose_method_multi_labels_disclaimer",
      ),

      // returnsPudoListShowall: "< Back to All Methods",
      // returnsPudoListFeeLabellabelfee: "Label Fee",
      // returnsPudoListFeeLabelshippingfee: "Shipping Fee",
      // returnsPudoListFeeLabelpickupfee: "Pickup Fee",
      // returnsPudoLocationsDistanceUnitmiles: "mi",
      // returnsPudoLocationsDistanceUnitkilometers: "km",
      // returnsPudoLocationsSortedbyOptionrecommended: "Recommended",
      // returnsPudoLocationsSortedbyOptiondistance: "Distance",
      // returnsPudoLocationsSortedbyTitle: "Sort By",
      // returnsPudoPickupTimeslotTitle: "We can pick up your return",
      // returnsPudoPickupTimeslotDescription:
      //   "The pickup window will be from {{earliestHour}} to {{latestHour}}.  <br/> Hand your package directly to courier or leave your package in a safe place for pickup.",
      // returnsPudoPickupLocationTitle: "Pickup Location",
      // returnsPudoPickupLocationDescription:
      //   "Returns can only be picked up from the original delivery address.",
      // returnsPudoPickupInstructionsToggle: "Add Pickup Instructions",
      // returnsPudoPickupFeeTitle: "{{pickupFee}} Pickup Convenience Fee",
      // returnsPudoPickupFeeDescription:
      //   "This is a non-refundable fee in addition to any return shipping fees deducted from your return. <br/> Once the pickup is scheduled, it cannot be canceled.",
      // returnsPudoPickupInstructionsPlaceholder:
      //   "Take the lobby elevator up to the 3rd floor.",
      // returnsPudoPickupInstructionsHandoffTitle:
      //   "How will the courier pick up your package?",
      // returnsPudoErrorNotimeslots:
      //   "No available pickup timeslots. Please go back and choose a different return method.",

      returnsRefundMethodsTitle: getTranslation("choose_refund_method_title"),
      returnsRefundMethodsDisclaimer:
        state.selectedReturnCreditMethod?.id === RETURN_CREDIT_METHOD_RESHOP
          ? getTranslation("choose_refund_method_reshop_disclaimer")
          : "",
      returnsRefundMethodsOptionIncentiveChip: getTranslation(
        "choose_refund_method_incentive_amount",
      ),

      returnsReviewError: getTranslation(
        "return_review_error_contact_retailer",
      ),
      returnsReviewHeader: getTranslation("return_review_title_new"),
      returnsReviewSubHeader: getTranslation("return_review_title"),
      returnsReviewSubHeaderExchange: getTranslation(
        "return_review_title_exchange",
      ),
      returnsReviewSubHeaderReturnAndExchange: getTranslation(
        "return_review_title_return_and_exchange",
      ),
      returnsReviewTotalsSubtotal: getTranslation("return_review_subtotal"),
      returnsReviewTotalsShippingFee: getTranslation(
        "return_review_shipping_fee",
      ),
      returnsReviewTotalsEstimatedRefund:
        getTranslation("return_review_estimated_refund") + "*",
      returnsReviewTotalsEstimatedCharge: getTranslation(
        "return_review_charge_total",
      ),
      returnsReviewTotalsHelpText:
        state.selectedReturnCreditMethod?.id === RETURN_CREDIT_METHOD_RESHOP
          ? "*" + getTranslation("return_review_disclaimer_reshop")
          : "*" + getTranslation("return_review_disclaimer"),
      returnsReviewDropOffLocationHeader: getTranslation(
        "return_review_drop_off_location",
      ),
      returnsReviewRefundTo_giftCard: interpolate(
        getTranslation("return_review_gift_card"),
        { shop_name: config.shopName },
      ),
      returnsReviewRefundTo_originalPayment: getTranslation(
        "return_review_original_payment",
      ),
      returnsReviewRefundTo_reshop: getTranslation("return_review_reshop"),
      returnsReviewReshopTitle: getTranslation(
        "return_review_reshop_options_title",
      ),
      returnsReviewReshopCheckboxLabel: getTranslation(
        "return_review_reshop_options_sms_updates",
      ),
      returnsReviewReshopPhoneInputLabel: getTranslation(
        "return_review_reshop_options_customer_phone",
      ),
      returnsReviewReshopDisclaimer: getTranslation(
        "return_review_reshop_options_disclaimer",
      ),
      returnsReviewSubmittingReturn: getTranslation("return_review_submitting"),
      returnsReviewSubmittingError: getTranslation(
        "return_review_submit_error",
      ),

      ...confirmationTranslationsFactory("mail"),
      ...confirmationTranslationsFactory("store"),
      ...confirmationTranslationsFactory("ship_on_your_own"),
      ...confirmationTranslationsFactory("home_pickup"),
      ...confirmationTranslationsFactory("keep_the_item"),
      ...confirmationTranslationsFactory("boxless"),
      returnsConfirmationreshopStepTitle: getTranslation(
        "return_confirmation_instructions_reshop_step_title",
      ),
      returnsConfirmationreshopStepDetails: getTranslation(
        "return_confirmation_instructions_reshop_step_subtitle",
      ),
      returnsConfirmationreshopStepAppStoreLink: getTranslation(
        "return_confirmation_instructions_reshop_step_app_store_link",
      ),
      returnsConfirmationreshopStepAppStoreLinkText: getTranslation(
        "return_confirmation_instructions_reshop_step_app_store_link_text",
      ),
      returnsConfirmationreshopStepGooglePlayLink: getTranslation(
        "return_confirmation_instructions_reshop_step_google_play_link",
      ),
      returnsConfirmationreshopStepGooglePlayLinkText: getTranslation(
        "return_confirmation_instructions_reshop_step_google_play_link_text",
      ),

      returnsReviewPreferencesCheckboxLabel: getTranslation(
        "return_review_preferences_checkbox_label",
      ),
      returnsReviewPreferencesInputLabel: getTranslation(
        "return_review_preferences_input_label",
      ),
      returnsPreferencesStatusDesc: getTranslation(
        "return_user_preferences_status_desc",
      ),
      returnsPreferencesStatusDesc_error: getTranslation(
        "return_user_preferences_status_desc_error",
      ),
      returnsPreferencesStatusDesc_pending: getTranslation(
        "return_user_preferences_status_desc_pending",
      ),
      returnsPreferencesStatusLinkText: getTranslation(
        "return_user_preferences_status_lint_text",
      ),
      returnsPreferencesStatusLinkText_error: getTranslation(
        "return_user_preferences_status_lint_text_error",
      ),
      returnsPreferencesStatusLinkText_pending: getTranslation(
        "return_user_preferences_status_lint_text_pending",
      ),

      returnsStepsGeneralError: getTranslation("general_loading_error"),
    };

    // nth use `{{var}}` as translation variable, we use `%{var}`
    translations = toNthTemplateConfig(translations, {
      // eg.
      // returnsItemListTitle: { customerName: "customer" },
    });
    return translations;
  }, [
    window?.nvo_config?.translations,
    state.guestReturn,
    state.selectingItem?.type,
    state.refundTotal,
    state.submitReturnResultCode,
    state.selectedReturnCreditMethod,
  ]);

  const theme = useMemo(() => {
    const branding = {
      primary_font: buildSansFontFamily(
        getBranding("primary_font") || "Oswald",
      ),
      secondary_font: buildSansFontFamily(
        getBranding("secondary_font") || "Lato",
      ),
      base_body_text_size: getBranding("base_body_text_size") ?? 14,
      border_radius: Math.min(getBranding("border_radius"), 24), // max. border radius is 24 only
      brand_primary: `#${getBranding("brand_primary") || "000000"}`,
      brand_secondary: `#${getBranding("brand_secondary") || "000000"}`,
      body_text: `#${getBranding("body_text") || "000000"}`,
      body_background: `#${getBranding("body_background") || "FFFFFF"}`,
      error_color: `#${getBranding("error_color") || "E63935"}`,
      error_text_color: `#${getBranding("error_text_color") || "FFFFFF"}`,
      is_link_underlined: getBranding("is_link_underlined") ?? true,
      is_link_all_caps: getBranding("is_link_all_caps") ?? false,
      root_margin_top: 0,
      root_margin_bottom: 0,
      step_delimiter_position: 90,
      fixed_app_bar: true,
    };
    branding.brand_primary_darker = darken(branding.brand_primary, 0.1);
    branding.brand_primary_lighter = lighten(branding.brand_primary, 0.95);
    branding.brand_secondary_darker = darken(branding.brand_secondary, 0.1);
    branding.brand_secondary_lighter = lighten(branding.brand_secondary, 0.95);

    const BG_WHITE = "#fff";
    const DISABLED_COLOR = "#ccc";
    const BORDER_COLOR = "#ccc";

    const defaultTextStyle = {
      fontFamily: branding.secondary_font,
      fontSize: branding.base_body_text_size,
      color: branding.body_text,
    };
    const headerTextStyle = {
      ...defaultTextStyle,
      fontFamily: branding.primary_font,
      fontWeight: 700,
    };
    const errorTextStyle = {
      color: branding.error_color,
    };
    const emphasisTextStyle = {
      fontWeight: "bold",
    };
    const linkStyle = {
      ...defaultTextStyle,
      color: branding.brand_primary,
      // fontWeight: 700, // TODO: uncomment this nth style
      // textDecoration: "none", // TODO: uncomment this nth style
    };
    const buttonStyle = {
      ...defaultTextStyle,
      fontFamily: branding.primary_font,
      fontWeight: 700,
      textTransform: "uppercase", // TODO: remove this SHOPZ style
      backgroundColor: branding.brand_primary,
      color: "#fff",
      borderWidth: 0,
      borderStyle: "none",
      borderColor: "transparent",
      borderRadius: branding.border_radius,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 20,
      paddingRight: 20,
    };
    const buttonOutlineStyle = {
      ...defaultTextStyle,
      fontFamily: branding.primary_font,
      fontWeight: 700,
      textTransform: "uppercase", // TODO: remove this SHOPZ style
      backgroundColor: BG_WHITE,
      color: branding.brand_primary,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: branding.brand_primary,
      borderRadius: branding.border_radius,
      paddingTop: 7,
      paddingBottom: 7,
      paddingLeft: 19,
      paddingRight: 19,
    };
    const iconStyle = {
      color: branding.brand_secondary,
      fill: branding.brand_secondary,
    };
    const panelStyle = {
      backgroundColor: BG_WHITE,
      borderWidth: 1,
      borderStyle: "solid",
      borderTopColor: BORDER_COLOR,
      borderBottomColor: BORDER_COLOR,
      borderLeftColor: BORDER_COLOR,
      borderRightColor: BORDER_COLOR,
      borderRadius: 4,
      marginBottom: 8,
    };
    const panelHoverStyle = {
      ...omit(["backgroundColor"], panelStyle),
      borderTopColor: branding.brand_primary, // TODO: map to secondary color when all others component is using nth
      borderBottomColor: branding.brand_primary,
      borderLeftColor: branding.brand_primary,
      borderRightColor: branding.brand_primary,
    };
    const panelActiveStyle = {
      ...panelStyle,
      borderTopColor: branding.brand_primary, // TODO: map to secondary color when all others component is using nth
      borderBottomColor: branding.brand_primary,
      borderLeftColor: branding.brand_primary,
      borderRightColor: branding.brand_primary,
      borderWidth: 2,
      backgroundColor: branding.brand_primary_lighter,
    };
    const panelBodyStyle = {
      padding: 24,
    };
    const inputStyle = {
      ...defaultTextStyle,
      backgroundColor: BG_WHITE,
      borderWidth: 1,
      borderStyle: "solid",
    };
    const inputFocusStyle = {
      ...inputStyle,
      borderColor: branding.brand_primary, // TODO: map to secondary color when all others component is using nth
      outlineColor: branding.brand_primary,
    };
    const inputErrorStyle = {
      ...inputStyle,
      borderColor: branding.error_color,
      outlineColor: branding.error_color,
    };

    // TODO: map theme
    const theme = {
      documentbodyNoneNone: {
        ...defaultTextStyle,
        backgroundColor: BG_WHITE,
      },
      bodytextDefaultNone: { ...defaultTextStyle },
      bodytextEmphasisNone: { ...defaultTextStyle, ...emphasisTextStyle },
      smalltextDefaultNone: {
        ...defaultTextStyle,
        ...computeFontStyle("caption", branding.base_body_text_size),
      },
      smalltextEmphasisNone: {
        ...defaultTextStyle,
        ...emphasisTextStyle,
        ...computeFontStyle("caption", branding.base_body_text_size),
      },
      captiontextDefaultNone: {
        ...defaultTextStyle,
        ...computeFontStyle("caption", branding.base_body_text_size),
      },
      captiontextErrorNone: {
        ...defaultTextStyle,
        ...errorTextStyle,
        ...computeFontStyle("caption", branding.base_body_text_size),
      },
      headersHugeNone: {
        ...headerTextStyle,
        ...computeFontStyle("h1", branding.base_body_text_size),
      },
      headersBigNone: {
        ...headerTextStyle,
        ...computeFontStyle("h1", branding.base_body_text_size), // TODO: review this later, we need to match existing font size for now
      },
      headersSemibigNone: {
        ...headerTextStyle,
        ...computeFontStyle("h1", branding.base_body_text_size), // TODO: review this later, we need to match existing font size for now
      },
      headersMediumNone: {
        ...headerTextStyle,
        ...computeFontStyle("h4", branding.base_body_text_size),
      },
      headersSmallNone: {
        ...headerTextStyle,
        ...computeFontStyle("h5", branding.base_body_text_size),
      },
      buttonsDismissNone: { ...defaultTextStyle },
      buttonsPrimaryDefault: { ...buttonStyle },
      buttonsPrimaryDisabled: {
        ...buttonStyle,
        backgroundColor: DISABLED_COLOR,
      },
      buttonsPrimaryHover: {
        ...buttonStyle,
        backgroundColor: branding.brand_primary_darker,
      },
      buttonsSecondaryDefault: { ...buttonOutlineStyle },
      buttonsSecondaryDisabled: {
        ...buttonOutlineStyle,
        color: DISABLED_COLOR,
        borderColor: DISABLED_COLOR,
      },
      buttonsSecondaryHover: {
        ...buttonOutlineStyle,
        color: branding.brand_primary_darker,
        borderColor: branding.brand_primary_darker,
      },
      iconsPrimaryNone: { ...iconStyle },
      iconsSecondaryNone: {},
      spinnerPrimaryNone: { ...iconStyle },
      linksNoneDefault: { ...linkStyle },
      linksNoneHover: { ...linkStyle },
      linksNoneVisited: { ...linkStyle },
      // linksLegalDefault: {},
      // linksLegalHover: {},
      // linksTrackingDefault: {},
      // linksTrackingHover: {},
      footertextNoneNone: { ...defaultTextStyle },
      // narvarfooterNoneNone: {},
      // iconsFooterNone: {},
      alertNoneNone: { fontFamily: branding.secondary_font },
      panelbodyNoneNone: { ...panelBodyStyle },
      panelcontainerNoneNone: { ...panelStyle },
      panelcontainerNoneHover: { ...panelHoverStyle },
      panelcontainerNoneActive: { ...panelActiveStyle },
      panelfooterNoneNone: {},
      panelheaderNoneNone: {},
      selecteditemNoneNone: { ...panelActiveStyle },

      // inputlabelNoneDisabled: {},
      // inputlabelNoneNone: {},
      // inputratingstarsNoneDefault: {},
      // inputratingstarsNoneSelected: {},
      inputtextareasNoneDisabled: {},
      inputtextareasNoneFocus: { ...inputFocusStyle },
      inputtextareasNoneNone: { ...inputStyle },
      inputtextinputsNoneDisabled: {},
      inputtextinputsNoneError: { ...inputErrorStyle },
      inputtextinputsNoneFocus: { ...inputFocusStyle },
      inputtextinputsNoneNone: { ...inputStyle },
      // selectNoneNone: {},
      // inputcheckboxesNoneDefault: {},
      // inputcheckboxesNoneSelected: {},
      // telephoneinputNoneNone: {},
      // floatingpillNoneNone: {},
      // imgPrimaryNone: {},
      // calendarInputDefault: {},
      // calendarInputDisabled: {},
      // calendarInputSelected: {},
      // calendarStaticDefault: {},
      // calendarStaticSelected: {},
      // chatbubbleImageNone: {},
      // chatbubbleMeNone: {},
      // chatbubbleThemNone: {},

      // assetpanelcontainerNoneNone: {},
      // dividerPrimaryNone: {},
      // sectiondividerPrimaryNone: {},
      // gradientNoneNone: {},
      // eddcalculatorcontainerNoneNone: {},
      // productrecspanelcontainerNoneNone: {},
      // productrecsarrowcontainerNextNone: {},
      // productrecsarrowcontainerPrevNone: {},
      // productrecsarrowNoneDefault: {},
      // productrecsarrowNoneDisabled: {},
      // productrecsarrowNoneHover: {},
      // productrecscarouseltitleNoneNone: {},
      // productrecsdotcontainerNoneNone: {},
      // productrecsdotNoneActive: {},
      // productrecsdotNoneDefault: {},
      // productrecsdotNoneHover: {},
      // productrecsreccontainerNoneNone: {},
      // productrecsrecimageNoneNone: {},
      // productrecsrecpriceCentsNone: {},
      // productrecsrecpriceNoneNone: {},
      // productrecsrecpriceOldNone: {},
      // productrecsrecpricePriceNone: {},
      // productrecsrecpriceSaleNone: {},
      // productrecsrecpriceStrikethroughcentsNone: {},
      // productrecsrecpriceUsdNone: {},
      // productrecsrecratingcontainerNoneNone: {},
      // productrecsrecratingstarEmptyNone: {},
      // productrecsrecratingstarFullNone: {},
      // productrecsrectextBrandNone: {},
      // productrecsrectextNameNone: {},
      // productrecsrectextNoneNone: {},
      // productrecsrectextReviewsNone: {},
      // productrecsrectextStaticNone: {},
      // productrecsrectextStrikethroughNone: {},
      // progressbarmarkerDeliveredDefault: {},
      // progressbarmarkerEddDefault: {},
      // progressbarmarkerShippedAfter: {},
      // progressbarmarkerShippedBefore: {},

      // panelcontainerNoneNone: {
      //   backgroundColor: "#FFFFFF",
      //   borderBottomColor: "#CCCCCC",
      //   borderBottomStyle: "solid",
      //   borderBottomWidth: "1px",
      //   borderLeftColor: "#CCCCCC",
      //   borderLeftStyle: "solid",
      //   borderLeftWidth: "1px",
      //   borderRightColor: "#CCCCCC",
      //   borderRightStyle: "solid",
      //   borderRightWidth: "1px",
      //   borderTopColor: "#CCCCCC",
      //   borderTopStyle: "solid",
      //   borderTopWidth: "1px",
      //   marginBottom: "0.5rem",
      //   marginLeft: "0",
      //   marginRight: "0",
      //   marginTop: "0",
      // },
    };
    return theme;
  }, [window?.nvo_config?.branding]);

  const { language, country } = useConsumerSettings();

  const globalSettings = memoObjectByKeyValues({
    retailerName: config.shopName,
    defaultLanguage: language,
    defaultCountry: country,
    defaultVersionName: "",
    fallbackVersionName: "",
    returnsWorkflowActiveVersion: "",
    returnsWorkflowDefaultVersion: "",
    // dynamicTrackEnabled?: '',
    // valid_country_list?: Maybe<Array<Maybe<Country>>>;
    // smsSupportedCountries?: Maybe<Array<Maybe<SmsCountry>>>;
    // smsDefaultCountry?: Maybe<SmsCountry>;
    // orderLevelOptInEnabled?: Maybe<Scalars['Boolean']>;
    // retailerLevelOptInEnabled?: Maybe<Scalars['Boolean']>;
    // hipaaCompliant?: Maybe<Scalars['Boolean']>;
    // internationalDateFormatting?: Maybe<Scalars['Boolean']>;
    // giftSharingEnabled?: Maybe<Scalars['Boolean']>;
  });

  const localConfig = useMemo(
    () => ({
      queryTheme: () => theme,
      queryTranslations: () => translations,
      queryGlobalSettings: () => globalSettings,
    }),
    [theme, translations, globalSettings],
  );

  const handleAnalyticsError = useCallback(error => {
    console.error(error);
  }, []);

  return (
    <ReturnsConfigProvider
      noflakeUrl={noflake.endpoint}
      handleError={handleAnalyticsError}
      lomaUrl={lomaUrl}
      returnsHeadlessUrl=""
      moniker={config.shopDomain}
      localeLanguage={language}
      localeCountry={country}
      localConfig={localConfig}
      includeCurrency={config.isIncludeCurrency}
      fitItemThumbnail={config.isCropItemThumbnail}>
      <ViewportConfigProvider config={viewportConfig}>
        {children}
      </ViewportConfigProvider>
    </ReturnsConfigProvider>
  );
}

export const useNthReturnsActions = featureFlaggedHook(
  config.isNthUi || config.isNthLoginUi,
  {},
)(() => {
  const nthReturnsActions = useReturns();
  return nthReturnsActions;
});
