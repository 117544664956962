import React, { useState, useEffect, useMemo, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import {
  compose,
  defaultTo,
  find,
  fromPairs,
  get,
  map,
  toPairs,
} from "lodash/fp";

import { config } from "../../../config";
import useEvenExchangeProduct from "../../../data/evenExchangeProduct";
import { makeRootStyles } from "../../../theme/styles";
import ExchangeItemDetails from "./ExchangeItemDetails";
import {
  buildVariantInfoMap,
  buildVariants,
  buildVariantsOptionsFallback,
} from "../../../modules/items";
import locale from "../../../../shared/modules/lang";

export const useStyles = makeRootStyles(
  theme => ({
    unavailableContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
  { name: "N0ExchangeItemWizard" },
);

const ExchangeItemWizard = ({
  exchangeItem,
  lineItem,
  onConfirm,
  onChange,
  orderFetchData,
}) => {
  const classes = useStyles();
  const orderPresentmentCurrency = get(
    "order.presentmentCurrency",
    orderFetchData,
  );

  const productId = lineItem.productId;
  const productVariantName = lineItem.productTitle;
  const { result, status } = useEvenExchangeProduct({
    query: productVariantName,
    price: lineItem.priceAmount,
    compareAtPrice: lineItem.compareAtPriceAmount,
    productId: productId || "",
    variantId: lineItem.variantId,
    currency: orderPresentmentCurrency,
    locale: locale,
    displayPrice: lineItem.displayPrice,
  });

  const isUnavailableForSale = !status.loading && !result;

  const item = useMemo(
    () => ({
      ...lineItem,
      variantInfoMap: buildVariantInfoMap(lineItem.variantInfo),
    }),
    [lineItem],
  );

  const selectedItem = result;

  const variants = useMemo(() => (result ? buildVariants(result) : [item]), [
    item,
    result,
  ]);
  const variantsOptions = useMemo(
    () =>
      selectedItem ? selectedItem.options : buildVariantsOptionsFallback(item),
    [item, selectedItem],
  );

  const onChangeHandler = useCallback(
    variants => {
      const variantsPairs = toPairs(variants);
      const filter = compose(
        fromPairs,
        map(([key, value]) =>
          compose(
            pos => [`option${pos}`, value],
            get("position"),
            defaultTo({}),
            find({ name: key }),
          )(selectedItem.options),
        ),
      )(variantsPairs);
      const selectedVariants = find(filter)(selectedItem.variantInfo || []);
      if (selectedVariants) {
        onChange({
          ...selectedVariants,
          name: selectedVariants.displayName,
          productImageAltTxt: selectedVariants.imageAlt,
          productImageUrl: selectedVariants.imageSrc,
          variantInfo: map(([name, value]) => ({ name, value }))(variantsPairs),
        });
      }
    },
    [selectedItem],
  );

  if (isUnavailableForSale) {
    return (
      <div className={classes.unavailableContent}>
        <Typography variant="h3" align="center">
          {config.translations.choose_items_exchange_wizard_unavailable_title}
        </Typography>
      </div>
    );
  }

  return (
    <ExchangeItemDetails
      item={exchangeItem || item}
      loading={status.loading}
      onConfirm={onConfirm}
      onChange={onChangeHandler}
      variants={variants}
      variantsOptions={variantsOptions}
      selectedItem={exchangeItem}
    />
  );
};
export default ExchangeItemWizard;
