import React, { Fragment } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";

import Select from "../../../components/select";
import { config } from "../../../config";
import { makeRootStyles } from "../../../theme/styles";
import { getBorderSelected } from "../utils";

const BORDER_WIDTH_SELECTED = 3;
const ACTION_HEIGHT_SPACING = 6;

export const useStyles = makeRootStyles(
  theme => ({
    disabled: {},
    action: {
      border: getBorderSelected(theme),
      boxSizing: "border-box",
      backgroundColor: theme.palette.common.white,
    },
    menuList: {
      borderTop: getBorderSelected(theme),
      borderRight: getBorderSelected(theme),
      borderLeft: getBorderSelected(theme),
      backgroundColor: theme.palette.common.white,
      maxHeight: theme.spacing(43), // about 350px on desktop. ItemCollapsible is used on smaller devices
      overflowY: "auto",
    },
    menuListItemRoot: {
      minHeight:
        theme.spacing(ACTION_HEIGHT_SPACING) - BORDER_WIDTH_SELECTED * 2,
    },
    menuListItemGutters: {
      paddingLeft: theme.spacing(3) - BORDER_WIDTH_SELECTED,
      paddingRight: theme.spacing(3) - BORDER_WIDTH_SELECTED,
      whiteSpace: "normal",
    },
    itemLabel: {
      color: theme.palette.primary.main,
      "&$disabled": {
        opacity: 1,
      },
    },
    actionsLarge: {
      height: theme.spacing(ACTION_HEIGHT_SPACING),
      width: `calc(50% - ${theme.spacing(0.5)}px)`,
    },
    actionsSmall: {
      height: theme.spacing(ACTION_HEIGHT_SPACING) - BORDER_WIDTH_SELECTED,
      borderTopWidth: 0,
    },
  }),
  { name: "N0ItemSelectors" },
);

const ItemSelectors = ({
  childReturnReasonsOptions,
  onChangeChildReason,
  onChangeQuantity,
  onChangeReason,
  openChildReasonsSelect,
  openQuantitySelect,
  openReasonsSelect,
  quantityOptions,
  returnReasonsOptions,
  setOpenChildReasonsSelect,
  setOpenQuantitySelect,
  setOpenReasonsSelect,
  state,
  variant,
}) => {
  const classes = useStyles();

  const toggleQuantitySelect = () => setOpenQuantitySelect(current => !current);

  const toggleReasonsSelect = () => setOpenReasonsSelect(current => !current);

  const toggleChildReasonsSelect = () =>
    setOpenChildReasonsSelect(current => !current);

  const showChildReason = state.returnReason && childReturnReasonsOptions;
  const showQuantity =
    state.returnReason &&
    (!showChildReason || state.childReturnReason?.returnReason) &&
    quantityOptions;

  const selectClasses = {
    root: clsx(classes.action, {
      [classes.actionsLarge]: variant === "large",
      [classes.actionsSmall]: variant === "small",
    }),
    menuList: classes.menuList,
  };

  const selectItemClasses = {
    root: classes.menuListItemRoot,
    gutters: classes.menuListItemGutters,
  };

  return (
    <Fragment>
      <Select
        className={classes.itemLabel}
        classes={selectClasses}
        onChange={onChangeReason}
        onClose={toggleReasonsSelect}
        onOpen={toggleReasonsSelect}
        open={openReasonsSelect}
        value={state.returnReason}>
        <MenuItem
          className={classes.itemLabel}
          classes={selectItemClasses}
          color="primary"
          value={null}
          disabled>
          {config.translations.choose_items_select_reason_prompt}
        </MenuItem>
        {returnReasonsOptions.map(option => (
          <MenuItem
            key={option.value}
            classes={selectItemClasses}
            color="primary"
            value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {showChildReason && (
        <>
          <Select
            className={classes.itemLabel}
            classes={selectClasses}
            onChange={onChangeChildReason}
            onClose={toggleChildReasonsSelect}
            onOpen={toggleChildReasonsSelect}
            open={openChildReasonsSelect}
            value={state.childReturnReason.returnReason}>
            <MenuItem
              className={classes.itemLabel}
              classes={selectItemClasses}
              color="primary"
              value={null}
              disabled>
              {config.translations.choose_items_select_subreason_prompt}
            </MenuItem>
            {childReturnReasonsOptions.map(option => (
              <MenuItem
                key={option.value}
                classes={selectItemClasses}
                color="primary"
                value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {showQuantity && (
        <Select
          className={classes.itemLabel}
          classes={selectClasses}
          onChange={onChangeQuantity}
          onClose={toggleQuantitySelect}
          onOpen={toggleQuantitySelect}
          open={openQuantitySelect}
          value={state.quantity}>
          <MenuItem
            className={classes.itemLabel}
            classes={selectItemClasses}
            color="primary"
            value={null}
            disabled>
            {config.translations.choose_items_select_quantity_prompt}
          </MenuItem>
          {quantityOptions.map(option => (
            <MenuItem
              key={option.value}
              classes={selectItemClasses}
              color="primary"
              value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Fragment>
  );
};
export default ItemSelectors;
