import React, {
  useMemo,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

import { Swiper as ReactSwiper, SwiperSlide } from "swiper/react"; // eslint-disable-line import/no-unresolved
import { Keyboard, FreeMode, Navigation } from "swiper";
import "swiper/css"; // eslint-disable-line import/no-unresolved
import "swiper/css/free-mode"; // eslint-disable-line import/no-unresolved
import "swiper/css/keyboard"; // eslint-disable-line import/no-unresolved
import "swiper/css/navigation"; // eslint-disable-line import/no-unresolved

import { makeRootStyles } from "../../theme/styles";

const useStyles = makeRootStyles(
  theme => {
    const iconSize = theme.spacing(5);
    const navPadding = theme.spacing(1);
    const containerNavPadding = iconSize + navPadding * 2;

    return {
      root: {
        [theme.breakpoints.ie11()]: { height: theme.spacing(68) },
        [theme.breakpoints.edge()]: { height: theme.spacing(68) },
        position: "relative",
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        [theme.breakpoints.up("sm")]: {
          marginLeft: -theme.spacing(3),
          marginRight: -theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },

        "&$nav": {
          [theme.breakpoints.up("md")]: {
            paddingLeft: containerNavPadding,
            paddingRight: containerNavPadding,
          },
        },

        "& .swiper": {
          overflow: "visible",
        },
      },
      nav: {},

      whiteOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "90%",
        right: 0,
        pointerEvents: "none",
        zIndex: 2,
        background:
          "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",

        "&$begin": {
          left: 0,
          right: "90%",
          background:
            "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          [theme.breakpoints.up("md")]: {
            right: `calc(100% - ${containerNavPadding + theme.spacing(3)}px)`,
          },
        },
      },
      begin: {},
      end: {},
      navButton: {
        display: "inline-block",
        position: "absolute",
        top: theme.spacing(20),
        zIndex: theme.zIndex.appBar,
        width: iconSize,
        height: iconSize,
        borderRadius: iconSize,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        cursor: "pointer",
        textAlign: "center",
        "&.swiper-button-disabled": {
          cursor: "default",
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled,
          display: "none",
        },
        [theme.breakpoints.up("sm")]: {
          top: theme.spacing(30),
        },
        [theme.breakpoints.up("md")]: {
          top: theme.spacing(20),
        },
      },
      navPrev: {
        left: navPadding,
      },
      navNext: {
        right: navPadding,
      },
    };
  },
  {
    name: "N0Swiper",
  },
);

const Swiper = forwardRef(
  ({ className, children, slidesPerView, spacing, nav, ...other }, ref) => {
    const classes = useStyles();

    const slides = children instanceof Array ? children : [children];
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const swiperRef = useRef(null);
    const navPrevRef = useRef(null);
    const navNextRef = useRef(null);
    const theme = useTheme();
    const spaceBetween = useMemo(() => theme.spacing(spacing), [
      spacing,
      theme,
    ]);
    const isSingleSlide = slidesPerView < 2;

    // on swiper init
    const handleSwiper = useCallback(function handleSwiper(ref) {
      swiperRef.current = ref;
    }, []);

    function slideToIndex(index) {
      swiperRef.current.slideTo(index);
    }

    const handleSlideChange = useCallback(() => {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        slideTo: index => {
          swiperRef.current.slideTo(index);
        },
      }),
      [],
    );

    return (
      <div className={clsx(classes.root, { [classes.nav]: nav })}>
        {nav && (
          <a
            className={clsx(classes.navButton, classes.navPrev)}
            ref={navPrevRef}>
            <NavigateBefore style={{ color: "#ffffff" }} size="large" />
          </a>
        )}
        {nav && (
          <a
            className={clsx(classes.navButton, classes.navNext)}
            ref={navNextRef}>
            <NavigateNext style={{ color: "#ffffff" }} size="large" />
          </a>
        )}

        {slidesPerView < slides.length && !isBeginning && (
          <div className={clsx(classes.whiteOverlay, classes.begin)}></div>
        )}
        {slidesPerView < slides.length && !isEnd && (
          <div className={clsx(classes.whiteOverlay, classes.end)}></div>
        )}
        <ReactSwiper
          modules={[Keyboard, FreeMode, Navigation]}
          freeMode={{
            sticky: true,
          }}
          grabCursor
          navigation={{
            nextEl: navNextRef.current,
            prevEl: navPrevRef.current,
          }}
          preventClicksPropagation
          shortSwipes
          keyboard={{
            enabled: true,
          }}
          centeredSlides={isSingleSlide}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          touchStartPreventDefault={false}
          onSwiper={handleSwiper}
          onSlideChange={handleSlideChange}
          onSliderMove={handleSlideChange}
          {...other}>
          {React.Children.map(slides, (node, index) => (
            <SwiperSlide
              onClickCapture={event => {
                slideToIndex(index);
              }}>
              {node}
            </SwiperSlide>
          ))}
        </ReactSwiper>
      </div>
    );
  },
);
Swiper.displayName = "Swiper";
Swiper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  slidesPerView: PropTypes.number,
  spacing: PropTypes.number,
  nav: PropTypes.bool,
};
Swiper.defaultProps = {
  spacing: 0,
  nav: false,
};
export default Swiper;
