import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { mergeIgnoreNil } from "../../shared/modules/object";
import {
  compose,
  toPairs,
  map,
  fromPairs,
  get,
  set,
  isNil,
  unset,
} from "lodash/fp";
import { useOrderData } from "../data/orderData";
import { config } from "../config";

export const ORDER_LOOKUP_STEP = "order lookup step";
export const CHOOSE_ITEMS_STEP = "choose items";
export const CHOOSE_METHOD_STEP = "choose method";
export const CHOOSE_METHOD_SUBSTEP = "choose method substep";
export const CONFIRM_MAILING_ADDRESS_STEP = "confirm mailing address step";
export const CHOOSE_SHOP_NOW_STEP = "choose shop now step";
export const CHOOSE_RETURN_CREDIT_METHOD_STEP =
  "choose return method credit step";
export const REVIEW_STEP = "review step";
export const CONFIRMATION_STEP = "confirmation step";

const STEPS = [
  ORDER_LOOKUP_STEP,
  CHOOSE_ITEMS_STEP,
  CHOOSE_METHOD_STEP,
  CHOOSE_METHOD_SUBSTEP,
  CONFIRM_MAILING_ADDRESS_STEP,
  CHOOSE_SHOP_NOW_STEP,
  CHOOSE_RETURN_CREDIT_METHOD_STEP,
  REVIEW_STEP,
  CONFIRMATION_STEP,
];

export const getStepIndex = step => STEPS.indexOf(step);
export const isReturnStepActive = (step, state) =>
  state?.currentStepIndex === getStepIndex(step);

export const useReturnStepIsActive = step => {
  const state = useContext(ReturnStepsState);
  return isReturnStepActive(step, state);
};

export const ACTIONS = {
  clearAll: "returnSteps@clearAll",
  setSelectingItem: "returnSteps@setSelectingItem",
  setSelectedItems: "returnSteps@setSelectedItems",
  setKeepItemIds: "returnSteps@setKeepItemIds",
  setEmail: "returnSteps@setEmail",
  setGuestEmail: "returnSteps@setGuestEmail",
  setReturnMethod: "returnSteps@setReturnMethod",
  setRequestedLabelCount: "returnSteps@setRequestedLabelCount",
  setStoreLocation: "returnSteps@setStoreLocation",
  setOrderNumber: "returnSteps@setOrderNumber",
  setSubmitSuccess: "returnSteps@setSubmitSuccess",
  goToStep: "returnSteps@goToStep",
  setConfirmedMailingAddress: "returnSteps@setConfirmedMailingAddress",
  setReturnCreditMethod: "returnSteps@setReturnCreditMethod",
  setAvailableReturnCreditMethods:
    "returnSteps@setAvailableReturnCreditMethods",
  setFallbackReturnCreditMethod: "returnSteps@setFallbackReturnCreditMethod",
  setIsGuestReturn: "returnSteps@setIsGuestReturn",
  setGiftField: "returnSteps@setGiftField",
  setFromCountryCode: "returnSteps@setFromCountryCode",
  setCorrectedAddress: "returnSteps@setCorrectedAddress",
  setShopNowRedirect: "returnSteps@setShopNowRedirect",
  setSavePreferences: "returnSteps@setSavePreferences",
  setSubmitReturnResultCode: "returnSteps@setSubmitReturnResultCode",
  setLineItemInitialIntension: "returnSteps@setLineItemInitialIntension",
  confirmLineItemIntension: "returnSteps@confirmLineItemIntension",
  clearLineItemIntension: "returnSteps@clearLineItemIntension",
  setStepStatus: "returnSteps@setStepStatus",
  goBack: "returnSteps@goBack",
  setReshopOptions: "returnSteps@setReshopOptions",
};

const nvo_urlParams = new URLSearchParams(window.location.search);

const giftField = nvo_urlParams.get("giftField") || "";
const isGift = nvo_urlParams.get("isGift") === "true";
const csid = nvo_urlParams.get("csid");
export const initialState = {
  mode:
    config.isNthUi && nvo_urlParams.get("tracking_number") !== null
      ? "track"
      : "return",
  stepStatus: {},
  currentStepIndex: 0,
  email: nvo_urlParams.get("email") || "",
  decodedGuestEmail: nvo_urlParams.get("email") || "",
  fromCountryCode: null,
  orderNumber: nvo_urlParams.get("order") || "",
  giftField,
  selectingItem: null,
  items: [],
  keepItemIds: [],
  selectedReturnMethod: {},
  // The ID of the selected location
  // During return submit, we should only include if it is the id of a Shopify Location
  locationId: null,
  // Concierge location details
  location: {},
  requestedLabelCount: null,
  submitted: false,
  submitResult: null,
  refundTotal: undefined,
  fromAddress: {},
  guestReturn: isGift && !!giftField,
  selectedReturnCreditMethod: {},
  availableReturnCreditMethods: [],
  shopNowSession: {},
  savePreferences: config.isUserPreferencesEnabled, // pre-selected by default
  csid,
  homePickupInstructions: "",
  lineItemIntension: {},
  reshopOptions: {},
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.clearAll:
      return initialState;

    case ACTIONS.setEmail:
      return { ...state, email: payload };

    case ACTIONS.setGuestEmail:
      return { ...state, decodedGuestEmail: state.email, email: payload };

    case ACTIONS.setOrderNumber:
      return { ...state, orderNumber: payload };

    case ACTIONS.setSelectingItem:
      return { ...state, selectingItem: payload };

    case ACTIONS.setSelectedItems:
      return {
        ...state,
        items: [...payload],
        // Reset step
        currentStepIndex: STEPS.indexOf(CHOOSE_ITEMS_STEP),
        keepItemIds: [],
        locationId: null,
        location: {},
        selectedReturnMethod: {},
        selectedReturnCreditMethod: {},
        availableReturnCreditMethods: [],
        submitted: false,
      };

    case ACTIONS.setKeepItemIds:
      return {
        ...state,
        keepItemIds: [...payload],
      };

    case ACTIONS.setStoreLocation: {
      const { carrier, ...details } = payload;
      const newLocation = {
        ...state,
        location: details,
        locationId: payload.id,
        // Reset step
        currentStepIndex: STEPS.indexOf(CHOOSE_METHOD_SUBSTEP),
        selectedReturnCreditMethod: {},
        availableReturnCreditMethods: [],
        submitted: false,
      };
      if (payload.carrier != "in_store") newLocation.locationId = null;
      return newLocation;
    }

    case ACTIONS.setReturnMethod:
      return {
        ...state,
        selectedReturnMethod: payload,
        qr: payload.qr,
        // Reset step
        currentStepIndex: STEPS.indexOf(CHOOSE_METHOD_STEP),
        locationId: null,
        location: {},
        selectedReturnCreditMethod: {},
        submitted: false,
      };

    case ACTIONS.setRequestedLabelCount:
      return {
        ...state,
        requestedLabelCount: payload,
        // Reset step
        currentStepIndex: STEPS.indexOf(CHOOSE_METHOD_STEP),
        locationId: null,
        location: {},
        selectedReturnCreditMethod: {},
        availableReturnCreditMethods: [],
        submitted: false,
      };

    case ACTIONS.setSubmitSuccess:
      return {
        ...state,
        submitted: true,
        submitResult: payload.submitResult,
        refundTotal: payload.refundTotal,
        // Reset step
        currentStepIndex: STEPS.indexOf(REVIEW_STEP),
      };

    case ACTIONS.goToStep:
      return {
        ...state,
        currentStepIndex: STEPS.indexOf(payload),
      };

    case ACTIONS.setConfirmedMailingAddress: {
      const { homePickupInstructions, ...fromAddress } = payload || {};

      return mergeIgnoreNil(state, {
        fromAddress,
        homePickupInstructions,
        // Reset step
        currentStepIndex: STEPS.indexOf(CONFIRM_MAILING_ADDRESS_STEP),
      });
    }

    case ACTIONS.setIsGuestReturn:
      return {
        ...state,
        guestReturn: payload,
      };

    case ACTIONS.setGiftField:
      return {
        ...state,
        giftField: payload,
      };

    case ACTIONS.setFromCountryCode:
      return {
        ...state,
        fromCountryCode: payload,
      };

    case ACTIONS.setReturnCreditMethod:
      return {
        ...state,
        selectedReturnCreditMethod: payload,
        currentStepIndex: STEPS.indexOf(CHOOSE_RETURN_CREDIT_METHOD_STEP),
      };

    case ACTIONS.setAvailableReturnCreditMethods:
      return {
        ...state,
        availableReturnCreditMethods: payload,
      };

    case ACTIONS.setFallbackReturnCreditMethod:
      return {
        ...state,
        selectedReturnCreditMethod: payload,
      };

    case ACTIONS.setCorrectedAddress:
      return {
        ...state,
        fromAddress: payload,
      };

    case ACTIONS.setShopNowRedirect:
      return {
        ...state,
        shopNowSession: {
          redirectingToShop: payload,
        },
        currentStepIndex: STEPS.indexOf(CHOOSE_SHOP_NOW_STEP),
        // Reset step
        selectedReturnCreditMethod: {},
        availableReturnCreditMethods: [],
        submitted: false,
      };

    case ACTIONS.setSavePreferences:
      return {
        ...state,
        savePreferences: payload,
      };

    case ACTIONS.setReshopOptions:
      return {
        ...state,
        reshopOptions: {
          ...payload,
          customerPhone: payload.customerPhone?.replace(/[-()]/g, ""),
        },
      };

    case ACTIONS.setSubmitReturnResultCode:
      return {
        ...state,
        submitReturnResultCode: payload,
      };

    case ACTIONS.setLineItemInitialIntension: {
      const { lineItemId, localId, type, exchangeEnabled } = payload;
      if (isNil(lineItemId) || isNil(localId)) return state;

      const updates = set(
        [lineItemId, localId],
        {
          initial: type,
          exchangeEnabled,
        },
        state.lineItemIntension,
      );
      return {
        ...state,
        lineItemIntension: updates,
      };
    }

    case ACTIONS.confirmLineItemIntension: {
      const { lineItemId, localId, type } = payload;
      if (isNil(lineItemId) || isNil(localId)) return state;

      const intension = get([lineItemId, localId], state.lineItemIntension);
      if (!intension || type === intension.initial) return state;

      const updates = set(
        [lineItemId, localId, "changedTo"],
        type,
        state.lineItemIntension,
      );
      return {
        ...state,
        lineItemIntension: updates,
      };
    }

    case ACTIONS.clearLineItemIntension: {
      const { lineItemId, localId } = payload;
      if (isNil(lineItemId) || isNil(localId)) return state;

      const updates = unset([lineItemId, localId], state.lineItemIntension);
      return {
        ...state,
        lineItemIntension: updates,
      };
    }

    case ACTIONS.setStepStatus: {
      const { step, ...status } = payload;

      return set(
        ["stepStatus", step],
        {
          ...state.stepStatus[step],
          ...status,
        },
        state,
      );
    }

    case ACTIONS.goBack: {
      let index = state.currentStepIndex;

      for (let i = index - 1; i >= 0; i--) {
        let step = STEPS[i];
        if (!state.stepStatus[step]?.skipped) {
          index = i;
          break;
        }
      }

      if (index === state.currentStepIndex) {
        return state;
      } else {
        return {
          ...state,
          currentStepIndex: index,
        };
      }
    }

    default:
      return state;
  }
}

export const ReturnStepsState = createContext();
export const ReturnStepsActions = createContext();
export const OrderFetchData = createContext();

export const buildInitialState = () => initialState;

function useReturnSteps(initialState) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => {
    const ret = compose(
      fromPairs,
      map(([key, type]) => {
        const dispatcher = function(payload) {
          dispatch({ type, payload });
        };
        dispatcher.displayName = key;
        return [key, dispatcher];
      }),
      toPairs,
    )(ACTIONS);

    return ret;
  }, [dispatch]);

  return useMemo(
    () => ({
      actions,
      state,
    }),
    [actions, state],
  );
}

function ReturnStepsProvider({ children, initialState }) {
  const { state, actions } = useReturnSteps(initialState);
  const orderFetch = useOrderData();

  return (
    <OrderFetchData.Provider value={orderFetch}>
      <ReturnStepsState.Provider value={state}>
        <ReturnStepsActions.Provider value={actions}>
          {children}
        </ReturnStepsActions.Provider>
      </ReturnStepsState.Provider>
    </OrderFetchData.Provider>
  );
}

export default ReturnStepsProvider;

export function useReturnStepsActions() {
  return useContext(ReturnStepsActions);
}

export function useReturnStepsState() {
  return useContext(ReturnStepsState);
}

export function useOrderFetchData() {
  return useContext(OrderFetchData);
}

export const useRegisterStepStatus = (step, { skipped } = {}) => {
  const actions = useContext(ReturnStepsActions);
  useEffect(() => actions.setStepStatus({ step, skipped }), [step, skipped]);
};
