import React, { useCallback, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeRootStyles } from "../../../theme/styles";
import ReturnComment from "./ReturnComment";
import {
  ACTION_HEIGHT_SPACING,
  getBorderSelected,
  getBorderUnselected,
} from "../utils";
import ItemDetails from "./ItemDetails";
import ItemSelectors from "./ItemSelectors";
import ItemBase from "./ItemBase";
import useItemSelectors from "./use-item-selectors";
import useReturnState from "./use-return-state";
import { config } from "../../../config";

export const useStyles = makeRootStyles(
  theme => ({
    collapsibleOption: {
      "&:after": {
        content: "' '",
        display: "block",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        border: getBorderUnselected(theme),
        pointerEvents: "none",
        zIndex: 1,
      },
    },
    collapsibleOptionSelected: {
      "&:after": {
        border: getBorderSelected(theme),
      },
    },
    actions: {
      minHeight: theme.spacing(ACTION_HEIGHT_SPACING),
    },
    actionsExtraSpace: {
      minHeight: theme.spacing(12),
    },
    additionalCommentsTitle: {
      width: "100%",
      padding: theme.spacing(3, 3, 1),
    },
  }),
  { name: "N0ItemDefault" },
);

const ItemDefault = ({
  addExtraSpace,
  enableExchange,
  disabled,
  item,
  itemIneligibleReasons,
  onChangeContinuePending,
  onChangeQuantity,
  onChangeReason,
  onChangeChildReason,
  onChangeComment,
  onChoose,
  onRemove,
  setPicturesForItem,
  selectedState,
  size = "sm",
  orderFetchData,
}) => {
  const classes = useStyles();

  const { eligible, isExchange, isReturn, isUnknown, state } = useReturnState({
    disabled,
    item,
    itemIneligibleReasons,
    selectedState,
  });
  const {
    isCommentMandatory,
    childReturnReasonsOptions,
    openChildReasonsSelect,
    openComment,
    openPictures,
    openQuantitySelect,
    openReasonsSelect,
    confirmedInComment,
    quantityOptions,
    returnReasonsOptions,
    setUserHasContinuedPastPictures,
    setOpenChildReasonsSelect,
    setOpenComment,
    setOpenPictures,
    setOpenQuantitySelect,
    setOpenReasonsSelect,
    setConfirmedInComment,
    onChangeChildReason: handleChangeChildReason,
    onChangeComment: handleChangeComment,
    onChangeQuantity: handleChangeQuantity,
    onChangeReason: handleChangeReason,
    photoUploadEnabled,
    photoUploadRequired,
  } = useItemSelectors({
    isReturn,
    isExchange,
    item,
    onChangeChildReason,
    onChangeComment,
    onChangeQuantity,
    onChangeReason,
    selectedState,
    state,
    orderFetchData,
  });

  const isSelected = state.selected && !isUnknown;

  const requiredConfirm = openPictures || openComment;
  useEffect(() => {
    onChangeContinuePending(item, requiredConfirm);
  }, [item, requiredConfirm]);

  const renderSelectors = ({ variant }) => {
    if (!isSelected) return null;

    return (
      <ItemSelectors
        childReturnReasonsOptions={childReturnReasonsOptions}
        onChangeChildReason={handleChangeChildReason}
        onChangeQuantity={handleChangeQuantity}
        onChangeReason={handleChangeReason}
        openChildReasonsSelect={openChildReasonsSelect}
        openQuantitySelect={openQuantitySelect}
        openReasonsSelect={openReasonsSelect}
        quantityOptions={quantityOptions}
        returnReasonsOptions={returnReasonsOptions}
        setOpenChildReasonsSelect={setOpenChildReasonsSelect}
        setOpenQuantitySelect={setOpenQuantitySelect}
        setOpenReasonsSelect={setOpenReasonsSelect}
        state={state}
        variant={variant}
      />
    );
  };

  const handleToggleExchangeVariants = useCallback(
    toggle => onChangeContinuePending(item, toggle),
    [item],
  );

  const onRemoveHandler = useCallback(
    (...args) => {
      setConfirmedInComment(false);
      setUserHasContinuedPastPictures(false);
      return onRemove(...args);
    },
    [onRemove],
  );

  return (
    <ItemBase item={item} onRemove={onRemoveHandler} size={size} state={state}>
      <div
        className={clsx(classes.collapsibleOption, {
          [classes.collapsibleOptionSelected]: state.selected,
        })}>
        <ItemDetails
          orderFetchData={orderFetchData}
          disabled={disabled || !eligible}
          enableExchange={enableExchange}
          item={item}
          openPictures={openPictures}
          setOpenPictures={setOpenPictures}
          photoUploadEnabled={photoUploadEnabled}
          photoUploadRequired={photoUploadRequired}
          onToggleExchangeVariants={handleToggleExchangeVariants}
          onChangeReason={handleChangeReason}
          onChangeChildReason={handleChangeChildReason}
          onChangeQuantity={handleChangeQuantity}
          onChoose={onChoose}
          onShowComment={setOpenComment}
          openComment={openComment}
          setPicturesForItem={setPicturesForItem}
          setUserHasContinuedPastPictures={setUserHasContinuedPastPictures}
          renderComment={() => (
            <>
              <Typography
                className={classes.additionalCommentsTitle}
                variant="h4">
                {config.translations.choose_items_comment_title}
              </Typography>
              <ReturnComment
                onContinue={comment => {
                  setOpenComment(false);
                  handleChangeComment(comment);
                }}
                required={isCommentMandatory}
                value={state.comment}
              />
            </>
          )}
          renderSelectors={() => renderSelectors({ variant: "large" })}
          size={size}
          state={state}
        />
      </div>
    </ItemBase>
  );
};
export default ItemDefault;
