import { mapKeys, snakeCase } from "lodash/fp";

export class Noflake {
  constructor({ endpoint, defaults } = {}) {
    this.endpoint = endpoint; // api endpoint, set to empty string for disabling the logging
    this.defaults = {
      ...defaults,
    };
  }

  log(schema, payload) {
    if (this.endpoint === undefined) {
      console.error(new Error("Noflake endpoint is undefined"));
    }
    if (!this.endpoint) return Promise.resolve(false);

    const now = new Date().toISOString();
    let data = {
      ...this.defaults,
      tag: schema ? `noflake.${schema}` : this.defaults.tag,
      event_ts: now,
      ingestion_timestamp: now,
      ...payload,
    };
    data = mapKeys(snakeCase)(data);

    return fetch(this.endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(res => {
        return res.ok;
      })
      .catch(err => {
        console.warn(err);
        return false;
      });
  }
}

const noflake = new Noflake();
export default noflake;
