import React, { Fragment, useState, useEffect } from "react";
import ImageIcon from "@material-ui/icons/BrokenImage";
import Typography from "@material-ui/core/Typography";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import { makeRootStyles } from "../../../theme/styles";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      width: "100%",
      height: "100%",
      color: theme.palette.grey[400],
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundColor: theme.palette.white,
      minHeight: "min(120px, 12vh)",
      "&.N0ItemThumbnail-root-enter": {
        opacity: 0,
      },
      "&.N0ItemThumbnail-root-enter-active": {
        opacity: 1,
        transition: `opacity ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
      },
      "&.N0ItemThumbnail-root-exit": {
        opacity: 1,
      },
      "&.N0ItemThumbnail-root-exit-active": {
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
      },
    },
    imageBroken: {
      backgroundColor: theme.palette.grey[50],
    },
    altText: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 2),
    },
  }),
  { name: "N0ItemThumbnail" },
);

function ItemThumbnail({ fade, className, imageAltText, imageUrl, ...rest }) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false);
  const imageBroken = error || !imageUrl;

  const content = (
    <div
      className={clsx(classes.root, className, {
        [classes.imageBroken]: imageBroken,
      })}
      style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : "none" }}>
      {imageBroken && (
        <>
          <ImageIcon
            fontSize="large"
            color="inherit"
            data-styleid="thumbnail-image"
          />
          {imageAltText && (
            <Typography
              className={classes.altText}
              color="inherit"
              align="center"
              variant="body2">
              {imageAltText}
            </Typography>
          )}
        </>
      )}
    </div>
  );

  useEffect(() => {
    const preloadImage = url => {
      const img = new Image();
      img.onload = () => {
        setReady(true);
        setError(null);
      };
      img.onerror = setError;
      img.src = url;
      return img;
    };

    let img = null;
    if (imageUrl) {
      img = preloadImage(imageUrl);
    }

    // remove the onload handler
    // avoid error occurs when thumbnail is removed before the image is loaded
    return () => {
      if (img) img.onload = null;
    };
  }, [imageUrl]);

  if (fade) {
    return (
      <CSSTransition timeout={500} in={ready} classNames="N0ItemThumbnail-root">
        {content}
      </CSSTransition>
    );
  }

  return content;
}
export default ItemThumbnail;
