import React, { useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageIcon from "@material-ui/icons/Image";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "inline-flex",
      flexDirection: "row",
    },
    imageContainer: {
      display: "flex",
      width: theme.spacing(11),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "90%",
    },
    buttonContainer: {
      display: "block",
    },
    button: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      marginBottom: theme.spacing(1),
    },
    label: {
      marginLeft: theme.spacing(1),
    },
  }),
  { name: "N0ImageUploadSmall" },
);

const ImageUploadSmall = ({
  className,
  helperText = "Upload a JPG or PNG",
  errorText = "",
  label = "Upload Image",
  onChange,
  preview,
  variant = "normal",
}) => {
  const inputRef = useRef(null);
  const classes = useStyles();

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.imageContainer}>
        {preview ? (
          <img alt="" className={classes.image} src={preview} />
        ) : (
          <ImageIcon color="action" />
        )}
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.button}>
          <CloudUploadIcon color="primary" />
          <Typography color="primary" className={classes.label} variant="body2">
            <strong>{label}</strong>
          </Typography>
        </div>
        {errorText && (
          <Typography color="error" component="p" variant="caption">
            {errorText}
          </Typography>
        )}
        <Typography color="textSecondary" component="p" variant="caption">
          {helperText}
        </Typography>
      </div>
    </div>
  );
};

export default ImageUploadSmall;
