import React from "react";
import { alpha } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import { makeRootStyles } from "../../../theme/styles";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      display: "flex",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
    },
    removeButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 2,
      backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    sm: {
      minHeight: theme.spacing(49),
    },
    lg: {
      minHeight: theme.spacing(25),
    },
  }),
  { name: "N0ItemBase" },
);

const ItemBase = ({ children, item, onRemove, size = "sm", state }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {state.selected && (
        <IconButton
          color="primary"
          size="small"
          className={classes.removeButton}
          onClick={() => onRemove(item)}>
          <CloseIcon />
        </IconButton>
      )}
      <div className={clsx(classes.card, classes[size])}>{children}</div>
    </div>
  );
};
export default ItemBase;
