import React from "react";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(
  () => ({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    arrows: {
      display: "flex",
    },
    arrow: {
      color: "#cfd0d3",
      cursor: "pointer",
    },
    arrowDisabled: {
      pointerEvents: "none",
    },
  }),
  { name: "N0PositionControl" },
);

function PositionControl({
  disableDown,
  disableUp,
  id,
  onMoveDown,
  onMoveUp,
  value,
}) {
  const classes = useStyles();

  function handleMoveUp() {
    if (disableUp) return;
    onMoveUp(id);
  }

  function handleMoveDown() {
    if (disableDown) return;
    onMoveDown(id);
  }

  return (
    <div className={classes.root}>
      {value && <Typography>{value}</Typography>}
      <div className={classes.arrows} onClick={e => e.stopPropagation()}>
        <KeyboardArrowUp
          className={clsx(classes.arrow, {
            [classes.arrowDisabled]: disableUp,
          })}
          onClick={handleMoveUp}
        />
        <KeyboardArrowDown
          className={clsx(classes.arrow, {
            [classes.arrowDisabled]: disableDown,
          })}
          onClick={handleMoveDown}
        />
      </div>
    </div>
  );
}
PositionControl.propTypes = {
  disableDown: PropTypes.bool,
  disableUp: PropTypes.bool,
  id: PropTypes.any,
  onMoveDown: PropTypes.func,
  onMoveUp: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default PositionControl;
