import { useMemo } from "react";

const useReturnState = ({
  disabled,
  item,
  itemIneligibleReasons,
  selectedState,
}) => {
  const state = useMemo(
    () => ({
      eligible: item.someReasonsEligible,
      ineligibleReason: itemIneligibleReasons || [],
      returnReason: null,
      childReturnReason: { returnReason: null },
      comment: null,
      quantity: null,
      pictures: [],
      ...selectedState,
      selected: !!selectedState,
    }),
    [item.someReasonsEligible, itemIneligibleReasons, selectedState],
  );

  const eligible = disabled ? false : state.eligible;
  const isReturn = state.type === "return";
  const isExchange = state.type === "exchange";
  const isUnknown = !isReturn && !isExchange;

  return {
    eligible,
    isExchange,
    isReturn,
    isUnknown,
    state,
  };
};
export default useReturnState;
