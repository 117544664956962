import React, { FunctionComponent, ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 8,
      display: "block",
    },
  }),
  { name: "N0FieldLabel" },
);

const FieldLabel = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Typography
      className={`${classes.root} ${className}`}
      component="label"
      variant="h4"
      {...props}>
      {children}
    </Typography>
  );
};
export default FieldLabel;
