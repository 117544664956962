import { useCallback, useMemo, useState } from "react";
import { useApolloClient, gql } from "@apollo/client";
import keyBy from "lodash/keyBy";
import get from "lodash/get";

import locale from "../../shared/modules/lang";
import { config } from "../config";
import errorNotifier from "../../shared/modules/error-notifier";
import { memoObjectByKeyValues } from "../../shared/modules/object";

const { isGiftZipEnabled, isInAppLabelEnabled } = config;

const RETURN_HISTORY_DATA = gql`
  fragment ReturnHistoryData on Return {
    id
    cancellable
    carrierTrackingNumber
    currentStatus
    returnItems {
      itemName
      quantity
      itemId
      returnReason
      returnReasonCode
      childReturnReasonCode
    }
    returnMethod
    returnStatusUpdatedAt
    ${isInAppLabelEnabled ? `qrCodeUrl returnLabel` : ""}
    refundMethod
    reshopRefundId
    returnShipments {
      letter
      returnTrackingLink
    }
  }
`;

function buildOrderDataQuery({ orderNumber, email, giftField, isGift, csid }) {
  const giftFieldParam = (() => {
    if (isGift) {
      return isGiftZipEnabled
        ? `, zipcode: "${giftField}"`
        : `, giftCode: "${giftField}"`;
    }
    return "";
  })();

  // prettier-ignore
  return gql`
  ${RETURN_HISTORY_DATA}
  query GetOrderData {
    order(orderNumber: "${orderNumber}", email: "${email}", guestReturn: ${isGift}${giftFieldParam}${locale ? `, locale: "${locale}"` : ""}${csid ? `, csid: "${csid}"` : ""}) {
      shopifyOrderId
      orderNumber
      ${
        config.shopifyCollectiveEnabled
          ? 'orderLineItemGroupings { slug, displayName, behaviors, lineItemIds }'
          : ''
      }
      billingZip
      billingProvince
      billingProvinceCode
      billingCountryCode
      fromFirstName
      fromLastName
      fromAddress1
      fromAddress2
      fromCity
      fromZip
      fromProvince
      fromProvinceCode
      fromCountryCode
      eligible
      ineligibleDisplayText
      presentmentCurrency
      presentmentCurrencySubunitToUnit
      presentmentCurrencyEstimatedUsdConversionRate
      guestLoginEmail
      items {
        id
        productId
        variantId
        name
        productTitle
        productImageUrl
        productImageAltTxt
        variantInfo {
          name
          value
        }
        priceAmount
        displayPrice
        hideDisplayPrice
        compareAtPriceAmount
        displayCompareAtPrice
        orderedQuantity
        returnableQuantity
        removedQuantity
        sku
        eligibilityCriteria {
          returnReason
          ineligibleReason
          eligible
          ineligibleReasonForReturn
          ineligibleReasonForExchange
        }

      }
    }
    returnHistory(orderNumber: "${orderNumber}", email: "${email}") {
      ...ReturnHistoryData
    }
    returnReasons${locale ? `(locale: "${locale}")` : ""} {
      id
      reasonTitle: translatedReason
      parentId
      priority
      randomize
      enableCustomerPictures
      requireCustomerPictures
      isCommentMandatory
    }
  }
`;
}

export function useOrderData() {
  const [called, setCalled] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const showNonRemoved = item => item.orderedQuantity !== item.removedQuantity;

  const mappedData = useMemo(() => {
    if (data) {
      const returnReasonsById = keyBy(data.returnReasons, "id");
      const items = get(data, "order.items", [])
        .filter(showNonRemoved)
        .map(item => ({
          ...item,
          eligibilityCriteria: item.eligibilityCriteria.map(c => {
            const reason = returnReasonsById[c.returnReason];
            return {
              ...c,
              returnReasonTitle:
                (reason && reason.reasonTitle) || c.returnReason,
              returnReasonParent: (reason && reason.parentId) || null,
            };
          }),
          someReasonsEligible: item.eligibilityCriteria
            .map(reason => reason.eligible)
            .some(flag => flag === true),
          allowReturn: item.eligibilityCriteria.some(
            c => !c.ineligibleReasonForReturn,
          ),
          allowExchange: item.eligibilityCriteria.some(
            c => !c.ineligibleReasonForExchange,
          ),
        }));

      const itemsById = keyBy(items, "id");

      return {
        ...data,
        returnReasonsById,
        order: {
          ...data.order,
          items,
          itemsById,
        },
      };
    }
  }, [data]);

  const state = memoObjectByKeyValues({
    called,
    error,
    loading,
    data: mappedData,
  });

  const clearOrderData = useCallback(function clearOrderData() {
    setCalled(false);
    setData();
    setError(null);
    setLoading(false);
  }, []);

  const getOrderData = useCallback(
    function getOrderData({ orderNumber, email, giftField, isGift, csid }) {
      async function getOrderDataAsync({
        orderNumber,
        email,
        giftField,
        isGift,
        csid,
      }) {
        try {
          setCalled(true);
          setLoading(true);

          const { error, data } = await client.query({
            query: buildOrderDataQuery({
              orderNumber,
              email,
              giftField,
              isGift,
              csid,
            }),
            fetchPolicy: "network-only",
          });

          if (error) {
            setError(error);
          } else {
            // log debugging information for rollbar
            const { shopUrl, shopName, host, shid } = config;
            console.log("consumer order:", {
              orderNumber,
              email,
              giftField,
              isGift,
              shopUrl,
              shopName,
              host,
            });
            errorNotifier.setUser({
              id: shid,
              shopUrl,
              orderEmail: email,
              orderNumber,
            });

            setData(data);
            setError(null);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
          setCalled(false);
        }
      }

      getOrderDataAsync({ orderNumber, email, giftField, isGift, csid });
    },
    [client],
  );

  const actions = useMemo(
    () => ({
      clearOrderData,
      getOrderData,
    }),
    [clearOrderData, getOrderData],
  );

  return useMemo(() => ({ actions, state }), [actions, state]);
}
