import React, { useMemo, forwardRef } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Swiper from "../../components/swiper";

const ItemsCarousel = forwardRef(({ children }, ref) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const itemsLength = useMemo(() => {
    if (children instanceof Array) return children.length;
    return 1;
  }, [children]);
  const itemsNode = useMemo(
    function renderItemsNode() {
      return React.Children.map(children, element => {
        if (isLgUp) {
          return (
            <element.type
              {...element.props}
              size={itemsLength === 1 ? "lg" : "sm"}
            />
          );
        }

        if (isSmUp && itemsLength === 1) {
          return <element.type {...element.props} size="lg" />;
        }

        return <element.type {...element.props} size="sm" />;
      });
    },
    [children, isLgUp, isSmUp, itemsLength],
  );

  if (isMdUp) {
    return (
      <Swiper
        key="md-up"
        ref={ref}
        slidesPerGroup={itemsLength <= 2 ? itemsLength : 2}
        slidesPerView={itemsLength <= 3 ? itemsLength : 3.1}
        threshold={20}
        nav
        spacing={2}>
        {itemsNode}
      </Swiper>
    );
  }

  return (
    <Swiper
      key="xs-up"
      ref={ref}
      slidesPerGroup={1}
      slidesPerView={itemsLength === 1 ? 1 : 1.25}
      nav
      spacing={2}>
      {itemsNode}
    </Swiper>
  );
});
ItemsCarousel.displayName = "ItemsCarousel";
ItemsCarousel.propTypes = {
  children: PropTypes.node,
};
export default ItemsCarousel;
