import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import { makeRootStyles } from "../../theme/styles";

import { config } from "../../config";
const { isGiftZipEnabled, isGiftReceiptEnabled } = config;

const useStyles = makeRootStyles(
  theme => ({
    root: {},
    form: {
      marginBottom: theme.spacing(1),
      justifyContent: "center",
      alignItems: "center",
    },
    instructions: {
      marginBottom: theme.spacing(1),
      lineHeight: 1.25,
    },
    formFieldContainer: {
      width: "100%",
    },
    input: {
      backgroundColor: theme.palette.common.white,
    },
    submitButton: {
      height: theme.spacing(6),
    },
    errorSnackbar: {
      color: theme.palette.error.text,
      backgroundColor: theme.palette.error.main,
      fontSize: "100%",
    },
    giftReturnTitle: {
      fontWeight: "bold",
      borderTop: "1px solid #c5c5c5",
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    giftReturnLink: {
      marginTop: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.primary.main,
    },
    giftEmailHelperText: {
      color: "#767676",
      fontSize: "0.75rem",
    },
  }),
  { name: "N0OrderLookupLogin" },
);

function OrderLookupLogin({
  orderNumber,
  email,
  giftField,
  error,
  onChangeOrderNumber,
  onChangeEmail,
  onChangeGiftField,
  onSubmitForm,
  disabled,
  isTrack,
  isGift,
  onChangeIsGift,
}) {
  const classes = useStyles();
  const buttonDisabled = !(orderNumber && email) || disabled;

  const switchReturnType = () => {
    onChangeIsGift(!isGift);
  };

  const giftReturnTitle = isTrack
    ? isGift
      ? config.translations.track_app_order_lookup_not_gift_link
      : config.translations.track_app_order_lookup_gift_link
    : isGift
    ? config.translations.order_lookup_not_gift_link
    : config.translations.order_lookup_gift_link;
  const giftReturnLink = isTrack
    ? isGift
      ? config.translations.track_app_order_lookup_title
      : config.translations.track_app_order_lookup_gift_title
    : isGift
    ? config.translations.order_lookup_title_start_return
    : config.translations.order_lookup_gift_title_start_return;
  const instructions = isTrack
    ? isGift
      ? config.translations.track_app_order_lookup_gift_start_instructions
      : config.translations.track_app_order_lookup_start_return_instructions
    : isGift
    ? config.translations.order_lookup_gift_start_instructions
    : config.translations.order_lookup_start_return_instructions;
  const input3 = isGiftZipEnabled
    ? config.translations.order_lookup_gift_shipping_zip
    : config.translations.order_lookup_gift_code;
  const orderNumberTranslation = isGift
    ? config.translations.order_lookup_gift_order_number
    : config.translations.order_lookup_order_number;
  const emailTranslation = isGift
    ? config.translations.order_lookup_gift_email
    : config.translations.order_lookup_email;
  const notFoundErrorMessage = isTrack
    ? isGift
      ? config.translations.track_app_order_lookup_not_found_gift
      : config.translations.track_app_order_lookup_not_found
    : isGift
    ? config.translations.order_lookup_not_found_gift
    : config.translations.order_lookup_not_found;
  const giftEmailHelper = isTrack
    ? config.translations.track_app_order_lookup_gift_email_helper
    : config.translations.order_lookup_gift_email_helper;
  const giftCodeHelper = isTrack
    ? config.translations.track_app_order_lookup_gift_code_helper
    : config.translations.order_lookup_gift_code_helper;
  const submitLabel = isTrack
    ? config.translations.track_app_order_lookup_submit
    : config.translations.order_lookup_submit;

  return (
    <form
      onSubmit={onSubmitForm}
      className={classes.root}
      data-styleid="order-lookup-form">
      <Grid className={classes.form} spacing={2} container>
        <Grid item xs={12} data-styleid="order-lookup-instructions-container">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Typography
                align="center"
                component="p"
                className={classes.instructions}
                data-styleid="order-lookup-instructions"
                dangerouslySetInnerHTML={{
                  __html: instructions,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} data-styleid="order-lookup-order-number-container">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6} className={classes.formFieldContainer}>
              <TextField
                autoFocus
                className={classes.input}
                id="order-lookup-order-number"
                label={orderNumberTranslation}
                name="order-number"
                value={orderNumber}
                onChange={onChangeOrderNumber}
                margin="none"
                variant="outlined"
                color="primary"
                fullWidth
                data-styleid="order-lookup-order-number"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} data-styleid="order-lookup-order-email-container">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6} className={classes.formFieldContainer}>
              <TextField
                className={classes.input}
                id="order-lookup-order-email"
                label={emailTranslation}
                name="email"
                value={email}
                onChange={onChangeEmail}
                margin="none"
                variant="outlined"
                color="primary"
                fullWidth
                type="email"
                data-styleid="order-lookup-order-email"
              />
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            {isGift && (
              <>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    className={classes.giftEmailHelperText}
                    dangerouslySetInnerHTML={{ __html: giftEmailHelper }}
                  />
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </>
            )}
          </Grid>
        </Grid>

        {isGift && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={6} className={classes.formFieldContainer}>
                <TextField
                  id="order-lookup-gift-field"
                  className={classes.input}
                  label={input3}
                  name="gift-field"
                  value={giftField}
                  onChange={onChangeGiftField}
                  margin="none"
                  variant="outlined"
                  color="primary"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={6}>
                <Typography
                  className={classes.giftEmailHelperText}
                  dangerouslySetInnerHTML={{ __html: giftCodeHelper }}
                />
              </Grid>
              <Grid item xs={12} md={3} />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6} className={classes.formFieldContainer}>
              <Button
                id="order-lookup-submit"
                className={classes.submitButton}
                color="primary"
                disabled={buttonDisabled}
                size="large"
                variant="contained"
                type="submit"
                fullWidth
                data-styleid="order-lookup-button">
                <strong>{submitLabel}</strong>
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {(isGiftZipEnabled || isGiftReceiptEnabled) && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography
                  align="center"
                  component="p"
                  className={classes.giftReturnTitle}
                  dangerouslySetInnerHTML={{
                    __html: giftReturnTitle,
                  }}
                />
                <Typography
                  id="order-lookup-gift-return-switch"
                  className={classes.giftReturnLink}
                  align="center"
                  component="p"
                  onClick={switchReturnType}>
                  <a href="#" data-styleid="gift-return-link">
                    {giftReturnLink}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid spacing={2} container>
        {error && (
          <Grid item xs={12} md={12}>
            {console.debug(classes)}
            <SnackbarContent
              className={classes.errorSnackbar}
              aria-describedby="client-snackbar"
              elevation={0}
              message={
                <Typography
                  className={classes.errorSnackbar}
                  dangerouslySetInnerHTML={{ __html: notFoundErrorMessage }}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
}
OrderLookupLogin.propTypes = {
  email: PropTypes.string,
  orderNumber: PropTypes.string,
  giftField: PropTypes.string,
  disabled: PropTypes.bool,
  isGift: PropTypes.bool,
  isTrack: PropTypes.bool,
  error: PropTypes.bool,
  onChangeOrderNumber: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangeGiftField: PropTypes.func,
  onSubmitForm: PropTypes.func,
  onChangeIsGift: PropTypes.func,
};
export default React.memo(OrderLookupLogin);
