import escapeStringRegexp from "escape-string-regexp";
import maxBy from "lodash/maxBy";
import { flatten } from "lodash/fp";

export const IN_STOCK = "in-stock";
export const UNAVAILABLE_IN_THIS_SET = "not available in this set";
export const UNAVAILABLE_IN_ANY_SET = "not available in any set";

export default class Sparsem {
  constructor() {
    this.SEP = "\t";
    this.BLANK = "__BLANK__";
  }

  setArr(inp, opts) {
    const patchBlankOptions = opts => opts.map(opt => opt ?? this.BLANK);
    this.inp = inp;
    this.arr = inp.map(x => patchBlankOptions(x).join(this.SEP));
    this.opts = opts;
    this.num = opts.length;
  }

  search(options = []) {
    const str = flatten(
      options.map((option, i) => {
        if (i >= this.num) return;
        if (option) return escapeStringRegexp(option);
        return ".{1,}";
      }),
    ).join(this.SEP);

    const out = this.arr
      .filter(x => x.match(new RegExp(`^${str}$`)))
      .map(x => x.split(this.SEP));

    const missingIdx = options.indexOf(null);

    // TODO: remove
    if (!this.opts[missingIdx]) return {};

    const result = this.opts[missingIdx].reduce((ret, val) => {
      ret[val ?? ""] = out.some(x => x[missingIdx] === val)
        ? IN_STOCK
        : this.inp.some(x => x[missingIdx] === val)
        ? UNAVAILABLE_IN_THIS_SET
        : UNAVAILABLE_IN_ANY_SET;
      return ret;
    }, {});

    return result;
  }

  /**
   * Select an available item which matches the most given options, then return
   * its options.
   * @param {string[]} options The selected options, e.g. ['Graphic Tee', 'Navy Stripe', null]
   * @returns {string[]}
   */
  select(options) {
    const result = maxBy(this.inp, availableItem =>
      availableItem.reduce((score, itemOption, index) => {
        const match = options[index] === itemOption;
        return match ? score + 1 : score;
      }, 0),
    );
    return result;
  }
}
