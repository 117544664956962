import { useMemo, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import { memoObjectByKeyValues } from "../../shared/modules/object";

const CANCEL_RETURN = gql`
  mutation cancelReturn(
    $orderNumber: String!
    $email: String!
    $returnId: String
  ) {
    cancelReturn(orderNumber: $orderNumber, email: $email, returnId: $returnId)
  }
`;

export function useCancelReturn() {
  const [mutate, { loading, error, data }] = useMutation(CANCEL_RETURN);
  const cancelReturn = useCallback(variables => mutate({ variables }), [
    mutate,
  ]);

  const cancelStatus = useMemo(
    () => ({
      loading,
      error,
      data: data?.cancelReturn ?? null,
    }),
    [loading, error, data],
  );

  return useMemo(
    () => ({
      cancelReturn,
      cancelStatus,
    }),
    [cancelReturn, cancelStatus],
  );
}
